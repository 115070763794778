import React, { useEffect } from 'react';
import LandingPage from "./pages/Landing/LandingPage";
import { Routes, Route, useNavigate } from 'react-router-dom'; // Remove Navigate if not used elsewhere
import ProtectedRoute from './ProtectedRoute'; // Make sure the path is correct
import HomePage from "./pages/Home/Common/HomePage"; 
import ReactGA from 'react-ga4';
import BillingSubscription from "../src/components/Home/Common/BillingSubscription";
import TermsAndConditions from "../src/components/Home/Common/TermsAndConditions";
import PrivacyStatement from "../src/components/Home/Common/PrivacyStatement";

import MatchCheatSheetPage from "./pages/Home/Mobile/MatchCheatSheetPage";
import MatchSelectPlayersPage from "./pages/Home/Mobile/MatchSelectPlayersPage";
import ApproachPage from "./pages/Home/Mobile/ApproachPage";
import TeamRecommendPage from './pages/Home/Mobile/TeamRecommendPage'
import ShowMyTeams from './components/Home/Mobile/ShowMyTeams';
import MyTeamsPlayersM from "./components/Home/Mobile/MyTeamsPlayersM";

import NotFoundPage from './pages/NotFound/NotFoundPage';

import FeedbackButton from './components/Home/Common/FeebackButton';

import './App.css';


// function DesktopRedirect(Component) {
//   return function WrapperComponent(props) {
//     const navigate = useNavigate();

//     useEffect(() => {
//       function detectDesktop() {
//         const userAgent = window.navigator.userAgent.toLowerCase();
//         const isDesktop = !/iphone|ipod|android|ie|blackberry|fennec/.test(userAgent);
//         return isDesktop;
//       }

//       if (detectDesktop()) {
//         navigate('/home');
//       }
//     }, [navigate]);

//     return <Component {...props} />;
//   };
// }

function App() {
  const TRACKING_ID = process.env.REACT_APP_GA_ID;
  ReactGA.initialize(TRACKING_ID);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/register" element={<HomePage page="registerPage" />} />
        <Route path="/login" element={<HomePage page="loginPage" />} />
        
        <Route path="/home" element={
          <ProtectedRoute>
            <HomePage /> 
          </ProtectedRoute>
        } /> 
        <Route path="/billing-subscription" element={<BillingSubscription />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyStatement />} />

          {/* Mobile view, now wrapped with DesktopRedirect for desktop redirection */}
        {/* <Route path="/match-selected/mobile" element={DesktopRedirect(MatchCheatSheetPage)} />
        <Route path="/select-players" element={DesktopRedirect(MatchSelectPlayersPage)} />
        <Route path="/approach" element={DesktopRedirect(ApproachPage)} />
        <Route path="/team-recommend" element={DesktopRedirect(TeamRecommendPage)} />
        <Route path="/saved-teams" element={DesktopRedirect(ShowMyTeams)} />
        <Route path="/myteams-players" element={DesktopRedirect(MyTeamsPlayersM)} /> */}

        <Route path="/match-selected/mobile" element={<MatchCheatSheetPage />} />
        <Route path="/select-players" element={<MatchSelectPlayersPage />}  />
        <Route path="/approach" element={<ApproachPage />} />
        <Route path="/team-recommend" element={<TeamRecommendPage />} />
        <Route path="/saved-teams" element={<ShowMyTeams />} />
        <Route path="/myteams-players" element={<MyTeamsPlayersM />} />
        
        <Route path="*" element={<NotFoundPage />} />
        


      </Routes>
      {/* <FeedbackButton /> */}

    </div>
  );
}

export default App;
