import React from 'react';
import './noupcomingmatches.css';

const NoUpcomingMatches = () => {
  return (
    <div className="no-upcoming-matches">
      <img src="/images/common/wicket.svg" alt="Wickets" className="wickets-image" />
      <h1>No Upcoming Matches</h1>
      <p>We are working on adding more matches. Please check back later.</p>
    </div>
  );
};

export default NoUpcomingMatches;
