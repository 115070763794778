import {useState} from 'react'
// import { useNavigate } from 'react-router-dom';
import PlayerCardPage from '../../../components/Home/Common/PlayerCardPage'
import './buildplayerlistM.css'

const BuildPlayerListM = ({player, teamName, handleTeamPlayerChange, teamType}) => {
    console.log("buildPlayerlist..player data:", player)
    const [iconChange, setIconChange] = useState(player.player_details.played_last_game)
    const [showDetails, setShowDetails] = useState(false)
    // const navigate = useNavigate()

    const getIcon = (role) => {
        switch (role) {
            case "top-order batter": return "/images/baticon.svg";
            case "middle-order batter": return "/images/baticon.svg";
            case "opening batter": return "/images/baticon.svg";
            case "batter": return "/images/baticon.svg";
            case "Allrounder - Pace":
            case "Allrounder - Spin": return "/images/all-rounder-icon.svg";
            case "allrounder": return "/images/all-rounder-icon.svg";
            case "bowling allrounder": return "/images/all-rounder-icon.svg";
            case "batting allrounder": return "/images/all-rounder-icon.svg";
            case "Bowler - Pace":
            case "Bowler - Spin": return "/images/bowler.svg";
            case "bowler": return "/images/bowler.svg";
            case "wicketkeeper": return "/images/wicketkeeper.svg";
            case "wicketkeeper batter": return "/images/wicketkeeper.svg";
            default: return "/images/playericon.svg";
        }
    };

    const handleChangeIcon = () =>{
        const playerAdded = (iconChange ==="true")? "false": "true"
        setIconChange(prevData => playerAdded)
        console.log("from builder comp:", teamName, player.player_details.player_id, playerAdded)
        handleTeamPlayerChange(teamName, player.player_details.player_id, playerAdded, teamType )
    }

    const handleShowPlayerDetail = (display) =>{
        // navigate('/player-card', {state: player})
        if(display === "show"){
            setShowDetails(true)
        }else{
            setShowDetails(false)
        }   
    }
    
  return (
    <div className="player-list-each-player">
        <div className="player-list-player-item" onClick={() =>handleShowPlayerDetail("show")}>
            <img src="/images/mobile/player-icon.svg" alt="" 
                    className="pl-icon"/>
            <span>{player.player_details.player_name}</span>
            <img src={getIcon(`${player.player_details.player_role}`)} alt="" />
        </div>
        <div>
            <img src={iconChange=== "true"? "/images/mobile/added-btn.svg": "/images/mobile/add-btn.svg"} alt=""
                  onClick={handleChangeIcon}/>
        </div>
        {
          showDetails &&
            <div className="select-player-modal-containerM"> 
                <PlayerCardPage player={player}
                            handleDetail={handleShowPlayerDetail}
                />
            </div>  
        }
    </div>
  )
}

export default BuildPlayerListM

