import React, { Suspense, lazy, useEffect, useState } from 'react';
import { useViewport } from '../../../context/viewportContext';
import RegisterPageMobile from '../../Register/RegisterPageMobile';
import RegisterPageDesktop from '../../Register/RegisterPageDesktop';
import LoginPage from '../../Login/LoginPage';
import LeftPanel from '../../../components/Home/LeftPanel';
import UpcomMatch from '../../../components/Home/UpcomMatch';
import './homepage.css';
import { useNavigate, useLocation } from 'react-router-dom';


const UpcomingMatchesM = lazy(() => import('../Mobile/UpcomingMatchesM'));


const HomePage = ({ page }) => {
  const { width } = useViewport();
  const breakpoint = 768;
  const [activePlan, setActivePlan] = useState(null);
  const [termEnd, setTermEnd] = useState(null);  
  const [subscriptionId, setSubscriptionId] = useState(null);  
  const [userDetails, setUserDetails] = useState(null);  
  const [openModal, setOpenModal] = useState(false);
  const [buildTeams, setBuildTeams] = useState(true);
  const [myTeams, setMyTeams] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  

  useEffect(() => {
    if (location.pathname !== '/register') {
      const fetchActiveSubscription = async () => {
        const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/active_subscription/`;
        const idToken = localStorage.getItem('idToken');

        try {
          const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${idToken}`
            }
          });
          if (response.status === 401) {
            // Unauthorized access, redirect to /login
            navigate('/login');
          } else {
            const data = await response.json();
            setActivePlan(data.active_plan);
            setTermEnd(data.term_end);
            setSubscriptionId(data.subscription_id)
            setUserDetails(data.user_details);
            localStorage.setItem('userDetails', JSON.stringify(data.user_details));            
            if (data.active_plan === "no_active_plan") {
              setOpenModal(true);
            }
            localStorage.setItem('UID', data.user_details.uid);
          }
        } catch (error) {
          console.error('Failed to fetch active plan:', error);
        }
      };

      fetchActiveSubscription();
    }
  }, [navigate, location.pathname]); 

  // Function to encrypt the phone number
  // async function encryptPhoneNumber(phoneNumber) {
  //   const encoder = new TextEncoder();
  //   const data = encoder.encode(phoneNumber);
  //   const key = await crypto.subtle.generateKey(
  //     {
  //       name: "AES-GCM",
  //       length: 256,
  //     },
  //     true,
  //     ["encrypt", "decrypt"]
  //   );

  //   const iv = crypto.getRandomValues(new Uint8Array(12)); // generate a random iv
  //   const encrypted = await crypto.subtle.encrypt(
  //     { name: "AES-GCM", iv },
  //     key,
  //     data
  //   );

  //   return btoa(String.fromCharCode(...new Uint8Array(encrypted))); // Convert the Uint8Array to string and then to base64
  // }

  if (page === "registerPage") {
    return (
      <>
        {width <= breakpoint ?
          <RegisterPageMobile />
          :
          <RegisterPageDesktop />
        }
      </>
    );
  }

  if (page === "loginPage") {
    return (
      <>
        {width <= breakpoint ?
          <LoginPage viewPort="mobile" />
          :
          <RegisterPageDesktop />
        }
      </>
    );
  }

  return (
    <>
      {/* {openModal ? <SubscriptionModal /> : null} */}
      {width <= breakpoint  ?
      // && !openModal ? 
        <Suspense fallback={<div>Loading...</div>}>
          <UpcomingMatchesM activePlan={activePlan}/>
        </Suspense>
        :
        <Suspense fallback={<div>Loading...</div>}>
          {/* {openModal ? null : ( */}
            <div className="home-container">
              <LeftPanel userDetails={userDetails} activePlan={activePlan} setBuildTeams={setBuildTeams} setMyTeams={setMyTeams} buildTeams={buildTeams} myTeams={myTeams}/>
              <UpcomMatch activePlan={activePlan} buildTeams={buildTeams} setMyTeams={setMyTeams} setBuildTeams={setBuildTeams}/>
            </div>
          {/* )} */}
        </Suspense>
      }
    </>
  );
}

export default HomePage;
