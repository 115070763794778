import React, {useEffect, useState} from 'react'
import './matchselectplayersM.css';
import BuildPlayerListM from './BuildPlayerListM';
import axios from 'axios'
import { useNavigate} from 'react-router-dom'
import Lottie from "lottie-react";
import ballSpinner from '../../../lotties/ball-loading.json';


const MatchSelectPlayersM = () => {
  const match = JSON.parse(localStorage.getItem('matchSelected'))
  const token = localStorage.getItem('idToken');
  const BASE_URL = "https://asak4lmpca.execute-api.us-east-2.amazonaws.com/dev"
  const navigate = useNavigate()
  const lstorageKey = "playerList" 

  const teamOne = `${match.team1_id} - ${match.team1_name}`
  const teamTwo = `${match.team2_id} - ${match.team2_name}`
  let parsedMatchSquad = null

  const [loading, setLoading] = useState(true)
  const [isDataReady, setIsDataReady] = useState(false)
  const [matchSquad, setMatchSquad] = useState(null);
  const [selectedPlayers, setSelectedPlayers] = useState({})
  const [restPlayers, setRestPlayers] = useState({})
  const [teamSelected, setTeamSelected] = useState(match.team1_name)
  const [teamOneCount, setTeamOneCount] = useState(0)
  const [teamTwoCount, setTeamTwoCount] = useState(0)
  
  const [nextBtnActv, setNextBtnActv ] = useState(false)
  const [errMsg, setErrMsg] = useState("")

  console.log("TeamOne:",teamOne, "TeamTwo:",teamTwo)

  useEffect(()=>{
      const fetchApi = async()=>{
         await axios.post(`${BASE_URL}/match-squad/`, { match_id: match.match_id }, {
                            headers: {
                              'Content-Type': 'application/json',
                              'Authorization': `Bearer ${token}`
                            }
                          })
                          .then(response => {
                            if (typeof response.data === 'string') {
                                try {
                                    const parsedData = JSON.parse(response.data);
                                    console.log("Parsed match squad:", parsedData);
                                    setMatchSquad(parsedData);
                                } catch (error) {
                                    console.error('Error parsing match squad:', error);
                                }
                            } else {
                                setMatchSquad(response.data);
                            }
                            // setLoading(false)
                            setIsDataReady(true)
                          })
                          .catch(error => {
                            console.error('Error fetching match squad:', error);
                            if (error.response && error.response.status === 401) {
                              navigate('/login');
                            }
                          });                  
      }
      fetchApi()
      
   
  }, [])

  useEffect(()=>{
    if (isDataReady === true)
    {
        parsedMatchSquad = JSON.parse(matchSquad)
        console.log("parsedMatchSquad", parsedMatchSquad)
        const teamOneLastPlayed = Object.entries(parsedMatchSquad.teams[teamOne])
                                    ?.filter(([_, playerDetails]) => playerDetails.player_details.played_last_game === "true")
        const teamOneLastNotPlayed = Object.entries(parsedMatchSquad.teams[teamOne])
                                        ?.filter(([_, playerDetails]) => playerDetails.player_details.played_last_game === "false")
        const teamTwoLastPlayed = Object.entries(parsedMatchSquad.teams[teamTwo])
                                        ?.filter(([_, playerDetails]) => playerDetails.player_details.played_last_game === "true");
        const teamTwoLastNotPlayed = Object.entries(parsedMatchSquad.teams[teamTwo])
                                        ?.filter(([_, playerDetails]) => playerDetails.player_details.played_last_game === "false");
     
        console.log("teamOneLastPlayed", teamOneLastPlayed)  
        console.log("teamOneLastNotPlayed", teamOneLastNotPlayed)
        console.log("teamTwoLastPlayed", teamTwoLastPlayed)
        console.log("teamTwoLastNotPlayed", teamTwoLastNotPlayed)

        setTeamOneCount(teamOneLastPlayed.length)
        setTeamTwoCount(teamTwoLastPlayed.length)

        const buildPlayers = (teamName) =>{
        const buildTeam = teamName.map(team => team[1])
        return buildTeam
        }

        setSelectedPlayers({
          [teamOne]: buildPlayers(teamOneLastPlayed),
          [teamTwo]: buildPlayers(teamTwoLastPlayed)
        })

        setRestPlayers({
          [teamOne]: buildPlayers(teamOneLastNotPlayed),
          [teamTwo]: buildPlayers(teamTwoLastNotPlayed)
        })

        console.log("SelectedPlayers", selectedPlayers)
        console.log("RestPlayers", restPlayers)    

        setLoading(false)
    }
  },[matchSquad]
)

useEffect(()=>{
  /* Check if the player count on both teams is atleast 11 */
  if(teamOneCount< 11 || teamTwoCount < 11){
    console.log("Players count less than 11")
    setNextBtnActv(true)
    setErrMsg("Please select playing 11 of both teams")
  }else if(teamOneCount> 12 || teamTwoCount > 12){
    setNextBtnActv(true)
    setErrMsg("Please select max playing 12 of both teams")
  }else{
    setNextBtnActv(false)
  }
},[teamOneCount, teamTwoCount])


const handleTeamChange = (teamName) =>{
  setTeamSelected(teamName)
}


const handleTeamPlayerChange= (teamName, playerId, lastPlayed, teamType)=>{ 
   if(teamType === "selected"){
        let playersArray = selectedPlayers[teamName]
        playersArray.forEach(player => {
                    let playerDetails = player.player_details
                    if(playerDetails.player_id === playerId)
                    {
                      playerDetails.played_last_game=lastPlayed
                    }
        } )
        setSelectedPlayers(prevState => ({...prevState, [teamName]: playersArray}))
   }else if(teamType === "rest"){
        let playersArray = restPlayers[teamName]
        playersArray.forEach(player => {
                    let playerDetails = player.player_details
                    if(playerDetails.player_id === playerId)
                    {
                      playerDetails.played_last_game=lastPlayed
                    }
        } )
        setRestPlayers(prevState => ({...prevState, [teamName]: playersArray}))
   }
  const selTeamOne = selectedPlayers[teamOne].filter(player => player.player_details.played_last_game === "true") 
  const restTeamOne = restPlayers[teamOne].filter(player => player.player_details.played_last_game === "true") 
  const oneCount= selTeamOne.length + restTeamOne.length
  setTeamOneCount(oneCount)

  console.log("SelTeamOne:", selTeamOne, "SelTeamCount:", selTeamOne.length)
  console.log("RestTeamOne:", restTeamOne, "RestTeamCount:", restTeamOne.length)
  console.log("Count team One:", oneCount)
  

  const selTeamTwo = selectedPlayers[teamTwo].filter(player => player.player_details.played_last_game === "true") 
  const restTeamTwo = restPlayers[teamTwo].filter(player => player.player_details.played_last_game === "true") 
  const twoCount = selTeamTwo.length + restTeamTwo.length
  console.log("Count team Two:", twoCount)
  setTeamTwoCount(twoCount)
}

// On click of Next button on the screen, build the selected players list 
// and navigate to approach selection team
const handleNextButton = () =>{
  console.log("SelectPlayers:",selectedPlayers)
  console.log("RestPlayers:",restPlayers)

  //Build the list of selected squads from both teams
  const finalSelTeamOne = selectedPlayers[teamOne].filter(player => player.player_details.played_last_game ==="true")
  const finalRestTeamOne = restPlayers[teamOne].filter(player => player.player_details.played_last_game ==="true")
  
  const finalSelTeamTwo = selectedPlayers[teamTwo].filter(player => player.player_details.played_last_game ==="true")
  const finalRestTeamTwo = restPlayers[teamTwo].filter(player => player.player_details.played_last_game ==="true") 
  
  //Update the state of selected squads from both teams
  const finalTeamOne = finalSelTeamOne.concat(finalRestTeamOne)
  const finalTeamTwo = finalSelTeamTwo.concat(finalRestTeamTwo)
  
  const buildPlayers={
    [teamOne] : finalTeamOne ,
    [teamTwo] : finalTeamTwo
  }
  console.log("BuildPlayers:", buildPlayers)

  localStorage.removeItem(lstorageKey)
  localStorage.setItem(lstorageKey, JSON.stringify(buildPlayers))
  
  // navigate('/approach', {state: buildPlayers})
  navigate('/approach')
}


  if(loading === true){
    return(
      <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000
      }}>
        <Lottie animationData={ballSpinner} loop={true} style={{ width: '50px', height: '50px' }} />
      </div>
    )
  }

 
 
  return (
    <div className="m-select-players-container">
      
        <span className="select-players-toptext">Choose the playing 11 from both teams </span>
        <div className="players-text-block">
            <img src="/images/mobile/info.svg" alt="" />
            <span>Pre-selection are based on previous match played. Click on the player to view the player card. </span>
        </div>

        <div className="player-list-container">
            <div className="player-list-teams">
                <div className={(teamSelected=== match.team1_name) ?"player-list-team-name bg-team-white":"player-list-team-name"}
                      onClick={() => handleTeamChange(match.team1_name)}>
                    <span>{match.team1_name}</span>
                    <span className="player-count">{teamOneCount}</span>
                </div>
                <div className={(teamSelected=== match.team2_name)?"player-list-team-name bg-team-white":"player-list-team-name"}
                      onClick={() => handleTeamChange(match.team2_name)}>
                    <span>{match.team2_name}</span>
                    <span className="player-count">{teamTwoCount}</span>
                </div>   
            </div>  
            <div className="player-list-wrapper">
                <p className="player-list-top-text">Played Previous Match</p>
                
                {(teamSelected=== match.team1_name)? 
                    selectedPlayers[teamOne].map((player) => (
                      <BuildPlayerListM player={player}
                                       teamName={teamOne} 
                                       handleTeamPlayerChange={handleTeamPlayerChange}
                                       teamType="selected"
                                       key={player.player_details.player_id}  
                      />))
                       : 
                      selectedPlayers[teamTwo].map((player) => (
                        <BuildPlayerListM player={player}
                                         teamName={teamTwo}  
                                         handleTeamPlayerChange={handleTeamPlayerChange}
                                         teamType="selected"
                                         key={player.player_details.player_id}  
                        />))
                }
                <p className="player-list-top-text">Rest of the Squad</p>
                {(teamSelected=== match.team1_name)? 
                  restPlayers[teamOne].map((player) => (
                    <BuildPlayerListM player={player} 
                                     teamName={teamOne}
                                     handleTeamPlayerChange={handleTeamPlayerChange}
                                     teamType="rest"
                                     key={player.player_details.player_id}  
                    />)): 
                    restPlayers[teamTwo].map((player) => (
                      <BuildPlayerListM player={player} 
                                       teamName={teamTwo}
                                       handleTeamPlayerChange={handleTeamPlayerChange}
                                       teamType="rest"
                                       key={player.player_details.player_id}  
                      />))
                }
            </div> 
        </div>
        {/*This division displays error message in case of error and disable Next button
           When no error, that is playng 11 teams selected, Next btn will be enabled  */}
        <div className="select-players-next-btn">
          {
            nextBtnActv ? 
              <div className="select-player-error">
                <img src="/images/mobile/alert-icon.svg" alt="" />
                <span>{errMsg}</span>
              </div>
              : null
          }
          <button className={nextBtnActv ? "select-player-next-deact":"select-player-next-act"}
                  disabled={nextBtnActv}
                  onClick={handleNextButton}>Next</button>
        </div>
    </div>
  )
}

export default MatchSelectPlayersM

