import React from 'react'
import { useNavigate } from 'react-router-dom';
import './landingcontent.css'
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const LandingContent = () => {

    const navigate = useNavigate();
    const location = useLocation();
    
    const redirectToLogin = () => {
        navigate('/login');  // Redirect to login page
    };
    useEffect(() => {
        const hash = location.hash;
        if (hash) {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }, 100); // Adjust delay time as necessary
        }
    }, [location]); // Trigger when location changes, specifically the hash
    
  return (
    <div id="a-how-works" className="land-content-container">
        <div className="land-content-wrapper">
            <p className="how-works-text">Build your team in <span>3 steps</span></p>
            <div className="land-works-desktop">
                <div className="land-content-works">
                    <div className="land-works-container">
                        <div className="numbering">1</div>
                        <p className="land-download-text">Confirm the playing 11 of completing teams</p>
                        <div className="land-matchreport-img">
                            <img className="land-works-images" src="/images/common/landing-sc1.svg" alt="" />
                        </div>
                    </div>
                
                    <div className="land-works-container">
                        <div className="numbering">2</div>
                        <p className="land-download-text">Choose the approach and provide some inputs</p>
                        <div className="land-aichat-img">
                            <img className="land-works-images" src="/images/common/landing-sc2.svg" alt="123" />
                        </div>
                    </div>
                </div>
                <div className="land-works-container3">
                    <div className="numbering">3</div>
                    <p className="land-download-text">Boom! Save your selected teams</p>
                    <div className="land-payerlist-img">
                        <img className="land-works-images" src="/images/common/landing-sc3.svg" alt="123" />
                    </div>
                </div>
            </div>
        </div>    
        <div className="land-fantasy-container">
            <p className="land-fantasy-text">Know the insights at a glance</p>
            <p className="fantasy-desc-text">Access the cheatsheet to get the data you need</p>
            <img className="land-report-img-mobile" src="/images/common/report.svg" alt="" />
            <div className="land-features-container">
              <div className="land-features-contlist land-feature-contone">
                  <div className="land-fantasy-list">
                      <img className="land-fantasy-icons" src="/images/common/uil_language.svg" alt="" />
                      <p className="land-fantasy-textone">Multi-language support</p>
                      <p className="land-fantasy-texttwo">Now it is available in English and Hindi</p>
                  </div>
                  <div className="land-feature-desktop">
                    <img className="land-report-img" src="/images/common/report.svg" alt="" />
                  </div>
                  <div className="land-fantasy-list">
                      <img className="land-fantasy-icons" src="/images/common/target.svg" alt="" />
                      <p className="land-fantasy-textone">Only Precise data</p>
                      <p className="land-fantasy-texttwo">Say no to complex and unwanted data</p>
                  </div>
              </div>
              <div className="land-features-contlist land-feature-conttwo">
                  <div className="land-fantasy-list">
                      <img className="land-fantasy-icons" src="/images/common/cap.svg" alt="" />
                      <p className="land-fantasy-textone">Player stats</p>
                      <p className="land-fantasy-texttwo">Analyze players based on their past performances</p>
                  </div>
                  <div className="land-start-btn-wrapper-desktop">
                    <button>Start for free</button>
                    <span>*Free trial for 1 week</span>
                </div>
                  <div className="land-fantasy-list">
                      <img className="land-fantasy-icons" src="/images/common/location.svg" alt="" />
                      <p className="land-fantasy-textone">Pitch Report</p>
                      <p className="land-fantasy-texttwo">Choose players based on the pitch report</p>
                  </div>
              </div>
              <div className="land-start-btn-wrapper-mobile">
                    <button>Start for free</button>
                    <span>*Free trial for 1 week</span>
              </div>
            </div>
        </div>
        
        <div id="a-pricing"  className="land-pricing-container">
            <p className="land-fantasy-text">Pricing</p>
            <p className="land-sub-text">Choose the plan that suits your need</p>
            <div className="price-card-container">
                <div className="price-card">
                    <p className="land-price-top-text">TRIAL</p>
                    <div className="price-wrapper">
                        <span className="land-price-amt">₹0</span>
                        <span className="land-month-text">/1 - Week Free Trial</span>
                    </div>
                    {/* <button className="land-price-btn">Buy Plan</button> */}
                    <button className="land-price-btn" onClick={redirectToLogin}>Login To Access Free Trial</button>
                
                    <span className="land-included-text">What's included</span>
                    <ul>
                        <li className="land-other-text">Cheat Sheet</li>
                        <li className="land-other-text">Recent Venue Stats</li>
                        <li className="land-other-text">Recent Player Stats</li>
                        <li className="land-other-text">Customized Insights for Fantasy Cricket</li>
                        <li className="land-other-text">Build Fantasy Teams</li>
                    </ul>
                </div>
                <div className="price-card">
                    <p className="land-price-top-text">PRO - MONTHLY</p>
                    <div className="price-wrapper">
                        <span className="land-price-amt">₹99</span>
                        <span className="land-month-text">/month</span>
                    </div>
                    <button className="land-price-btn" onClick={redirectToLogin}>Login To Buy Plan</button>
                
                    <span className="land-included-text">What's included</span>
                    <ul>
                        <li className="land-other-text">Cheat Sheet</li>
                        <li className="land-other-text">Recent Venue Stats</li>
                        <li className="land-other-text">Recent Player Stats</li>
                        <li className="land-other-text">Customized Insights for Fantasy Cricket</li>
                        <li className="land-other-text">Build Fantasy Teams</li>
                    </ul>
                </div>
                <div className="price-card">
                    <p className="land-price-top-text">PRO - YEARLY</p>
                    <div className="price-wrapper">
                        <span className="land-price-amt">₹899</span>
                        <span className="land-month-text">/year</span>
                    </div>
                    <button className="land-price-btn" onClick={redirectToLogin}>Login To Buy Plan</button>
                  
                    <span className="land-included-text">What's included</span>
                    <ul>
                        <li className="land-other-text">Cheat Sheet</li>
                        <li className="land-other-text">Recent Venue Stats</li>
                        <li className="land-other-text">Recent Player Stats</li>
                        <li className="land-other-text">Customized Insights for Fantasy Cricket</li>
                        <li className="land-other-text">Build Fantasy Teams</li>
                    </ul>
                </div>
            </div>
        </div>
        
    </div>
  )
}

export default LandingContent