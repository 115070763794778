import { useState} from 'react'
import './guesscheatsheetM.css'

const GuessCheatSheetM = ({teamOne, teamTwo, activeTeam, handleTeamChange,batTeam, handleInputChanges}) => {
  const [caretUpFirst, setCaretUpFirst] = useState(true) 
  const [caretUpSecond, setCaretUpSecond] = useState(true) 

  const handleAdvDropdownFirst = () =>{
    setCaretUpFirst(prevState => !prevState)
    setCaretUpSecond(prevState => !prevState)
    
  }  

  const handleAdvDropdownSecond = () =>{
    setCaretUpSecond(prevState => !prevState)
    setCaretUpFirst(prevState => !prevState)
  }  

  const handleFirstTeam = (activeTeam) =>{
        handleTeamChange(activeTeam)
  }

  console.log("Bat team:",batTeam)

  return (
    <div className="guess-cs-container" >
      <div className="guess-cs-wrapper" >
        <div className="guess-bat-team-wrapper">
            <p>First Batting team</p>
            <div className="guess-bat-team">
                <button className={activeTeam===teamOne? "appr-active-team": null}
                        onClick={()=>handleFirstTeam(teamOne)}>{teamOne}</button>
                <button className={activeTeam===teamTwo? "appr-active-team": null}
                    onClick={()=>handleFirstTeam(teamTwo)}>{teamTwo}</button>
               
            </div>
        </div>
        <div className="guess-inputs">
            <label htmlFor='score'>Expected score</label>
            <select id="score" name="firstTeamExpScore" 
                    value={batTeam.firstTeamExpScore} 
                    onChange={(e)=>handleInputChanges(e)}
                    className="guess-input-options"
            >
                <option value=""></option>
                <option value="<100">&lt;100</option>
                <option value="100-130">100-130</option>
                <option value="130-150">130-150</option>
                <option value="150-170">150-170</option>
                <option value="170-200">170-200</option>
                <option value="<200">&gt;200</option>
            </select>
        </div>
        <div className="guess-inputs">
            <label htmlFor='wicket'>Expected Wickets</label>
            <select id="wicket" name="firstTeamExpWktFallen" value={batTeam.firstTeamExpWktFallen} onChange={(e)=>handleInputChanges(e)}>
                <option value="">0</option>
                <option value="<=4">&lt;=4</option>
                <option value="<=6">&lt;=6</option>
                <option value="<=8">&lt;=8</option>
                <option value=">=9">&gt;=9</option>
            </select>
        </div>
        <div className="appr-adv-input-container">
            <div className="appr-adv-text" onClick={handleAdvDropdownFirst}   >
                <p>Advanced Input</p>
                <img src={caretUpFirst? "/images/mobile/chevron-down.svg": "/images/mobile/chevron-up.svg"} 
                        alt=""    
                />   
            </div>
            {
              !caretUpFirst ?
                (<div>
                    <div className="guess-adv-wrapper">
                        <div className="guess-inputs-adv">
                            <label htmlFor='runs-p'>Runs in Power-Play</label>
                            <input type="text" id="runs-p" 
                                    name="firstTeamRunsPP" 
                                    value={batTeam.firstTeamRunsPP} 
                                    onChange={(e)=>handleInputChanges(e)}
                                    pattern="[0-9]{3}"
                            />  
                        </div>
                        <div className="guess-inputs-adv">
                            <label htmlFor='wicket-p'>Wickets in Power-Play</label>
                            <input type="text" id="wicket-p" 
                                    name="firstTeamWicketPP" 
                                    value={batTeam.firstTeamWicketPP} 
                                    onChange={(e)=>handleInputChanges(e)}
                                    pattern="[0-9]{2}"
                            />      
                        </div>
                    </div>
                    <div>
                    <div className="guess-adv-wrapper">
                        <div className="guess-inputs-adv">
                            <label htmlFor='runs-m'>Runs in Middle Overs</label>
                            <input type="text" id="runs-m" 
                                    name="firstTeamRunsMO" 
                                    value={batTeam.firstTeamRunsMO} 
                                    onChange={(e)=>handleInputChanges(e)}
                                    pattern="[0-9]{3}"
                            />
                        </div>
                        <div className="guess-inputs-adv">
                            <label htmlFor='wicket-m'>Wickets in Middle Overs</label>
                            <input type="text" id="wicket-m" 
                                    name="firstTeamWicketMO" 
                                    value={batTeam.firstTeamWicketMO} 
                                    onChange={(e)=>handleInputChanges(e)}
                                    pattern="[0-9]{2}"
                            />
                        </div>
                        </div>
                    </div>
                    <div>
                    <div className="guess-adv-wrapper">
                        <div className="guess-inputs-adv">
                            <label htmlFor='runs-d'>Runs in Death Overs</label>
                            <input type="text" id="runs-d" 
                                    name="firstTeamRunsDO" 
                                    value={batTeam.firstTeamRunsDO} 
                                    onChange={(e)=>handleInputChanges(e)}
                                    pattern="[0-9]{3}"
                             />
                        </div>
                        <div className="guess-inputs-adv">
                            <label htmlFor='wicket-d'>Wickets in Death Overs</label>
                            <input type="text" id="wicket-d" 
                                    name="firstTeamWicketDO" 
                                    value={batTeam.firstTeamWicketDO} 
                                    onChange={(e)=>handleInputChanges(e)}
                                    pattern="[0-9]{2}"
                            />       
                        </div>
                        </div>
                    </div>
                </div>)
                : null
            }
          </div>
        </div>

       <div className="guess-cs-wrapper" >
        <div className="guess-bat-team-wrapper">
            <p>Second Batting team</p>
            <div className="guess-bat-team">
                <button className="appr-active-team">
                    {activeTeam===teamOne?teamTwo: teamOne}
                </button>
            </div>
        </div>
        <div className="guess-inputs">
            <label htmlFor='score'>Expected score</label>
            <select id="score" name="secondTeamExpScore" value={batTeam.secondTeamExpScore} onChange={(e)=>handleInputChanges(e)}>
                <option value=""></option>
                <option value="<100">&lt;100</option>
                <option value="100-130">100-130</option>
                <option value="130-150">130-150</option>
                <option value="150-170">150-170</option>
                <option value="170-200">170-200</option>
                <option value="<200">&gt;200</option>
            </select>
        </div>
        <div className="guess-inputs">
            <label htmlFor='wicket'>Expected Wickets</label>
            <select id="wicket" name="secondTeamExpWktFallen" value={batTeam.secondTeamExpWktFallen} onChange={(e)=>handleInputChanges(e)}>
                <option value="">0</option>
                <option value="<=4">&lt;=4</option>
                <option value="<=6">&lt;=6</option>
                <option value="<=8">&lt;=8</option>
                <option value=">=9">&gt;=9</option>
            </select>
        </div>
        <div className="appr-adv-input-container">
            <div className="appr-adv-text" onClick={handleAdvDropdownSecond}   >
                <p>Advanced Input</p>
                <img src={caretUpSecond? "/images/mobile/chevron-up.svg": "/images/mobile/chevron-down.svg"} 
                        alt=""  
                />   
            </div>
            {
              !caretUpSecond ?
                (<div>
                    <div className="guess-adv-wrapper">
                        <div className="guess-inputs-adv">
                            <label htmlFor='runs-p'>Runs in Power-Play</label>
                            <input type="text" id="runs-p" name="secondTeamRunsPP" 
                                value={batTeam.secondTeamRunsPP} 
                                onChange={(e)=>handleInputChanges(e)}
                                pattern="[0-9]{3}"
                            />
                        </div>
                        <div className="guess-inputs-adv">
                            <label htmlFor='wicket-p'>Wickets in Power-Play</label>
                            <input type="text" id="wicket-p" name="secondTeamWicketPP" 
                                    value={batTeam.secondTeamWicketPP} 
                                    onChange={(e)=>handleInputChanges(e)}
                                    pattern="[0-9]{2}"
                            />
                        </div>
                    </div>
                    <div>
                    <div className="guess-adv-wrapper">
                        <div className="guess-inputs-adv">
                            <label htmlFor='runs-m'>Runs in Middle Overs</label>
                            <input type="text" id="runs-m" name="secondTeamRunsMO" 
                                    value={batTeam.secondTeamRunsMO} 
                                    onChange={(e)=>handleInputChanges(e)}
                                    pattern="[0-9]{3}"
                            />     
                        </div>
                        <div className="guess-inputs-adv">
                            <label htmlFor='wicket-m'>Wickets in Middle Overs</label>
                            <input type="text" id="wicket-m" name="secondTeamWicketMO" 
                                    value={batTeam.secondTeamWicketMO} 
                                    onChange={(e)=>handleInputChanges(e)}
                                    pattern="[0-9]{2}"
                            />
                        </div>
                        </div>
                    </div>
                    <div>
                    <div className="guess-adv-wrapper">
                        <div className="guess-inputs-adv">
                            <label htmlFor='runs-d'>Runs in Death Overs</label>
                            <input type="text" id="runs-d" name="secondTeamRunsDO" 
                                    value={batTeam.secondTeamRunsDO} 
                                    onChange={(e)=>handleInputChanges(e)}
                                    pattern="[0-9]{3}"
                            />      
                        </div>
                        <div className="guess-inputs-adv">
                            <label htmlFor='wicket-d'>Wickets in Death Overs</label>
                            <input type="text" id="wicket-d" name="secondTeamWicketDO" 
                                    value={batTeam.secondTeamWicketDO} 
                                    onChange={(e)=>handleInputChanges(e)}
                                    pattern="[0-9]{2}"
                            />
                        </div>
                        </div>
                    </div>
                </div>)
                : null
            }
        </div>
       </div> 
    </div>
  )
}

export default GuessCheatSheetM