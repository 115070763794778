import React, { useState } from 'react';
import './privacystatement.css';


function PrivacyStatement() {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };
    return (
        <div className="root2">
            <header>
                <a href="https://cricbotz.com">
                    <img className="logo_static" src="images/logo-name.svg" alt="Cricbotz Logo" style={{ height: '50px' }} />
                </a>
                <div className={`nav-links-2 ${menuOpen ? 'open' : ''}`}>
                    <a href="/how_it_works">How It works</a>
                    <a href="/#a-pricing">Pricing</a>
                    <a href="https://blogs.cricbotz.com">Blog</a>
                    <a href="/login" className="login-btn-2">Login</a>
                </div>
                <div className="hamburger" onClick={toggleMenu}>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div>
            </header>
            <div className="content">
                <h1>Privacy Statement</h1>
                <p>
                Last updated June, 2024
                </p>
                <div class="section">                    
                    <p>This privacy notice for Cricbotz ("we," "us," or "our"), describes how and why we might collect, store, use, and/or share ("process") your information when you use our services ("Services”).
                        Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services.</p>
                </div>
                <div class="section">
                    <h2>WHAT INFORMATION DO WE COLLECT?</h2>
                    <p>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</p>
                </div>
                <div class="section">
                    <h2>INFORMATION AUTOMATICALLY COLLECTED</h2>
                    <p>We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</p>
                </div>
                <div class="section">
                    <h2>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>
                    <p><strong>Third-Party Service Providers:</strong> We engage various third-party service providers to perform functions and provide services to us, including analytics and user insights services. We share your non-personally identifiable information with these providers to help us understand user behavior and improve our services. These third-party providers are authorized to use this information only as necessary to provide these services to us and are obligated to maintain the confidentiality and security of the information.</p>
                </div>
                <div class="section">
                    <h2>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
                    <p>We may use cookies and similar tracking technologies to gather information when you interact with our Services. Some online tracking technologies help us maintain the security of our Services, prevent crashes, fix bugs, save your preferences, and assist with basic site functions.</p>
                </div>
                <div class="section">
                    <h2>DO WE COLLECT INFORMATION FROM MINORS?</h2>
                    <p>We do not knowingly collect, solicit data from, or market to children under 18 years of age, nor do we knowingly sell such personal information. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records.</p>
                </div>
                <div class="section">
                    <h2>DO WE MAKE UPDATES TO THIS NOTICE?</h2>
                    <p>We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date at the top of this privacy notice. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information</p>
                </div>
                <div class="section">
                    <h2>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
                    <p>If you have questions or comments about this notice, you may contact us by Email at: support@cricbotz.com</p>
                </div>
            </div>
            <div className="land-footer-container">
                <div className="land-logo-footer">
                    <img src="/images/common/logo-footer.svg" alt="" className="land-logo-name-footer"/>
                </div>
                <div className="land-footer-links">
                    <a className="land-link-list" href="#">support@cricbotz.com</a>
                    <a className="land-link-list" href="/terms-and-conditions">Terms & Conditions</a>
                    <a className="land-link-list" href="/privacy-policy">Privacy Policy</a>
                    <a className="land-link-list" href="/billing-subscription">Billing And Subscription</a>
                </div>
                </div>
        </div>
    );
}

export default PrivacyStatement;
