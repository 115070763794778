import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './myteams.css';
import Lottie from "lottie-react";
import ballSpinner from '../../lotties/ball-loading.json'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import FeedbackButton from '../../components/Home/Common/FeebackButton';

function formatPlayerType(type) {
    return type.replace(/_/g, ' ')
        .replace(/(first|second) /, '')
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

const MyTeams = ({ activeMatch }) => {
    const navigate = useNavigate();  // For redirection to login if needed
    const [teamList, setTeamList] = useState([]);
    const [teamFilters, setTeamFilters] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState('All');
    const [selectedApproach, setSelectedApproach] = useState('All');
    const [selectedTeamDetails, setSelectedTeamDetails] = useState(null);
    const [teamStyles, setTeamStyles] = useState({});
    const [selectedTeamIndex, setSelectedTeamIndex] = useState(null); // Track the index of the selected team
    const [selectedTeamTitle, setSelectedTeamTitle] = useState(''); // Track the title of the selected team
    const [insights, setInsights] = useState('');
    const [chartData, setChartData] = useState([]); 
    const [isLoading, setIsLoading] = useState(false);

    const loadingOverlay = isLoading ? (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000
        }}>
          <Lottie animationData={ballSpinner} loop={true} style={{ width: '50px', height: '50px' }} />
        </div>
    ) : null;


    const handleFilterChange = (filter) => {
        setSelectedFilter(filter);
        setSelectedTeamDetails(null)
        setInsights('')
        setChartData([])
        setSelectedTeamIndex(null)
    };

    const handleApproachChange = (approach) => {
        setSelectedApproach(approach);
    };


    useEffect(() => {
        if (!selectedTeamDetails) return; // Make sure the data is there before attempting to use it
    
        const uniqueTeams = new Set(selectedTeamDetails.map(player => player.team_name));
        const teamArray = Array.from(uniqueTeams);
        setTeamStyles({
            [teamArray[0]]: 'teamStyleBlack',
            [teamArray[1]]: 'teamStyleWhite',
        });
    }, [selectedTeamDetails]);



    useEffect(() => {
        if (!activeMatch) return;

        const token = localStorage.getItem('idToken');
        if (!token) {
            console.error('No token found, redirecting to login.');
            navigate('/login');
            return;
        }
        setIsLoading(true);
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/fetch-teams/`, { match_id: activeMatch.match_id }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            console.log("Teams fetched:", response.data);
            setTeamList(response.data.filenames.map(file => ({
                ...file,
                approachNumber: file.filename.split('||')[0],
                firstBattingTeam: file.filename.split('||')[1],
                secondBattingTeam: file.filename.split('||')[2],
                approach: file.filename.split('||')[0] === '1' ? 'Match Score' : 'Player Position',
                fileTitle: file.filetitle
            })));
            setTeamFilters(response.data.team_filters);
            setIsLoading(false);
        })
        .catch(error => {
            console.error('Error fetching teams:', error);
            if (error.response && error.response.status === 401) {
                navigate('/login');
            }
            setIsLoading(false);
        });
    }, [activeMatch, navigate]);
    
    // Fetch single team data
    const fetchSingleTeam = (filepath, index) => {
        const token = localStorage.getItem('idToken');
        if (!token) {
            console.error('No token found, redirecting to login.');
            navigate('/login');
            return;
        }
        setIsLoading(true);
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/fetch-single-team/`, { match_id: activeMatch.match_id, filepath }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            console.log("Single Team data:", response.data);
            setSelectedTeamDetails(response.data.file_data.team_details);
            setChartData(response.data.file_data.chartData);
            console.log(response.data.file_data.chartData)
            setInsights(response.data.file_data.insights)
            setSelectedTeamIndex(index); // Set the selected index
            setSelectedTeamTitle(teamList[index].fileTitle); // Set the title of the selected team
            setIsLoading(false);
        })
        .catch(error => {
            console.error('Error fetching single team:', error);
            if (error.response && error.response.status === 401) {
                navigate('/login');
            }
            setIsLoading(false);
        });
    };
    

    const filteredList = teamList.filter(team => 
        (selectedApproach === 'All' || team.approach === selectedApproach) &&
        (selectedFilter === 'All' || team.firstBattingTeam === selectedFilter)
    );


    return (
        <div className='MyTeamsContainer'>
            <div className='MyTeamsFilterAndTeamListContainer'>
                <div className='MyTeamsFilterContainer'>
                    <span> Filters</span>
                    <div className='MyTeamsSubtitle'>
                        Approach
                    </div>
                    <div className="MyTeamsFilterButtons">
                        {/* {['All', 'Match Score', 'Player Position'].map((approach, index) => ( */}
                        {['All', 'Match Score'].map((approach, index) => (
                                <button className='MyTeamsButton'
                                    key={index} 
                                    onClick={() => handleApproachChange(approach)}
                                    style={{
                                        backgroundColor: selectedApproach === approach ? '#00876c' : '#fff',
                                        color: selectedApproach === approach ? '#fff' : '#000',
                                        // width: approach !== 'All' ? '140px' : 'auto'
                                    }}
                                >
                                    {approach}
                                </button>
                            ))}
                    </div>
                    <div className='MyTeamsSubtitle'>
                        First Batting
                    </div>
                    <div className="MyTeamsFilterButtons">
                        {['All', ...teamFilters].map((filter, index) => (
                            <button className='MyTeamsButton'
                                key={index} 
                                onClick={() => handleFilterChange(filter)}
                                style={{
                                    backgroundColor: selectedFilter === filter ? '#00876c' : '#fff',
                                    color: selectedFilter === filter ? '#fff' : '#000',
                                    
                                }}
                            >
                                {filter}
                            </button>
                        ))}
                    </div>
                </div>
                <div className='MyTeamsListAndPlayerListContainer'>
                    <div className="MyTeamsList-left">
                    <div className='MyTeamsList'>
                        <span className="MyTeam-name">TEAMS</span>
                        <div className='MyTeamCards'>
                            {
                             filteredList.length !== 0 ?
                                filteredList.map((team, index) => (
                                <div key={index} 
                                    className={`teamItem ${index === selectedTeamIndex ? 'selectedTeam' : ''}`} 
                                    onMouseEnter={e => index !== selectedTeamIndex && (e.currentTarget.style.backgroundColor = '#e3ece8')}
                                    onMouseLeave={e => index !== selectedTeamIndex && (e.currentTarget.style.backgroundColor = '#F6f3ef')}
                                    onClick={() => {
                                        console.log(`Setting selected index: ${index}`);
                                        fetchSingleTeam(team.filename, index);
                                    }}
                                >
                                    <span>{team.fileTitle}</span>
                                    <p>Based on Approach - {team.approach}</p> 
                                    <p>First Batting Team - {team.firstBattingTeam}</p>
                                </div>
                            ))
                            :
                            <span className="myteams-no-teams-text">No teams to show</span> 
                        }
                        </div>   
                    </div>
                    <div className='MyTeamsDetails'>
                        {selectedTeamDetails ? (
                            <div className='MyTeamsDetailsContainerWrapper'>
                                <span className="MyTeam-name">{selectedTeamTitle}</span> {/* Display the title of the selected team */}
                                <div className='MyTeamsPlayerListContainer'>
                                {selectedTeamDetails.map((player, index) => (
                                    <div key={index} className="MyTeamsPlayerCard">
                                        <div className='MyTeamsplayerDetailContainer'>
                                            <span className='playerSerialNumber myteam-serial'>{index + 1}</span> 
                                            <div className="myteams-right-details">
                                                <div className="myteam-details">
                                                    <div className="myteams-playerdetails">
                                                        <span className='myteam-playerName'>{player.player_name}</span>
                                                        <span className={`teamName ${teamStyles[player.team_name] || ''}`}>{player.team_name}</span>
                                                        <img src={player.type.includes('bat') ? "/images/baticon.svg" : "/images/bowler.svg"} alt={player.type.includes('bat') ? "Bat Icon" : "Bowler Icon"} style={{ marginRight: '8px' }} />
                                                    </div>
                                                    <span className='playerType'>{formatPlayerType(player.type)}</span>
                                                </div>
                                                {['C', 'VC'].includes(player.captain_flag) && (
                                                    <span className="captainBadge">{player.captain_flag}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                </div>
                            </div>
                        ) : (
                            <div className='MyTeamsDetailsContainerWrapper'>
                                <span className="MyTeam-name">Team Details</span>
                                <div className="MyTeamsPlayerCard">
                                    <div className='MyTeamsplayerDetailContainer'> 
                                        <span className="myteams-no-teams-text">Select a team to view the player list</span>
                                    </div>
                                </div>
                            </div>

                        )}
                    </div>
                    </div> {/* First left container*/}

                    <div className='MyTeamsInsightChartContainer'>
                        
                        <span className="MyTeam-name">Team Scenario</span>
                        <div className="MyTeamsInsight-wrapper">
                        {
                         chartData.length !== 0 ?
                        <>
                        <p>{insights}</p>
                        <div className="MyTeamsChartContainer">
                        <ResponsiveContainer width="100%" height={200}>
                            <BarChart data={chartData} margin={{ top: 5, right: 30, left: 5, bottom: 5 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                {/* <Legend /> */}
                                <Bar dataKey="First_Innings_Runs" fill="#00876c" name="First Innings Runs" />
                                <Bar dataKey="Second_Innings_Runs" fill="#7ead9d" name="Second Innings Runs" />
                            </BarChart>
                        </ResponsiveContainer>
                        {/* </div>
                        <div className="MyTeamsChartContainer"> */}
                        <ResponsiveContainer width="100%" height={200}>
                            <BarChart data={chartData} margin={{ top: 5, right: 35, left: 5, bottom: 5 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                {/* <Legend /> */}
                                <Bar dataKey="First_Innings_Wickets" fill="#00876c" name="First Innings Wickets" />
                                <Bar dataKey="Second_Innings_Wickets" fill="#7ead9d" name="Second Innings Wickets" />
                            </BarChart>
                        </ResponsiveContainer>
                        </div>
                        </>
                        :
                        <span className="myteams-no-teams-text">No Data to show</span> 
                        }
                        </div>
                    </div>
                    
                </div>
        </div>
        {loadingOverlay}
        <FeedbackButton/>
    </div>
);
}
export default MyTeams