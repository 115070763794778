import React from 'react'
import MatchSelectedM from '../../../components/Home/Mobile/MatchSelectedM'
import MatchSelectPlayersM from '../../../components/Home/Mobile/MatchSelectPlayersM'
import './matchselectplayerspage.css'
import FeedbackButton from '../../../components/Home/Common/FeebackButton';

const MatchSelectPlayersPage = () => {
  return (
    <div className="match-select-container">
        <MatchSelectedM />
        <MatchSelectPlayersM />
        {/* <FeedbackButton/> */}
    </div>
  )
}

export default MatchSelectPlayersPage