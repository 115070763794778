import React from 'react'
import './landingheader.css'
import { useNavigate } from 'react-router-dom'

const LandingHeader = () => {
    const navigate = useNavigate()

    const handleSignIn = () =>{
        navigate('/login')
    }

    return (
        <div className="land-header-container">
            <div className="land-top">
                <div className="land-top-left">
                    <div className="land-logo-img">
                        <img src="/images/mobile/logo.svg" alt="" className="land-logo-name-header"/>
                    </div>
                    <div className="land-bg-image">
                        <img src="/images/common/rectangle.svg" alt="" />
                    </div>
                </div>
            
                <div className="land-top-right">
                    <a href="#a-how-works" className="land-header-right-text">How it works</a>
                    <a href="#a-pricing" className="land-header-right-text">Pricing</a>
                    <button className="land-get-started-btn" onClick={handleSignIn}>Login</button>
                </div>
            
            </div>

            <div className="land-center-section-one">
                <div className="land-center-one-left">
                    <p className="land-center-textone">Level Up Your Fantasy Cricket Game</p>
                    <p className="land-center-texttwo">Data-powered insights and strategies for smarter fantasy team building</p>
                    <div className="land-btn-wrapper">
                        <button className="land-build-btn" onClick={handleSignIn}>Build Team Now</button>
                        <button className="land-login-btn" onClick={handleSignIn}>Login</button>
                    </div>
                </div>
                <div className="land-center-one-right">
                    <img src="/images/cricbotzImg.svg" alt="" className="cricbotz-img"/>
                </div>
            </div>
        </div>
    )
    }

export default LandingHeader