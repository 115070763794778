import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { WarningAmber } from '@mui/icons-material';
import './subscriptioncancellation.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const SubscriptionCancellation = ({ close, proceedWithCancellation }) => {
  const [remainingDays, setRemainingDays] = useState(null);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (userDetails) {
      const termEnd = userDetails.term_end;
      const currentDate = Math.floor(Date.now() / 1000); // Get current date in epoch format
      const daysLeft = Math.round((termEnd - currentDate) / (60 * 60 * 24));
      setRemainingDays(daysLeft);
      setSubscriptionId(userDetails.subscription_id);
    }
  }, []);

  const handleProceedWithCancellation = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/cancel_subscription/`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ subscription_id: subscriptionId }),
      });

      const data = await response.json();
      if (response.ok) {
        if (data.message === 'Successfully cancelled') {
          proceedWithCancellation()
          

        } else {
          alert('Cancellation failed');
        }
      } else {
        alert('An error occurred: ' + JSON.stringify(data));
      }
    } catch (error) {
      alert('An error occurred: ' + JSON.stringify(error));
    }
  };
    



  return (
    <div className="modal-overlay">
      <div className="error-modal">
        <div className="error-modal-content">
          <div className="modal-header">
            <button className="modal-close-icon" onClick={close}>
              <img src="/images/close_icon_2.svg" alt="Close" />            
            </button>
            <h4>Subscription Cancellation</h4>
          </div>
          <WarningAmber style={{ fontSize: 40, color: 'red' }} />
          <p>
            This is a non-recurring subscription. You still have {remainingDays} days left in your subscription. Are you sure you want to cancel the Subscription?
          </p>
          <p>
            Please go through the <Link to="/billing-subscription" className="cancellationlink">policy on refunds</Link>.
          </p>
          <div className="modal-actions">
            <button className="cancel-button" onClick={close}>Cancel</button>
            <button className="proceed-button" onClick={handleProceedWithCancellation}>Proceed with Cancellation</button>
          </div>
        </div>
      </div>
    </div>
  );
};

SubscriptionCancellation.propTypes = {
  close: PropTypes.func.isRequired,
  proceedWithCancellation: PropTypes.func.isRequired,
};

export default SubscriptionCancellation;
