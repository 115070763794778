import { useState, useEffect } from 'react'
//import TeamsSelected from './TeamsSelected'
import MatchSelectedM from './MatchSelectedM'
import './showmyteams.css'
import Lottie from "lottie-react";
import ballSpinner from '../../../lotties/ball-loading.json'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';

const ShowMyTeams = () => {
  const match = JSON.parse(localStorage.getItem('teamSavedSelected'))
  
  const [teamList, setTeamList] = useState([]);
  const [teamFilters, setTeamFilters] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('All');
  const [selectedApproach, setSelectedApproach] = useState('All');
  const [isLoading, setIsLoading] = useState(true);
//   const [filteredList, setfilteredList] = useState([])

  const navigate = useNavigate(); 
  const loadingOverlay =  (
    <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: ' rgba(128,128,128,0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000
      }}>
      <Lottie animationData={ballSpinner} loop={true} style={{ width: '50px', height: '50px' }} />
    </div>) 
    


  useEffect(() => {
    if (!match) return;

    const token = localStorage.getItem('idToken');
    if (!token) {
        console.error('No token found, redirecting to login.');
        navigate('/login');
        return;
    }
    setIsLoading(true);
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/fetch-teams/`, { match_id: match.match_id }, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
    .then(response => {
        console.log("Teams fetched:", response.data);
        setTeamList(response.data.filenames.map(file => ({
            ...file,
            approachNumber: file.filename.split('||')[0],
            firstBattingTeam: file.filename.split('||')[1],
            secondBattingTeam: file.filename.split('||')[2],
            approach: file.filename.split('||')[0] === '1' ? 'Match Score' : 'Player Position',
            fileTitle: file.filetitle
        })));
        console.log("setTeamList:", teamList)
        setTeamFilters(response.data.team_filters);
        setIsLoading(false);
    })
    .catch(error => {
        console.error('Error fetching teams:', error);
        if (error.response && error.response.status === 401) {
            navigate('/login');
        }
        setIsLoading(false);
    });
}, []);

// useEffect(()=>{
        const filteredList = teamList.filter(team => 
        (selectedApproach === 'All' || team.approach === selectedApproach) &&
        (selectedFilter === 'All' || team.firstBattingTeam === selectedFilter)
    );
    // setfilteredList(filteredList)   
// },[selectedFilter, selectedApproach])


const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
};

const handleApproachChange = (approach) => {
    setSelectedApproach(approach);
};

const displayTeamPlayers = (filePath, fileTitle, index) =>{
    const firstTeam = teamFilters[0]
    const secondTeam = teamFilters[1]
    navigate('/myteams-players', {state: [filePath, fileTitle, firstTeam, secondTeam , index]})
}

  return (
    <div className="show-teams-container">
        <MatchSelectedM  teamSaved={true} />
        {isLoading? loadingOverlay: <>
        {
         teamList.length === 0 ?
            (<div className="show-teams-noteams">
                <img src="/images/teams-icon.svg" alt="" />
                <p>You haven't built any team</p>
            </div>)
            : (<div className="show-teams-team-names">
                <p className="show-teams-filter-text">Filters</p>   
                <div className="show-teams-filter-container">
                    <div className="show-teams-filter-wrapper">
                        <span className="show-teams-filter-by">Approach</span>
                        <div className="show-teams-filter-choose">
                            <span className={selectedApproach === "All" && "show-teams-filter-selected"}
                                onClick={()=>handleApproachChange ('All')}>All</span>
                            <span className={selectedApproach === "Match Score" && "show-teams-filter-selected"}
                                onClick={()=>handleApproachChange ('Match Score')}>Match Score</span>
                        </div>
                    </div>
                    <div className="show-teams-filter-wrapper">
                        <span className="show-teams-filter-by">First Batting</span>
                        <div className="show-teams-filter-choose">
                            <span className={selectedFilter=== "All" && "show-teams-filter-selected"}
                                onClick={()=>handleFilterChange ('All')}>All</span>
                            <span className={selectedFilter=== teamFilters[0] && "show-teams-filter-selected"}
                                onClick={()=>handleFilterChange (teamFilters[0])}>{teamFilters[0]}</span>
                            <span className={selectedFilter=== teamFilters[1] && "show-teams-filter-selected"}
                                onClick={()=>handleFilterChange (teamFilters[1])}>{teamFilters[1]}</span>
                        </div>
                    </div>
                </div>
                {/* Teams Selected*/}
                <div className="teams-selected-container">
                    <div className="teams-selected-names">
                        <p className="show-teams-top-text">Teams</p> 
                    </div>
                    <div className="team-selected-list-wrapper">
                        {
                        filteredList.map((team, index) => (
                            <div key={index} 
                                className="team-selected-lists" 
                                onClick={() => displayTeamPlayers(team.filename, team.fileTitle, index)}
                            >
                                <p>{team.fileTitle}</p>
                                <span className="team-selected-bottom-text">Based on Approach - {team.approach}</span> 
                                <span className="team-selected-bottom-text">First Batting Team - {team.firstBattingTeam}</span>
                            </div>
                            )) 
                        }
                </div>
             </div>
           </div> 
        )
    }
     </>}
    </div>
  )
}

export default ShowMyTeams