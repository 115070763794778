import { useState } from 'react'
import RegisterPageComp from '../../components/Register/RegisterPageComp'
import LoginPage from '../Login/LoginPage'
import './registerpagedesktop.css'

const RegisterPageDesktop = () => {
  const [isSignUp, setIsSignUp] = useState(false)

  const handleLoginToggle = (toggle) =>{
    toggle==="login"? setIsSignUp(false) : setIsSignUp(true)
  }

  const handleRegistrationSuccess = () => {
    // Automatically switch to the login tab after successful registration
    handleLoginToggle("login");
  };

  return (
    <div className="register-d-container">
        <div className="register-d-left-wrapper">
            <img src="/images/desktop/logo-dark.svg" alt="" 
                  className="reg-d-logo" />
            <p>Build Your Fantasy Cricket Team</p>
        </div>
        <div className="register-d-right-wrapper">
            <div className="register-login-tabs-wrapper">
               
               <span className={isSignUp? "register-login-tabs": "register-login-tabs active-tab"} 
                      onClick={()=>handleLoginToggle("login")}>LOGIN</span>
               <span className={isSignUp? "register-login-tabs active-tab": "register-login-tabs"}
                      onClick={()=>handleLoginToggle("signup")}>SIGNUP</span>
            </div>
            {
              isSignUp ?
              <div className='sigunup-container'><RegisterPageComp viewPort="desktop" onRegistrationSuccess={handleRegistrationSuccess}/> </div>:
              <LoginPage viewPort="desktop" onSignUpClick={() => handleLoginToggle("signup")} />
            }
        </div>
    </div>
  )
}

export default RegisterPageDesktop