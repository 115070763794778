import React from 'react'
import MatchSelectedM from '../../../components/Home/Mobile/MatchSelectedM'
import ApproachCompM from '../../../components/Home/Mobile/ApproachCompM'
import { useLocation } from 'react-router-dom'
import FeedbackButton from '../../../components/Home/Common/FeebackButton';

const ApproachPage = () => {
  const location = useLocation()
  const playerList = location.state

  console.log("Approach component", playerList)

  return (
    <div>
        <MatchSelectedM />
        <ApproachCompM playerListSelected={playerList}/>
        {/* <FeedbackButton/> */}
    </div>
  )
}

export default ApproachPage