import React, { useEffect } from 'react';
import './howtouse.css';

const HowToUse = ({ isOpen, onClose }) => {
  useEffect(() => {
    // Apply the sliding up animation when the modal is set to open
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.querySelector('.how-to-use-modal-content').style.transform = 'translateY(0)';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  const handleClose = () => {
    // Start the slide down animation
    const modalContent = document.querySelector('.how-to-use-modal-content');
    modalContent.style.transform = 'translateY(100%)';
    // Wait for animation to finish before closing the modal
    setTimeout(() => {
      onClose();
    }, 300);
  };

  return (
    <div className="how-to-use-modal-overlay" onClick={handleClose}>
      <div className="how-to-use-modal-content" onClick={e => e.stopPropagation()}>
        <div className="how-to-use-modal-header">
          <button className="modal-close-icon" onClick={handleClose}>
            <img src="/images/close_icon_2.svg" alt="Close" />
          </button>
          <h4>How to Build Your Fantasy Team</h4>
          
        </div>
        <div className="header-divider"></div>
        <div className="how-to-use-modal-body">
          <h5>UPCOMING MATCHES:</h5>
          <p>Left Panel shows the list of all the upcoming matches for which insights are shown in Cricbotz.</p>
          <div className='image-placeholder'><img src="/images/howtouse/upcomingmatches.png" alt="Upcoming Matches" /></div>
          <h5>CHEATSHEET:</h5>
          <div className='image-placeholder'><img src="/images/howtouse/cheatsheet.png" alt="Cheat Sheet" /></div>
          <p>
            <strong>1. Match Scores Chart:</strong><br />
            Provides visual representation of Total scores of each innings for last 5 matches on current venue.
            Hover on bar graphs to see the scores for each match.
            
          </p>
          <p>
            <strong>2. Powerplay Performance:</strong><br />
            Provides visual representation of Runs scored and Wickets fell in Powerplay for last 5 matches on current venue.
            Hover on bar graphs to see the scores for each match.
            
          </p>
          <p>
            <strong>3. Death Over Performance:</strong><br />
            Provides visual representation of Runs scored and Wickets fell in Death Overs (16-20) for last 5 matches on current venue.
            Hover on bar graphs to see the scores for each match.
            
          </p>
          <p>
            <strong>4. Insights:</strong><br />
            Based on historic data of venue, players, Cricbotz AI Engine generates smart insights to help user quickly understand venue behavior at different phases of game.
            Engine also recommends key players who could perform well in upcoming match based on venue conditions and player form.
          </p>
          <p>
            <strong>5. Translation:</strong><br />
            Toggle between Eng and Hindi to translate Insights to desired language.
          </p>
          <div className='image-placeholder'><img src="/images/howtouse/translation.png" alt="Translation" /></div>

          <h5>PROBABLE PLAYING 11:</h5>
          <p>Recommendation Engine requires selection of playing 11 before user can click on Build Fantasy team. Based on last match played, players are pre-populated. User can select/deselect players from given squad. Click on '+' to select and '-' to deselect the player. Toggle between teams to complete selection for both Teams. In front of player names, icons are provided to represent player’s playing role. Make sure 11 players are selected from both the teams. In case of Substitute rule, 12 players could be selected.</p>
          <div className='image-placeholder'><img src="/images/howtouse/playing11.png" alt="Playing XI" /></div>  
          <h5>PROFILE INFORMATION:</h5>
          <p>Click on dots to access profile information. View active plan or access Term & Condition and Privacy policies. Use Logout option to sign out from the account. Use Feedback button '+' on bottom left corner to provide any feedback. Cricbotz team will work on feedbacks provided by the users.</p>
          <div className='image-placeholder'><img src="/images/howtouse/userprofile.png" alt="User Profile" /></div>  
          <h5>BUILD FANTASY TEAM:</h5>
          <p>Click on 'Build Fantasy Team' to access AI recommender for team suggestions. Based on match score:</p>
          <div className='image-placeholder'><img src="/images/howtouse/approachinput.png" alt="Match score based team generation" /></div>  
          <ul>
            <li>First batting team (Based on first batting team, second batting is pre-populated)</li>
            <li>Expected runs to be scored in first and second innings. Runs are bucketized and are available as drop-down options</li>
            <li>Expected Wickets to fall in first and second innings. Wickets are bucketized and are available as drop-down options</li>
            <li>Advanced input (Optional): User can provide more granular level data as input such as Runs and Wickets in Powerplay, Middle overs, and Death overs</li>
            <div className='image-placeholder'><img src="/images/howtouse/advancedinput.png" alt="Advanced Input" /></div>  
          </ul>
          <p>Use cheatsheet to better analyze ground conditions and provide inputs to AI engine.</p>

          <h5>How to use recommended Teams?</h5>
          <p>Based on provided input from user and considering historic data, AI engine will pick the best possible 11 and combination of Captain(C)/Vice-Captain(VC) for the match. Players with similar ranks are given as option for user to select. Similarly for C and VC user can pick any of the suggested combinations. For each suggested team, insights are provided on how match could progress in different phases of the game.</p>
          <div className='image-placeholder'><img src="/images/howtouse/recommendteams.png" alt="Recommended Teams" /></div>  
          <h5>SAVING TEAMS:</h5>
          <p>Out of multiple teams suggested, user can wish to select a single team by clicking on “Save Team” button. User can also click on “Generate Team Combinations” button to let the system decide the final 11. User needs to provide how many teams need to be generated.</p>
          <div className='image-placeholder'><img src="/images/howtouse/generatecombinations.png" alt="Generate Combinations" /></div>  
          <p>Saved Teams can be accessed from 'My Teams' Section from left panel.</p>
          <div className='image-placeholder'><img src="/images/howtouse/savedteams.png" alt="Saved Teams" /></div>  

        </div>
      </div>
    </div>
  );
}

export default HowToUse;
