import React from 'react';
import './upcommatchdetail.css';

const UpcomMatchDetail = ({ match, loadMatch, activeId }) => {
  const teamNames = match.match_name.split('|')[1].trim().split(' vs ').map(name => name.trim());


  return (
    <div className="match-detail-container-d" onClick={() => loadMatch(match.match_id)}>
        <div className={match.match_id === activeId ? "match-card-d set-match-bgcolor-d" : "match-card-d"}>
            <span className="match-name-d">{match.series_name}</span>
            <div className="match-teams-d">
                <span className="team-info-d">{teamNames[0]}</span>
                <span className="team-info-d vs-d">vs</span>
                <span className="team-info-d">{teamNames[1]}</span>
            </div>
            <span className="match-time-d">{new Date(match.dt).toLocaleString()}</span>
        </div>
    </div>
  );
};

export default UpcomMatchDetail;
