import React from 'react'
import LandingHeader from '../../components/Landing/LandingHeader'
import LandingContent from '../../components/Landing/LandingContent'
import LandingFooter from '../../components/Landing/LandingFooter'

import './landingpage.css'

const LandingPage = () => {
  return (
    <div className="landing-page">
        <LandingHeader />
        <LandingContent />
        <LandingFooter />
    </div>
  )
}

export default LandingPage