import {useState} from 'react';
import MatchInsightPanel from './MatchInsightPanel';
import RightPanel from './RightPanel';
// import { Box, Grid } from '@mui/material';
import './matchinsightwrapper.css'

function MatchInsightWrapper({ onSelectionUpdate, teamSelections, matchInsights, activeMatch, matchSquad, toggleApproachInput, toggleApproach1Teams, insightIsLoading, squadIsLoading }) {
  console.log("active match from MatchInsightWrapper = ",activeMatch)
    return (
        <div className="match-insight-wrapper">
                <div className="match-insight-content-left">
                    <MatchInsightPanel      
                        teamSelections={teamSelections}                   
                        matchInsights={matchInsights}
                        activeMatch={activeMatch}
                        matchSquad={matchSquad}
                        toggleApproachInput={toggleApproachInput}
                        toggleApproach1Teams={toggleApproach1Teams}
                        insightIsLoading={insightIsLoading}
                    />
                </div>
                {activeMatch && (
                    <div className="match-insight-content-right">
                        <RightPanel
                            onSelectionUpdate={onSelectionUpdate}
                            teamOne={`${activeMatch.team1_id} - ${activeMatch.match_name.split('||')[1].trim().split(' vs ')[0].trim()}`}
                            teamTwo={`${activeMatch.team2_id} - ${activeMatch.match_name.split('||')[1].trim().split(' vs ')[1].trim()}`}
                            team1_full_name = {activeMatch.team1_full_name}
                            team2_full_name = {activeMatch.team2_full_name}
                            team1_name = {activeMatch.team1_name}
                            team2_name = {activeMatch.team2_name}
                            team1_id = {activeMatch.team1_id}
                            team2_id = {activeMatch.team2_id}
                            matchId={activeMatch.match_id}
                            matchSquad={matchSquad}
                            squadIsLoading={squadIsLoading}
                        />
                </div>
                )}
           
        </div>
    );
}

export default MatchInsightWrapper;
