import React, { useState } from 'react';
import { Box, Grid, Typography, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './matchinsightpanel.css';
// import RightPanel from './RightPanel';
import { Button } from '@mui/material';
import ballSpinner from '../../lotties/ball-loading.json'
import Lottie from "lottie-react";
// import RiseLoader from 'react-spinners/RiseLoader';
// import ValidationModal from './ValidationModal';
import ErrorModal from '../Home/Common/ErrorModal';
import useAnalyticsEvent from '../../components/Home/Common/UseAnalyticsEvent';
import FeedbackButton from '../../components/Home/Common/FeebackButton';
import ChatBot from '../../components/Home/Common/ChatBot';

function MatchInsightPanel({ teamSelections, matchInsights, activeMatch, toggleApproachInput, insightIsLoading }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const [language, setLanguage] = useState("English");
  const [isChatOpen, setIsChatOpen] = useState(false);

  const handleChatBotzClick = () => {
      setIsChatOpen(!isChatOpen);
  };

  const sortDataByDate = (data) => {
    return data.sort((a, b) => new Date(b.match_start_date) - new Date(a.match_start_date));
  };
  const trackAnalyticsEvent = useAnalyticsEvent();

  // Prepare sorted data for the charts
  const sortedPhaseWiseData = matchInsights?.phase_wise_data ? sortDataByDate([...matchInsights.phase_wise_data]) : [];
  const venueChartData = matchInsights?.venue_data ? sortDataByDate([...matchInsights.venue_data]).map(venue => ({
    name: `${venue.team1_name} vs ${venue.team2_name}`,
    First_Batting_Score: Number(venue.first_batting_score),
    First_Batting_Wickets: venue.first_batting_wicket_lost,
    Second_Batting_Score: Number(venue.second_batting_score),
    Second_Batting_Wickets: venue.second_batting_wicket_lost,
  })) : [];
  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: ballSpinner,
  //   rendererSettings: {
  //     preserveAspectRatio: "xMidYMid slice"
  //   }
  // };

  // if (!matchInsights || !Array.isArray(matchInsights.phase_wise_data)) {
  //     // return <div>Loading or no data...</div>;
  //     return <div className="spinner"></div>;
  // }

  const validateTeamSelections = (teamSelections) => {
      const errors = [];
      Object.entries(teamSelections).forEach(([team, players]) => {
          if (players.length < 11 || players.length > 12) {
              errors.push(`Team ${team.split(' - ')[1]} must have between 11 and 12 players selected. Currently selected: ${players.length}`);
          }
      });
      return errors.length > 0 ? errors.join('\n') : null;
  };
  const handleBuildTeamClick = () => {
    const validationErrors = validateTeamSelections(teamSelections);
    if (validationErrors) {
        setValidationMessage(validationErrors);
        setIsModalOpen(true);
    } else {
        toggleApproachInput();
        trackAnalyticsEvent("Team Build", "Approach Input",1);
    }
};



if (insightIsLoading || !matchInsights) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
      <Lottie animationData={ballSpinner} loop={true} style={{ width: '50px', height: '50px' }} />
    </div>
  ); 
}


  const loadingOverlay = (
    <div style={{
      position: 'fixed', // Overlay covers the entire screen
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000 // High z-index to cover everything else
    }}>
      {/* <RiseLoader color="#013220" size={15} /> */}
      <Lottie animationData={ballSpinner} loop={true} style={{ width: '50px', height: '50px' }} />
    </div>
  );

  const chartData = matchInsights && matchInsights.venue_data ? matchInsights.venue_data.map(venue => ({
    name: `${venue.team1_name} vs ${venue.team2_name}`,
    First_Batting_Score: Number(venue.first_batting_score),
    First_Batting_Wickets: venue.first_batting_wicket_lost,
    Second_Batting_Score: Number(venue.second_batting_score),
    Second_Batting_Wickets: venue.second_batting_wicket_lost,
})) : [];

/*
const toggleLanguage = () => {
    setLanguage(language === 'English' ? 'Hindi' : 'English');
  };
*/

  const groupedData = matchInsights && matchInsights.phase_wise_data ? matchInsights.phase_wise_data.reduce((acc, data) => {
    const key = `${data.match_start_date}-${data.team1_name} vs ${data.team2_name}`;
    if (!acc[key]) {
      acc[key] = {
        name: `${data.team1_name} vs ${data.team2_name}`,
        date: data.match_start_date,
        powerplayRuns: 0,
        deathRuns: 0,
        powerplayWickets: 0,
        deathWickets: 0,
      };
    }
    acc[key].powerplayRuns += Number(data.powerplay_runs_scored || 0); // Ensuring conversion to number and handling undefined
    acc[key].deathRuns += Number(data.death_runs_scored || 0); // Same as above
    acc[key].powerplayWickets += Number(data.powerplay_wickets_taken || 0); // Convert and ensure values are numbers
    acc[key].deathWickets += Number(data.death_wickets_taken || 0); // Convert and ensure values are numbers
    return acc;
  }, {}) : {}; // If data is null, return an empty object

  const sortedData = Object.values(groupedData).sort((a, b) => new Date(b.date) - new Date(a.date));

  
    // // Grouping data by match and innings for separate charts
    // const powerplayData = sortedData.map(data => ({
    //   name: `${data.team1_name} vs ${data.team2_name} - ${data.batting_innings}`,
    //   Runs: data.powerplay_runs_scored,
    //   Wickets: data.powerplay_wickets_taken,
    // }));
  
    // const deathOverData = sortedData.map(data => ({
    //   name: `${data.team1_name} vs ${data.team2_name} - ${data.batting_innings}`,
    //   Runs: data.death_runs_scored,
    //   Wickets: data.death_wickets_taken,
    // }));

  function CustomTooltip({ active, payload }) {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${payload[0].payload.name}`}</p>  
          <p className="intro">{`First Innings Score: ${payload[0].payload.First_Batting_Score} / ${payload[0].payload.First_Batting_Wickets}` }</p>            
          <p className="intro">{`Second Innings Score: ${payload[1].payload.Second_Batting_Score} / ${parseInt(payload[1].payload.Second_Batting_Wickets, 10)}`}</p>
          
        </div>
      );
    }
  
    return null;
  }

// Initialize phaseWiseData if not already defined
let phaseWiseData = [];

if (matchInsights && matchInsights.phase_wise_data) {
  matchInsights.phase_wise_data.forEach(data => {
    const matchInningKey = `${data.team1_name} vs ${data.team2_name} - ${data.batting_innings} Inning`;
    phaseWiseData.push({
      match: matchInningKey,
      powerplayRuns: Number(data.powerplay_runs_scored || 0),
      deathRuns: Number(data.death_runs_scored || 0),
      powerplayWickets: Number(data.powerplay_wickets_taken || 0),
      deathWickets: Number(data.death_wickets_taken || 0),
    });
  });

  // Sort phaseWiseData if it is not empty
  phaseWiseData.sort((a, b) => a.match.localeCompare(b.match));
} else {
  console.log("No phase wise data available.");
}


  function CustomTooltipPowerplay({ active, payload }) {
    if (active && payload && payload.length) {
      // Directly accessing the original data object for the hovered bar
      const data = payload[0].payload;

      return (
        <div className="custom-tooltip">
          <p className="label">{data.team1_name} vs {data.team2_name}</p>
          <p className="intro">{`First Inning Powerplay Score: ${data.powerplay_runs_scored_first} / ${data.powerplay_wickets_taken_first}`}</p>
          <p className="desc">{`Second Inning Powerplay Score: ${data.powerplay_runs_scored_second} / ${data.powerplay_wickets_taken_second}`}</p>
        </div>
      );
    }
    return null;
  }

  function CustomTooltipDeathOver({ active, payload }) {
    if (active && payload && payload.length) {
      // Directly accessing the original data object for the hovered bar
      const data = payload[0].payload;

      return (
        <div className="custom-tooltip">
          <p className="label">{data.team1_name} vs {data.team2_name}</p>
          <p className="intro">{`First Inning Death Score: ${data.death_runs_scored_first} / ${data.death_wickets_taken_first}`}</p>
          <p className="desc">{`Second Inning Death Score: ${data.death_runs_scored_second} / ${data.death_wickets_taken_second}`}</p>
        </div>
      );
    }
    return null;
  }

  const formatTextForHtml = text => text.replace(/\n/g, '');
  // function InsightsComponent({ matchInsights, language }) {
    let formattedInsights = 'No insights available.';
    if (matchInsights && matchInsights.ai_insights_data && matchInsights.ai_insights_data[language]) {
      formattedInsights = matchInsights.ai_insights_data[language];
    }
    
  const htmlContent = formattedInsights.replace(/\n\n/g, '<br>');
  // const htmlContent = htmlContent1.replace(/\n/g, '<br>');
// <Box className="chart-box">

const handleLangChange = (lang) =>{
  setLanguage(lang)
}
const textClass = language === "Hindi" ? "hindi-text" : "english-text";
  return (
   <>
   
    <div className="insights-top-d">
                        <span className="insights-text-d">CheatSheet</span>
                        <div className="insights-lang-block-d">
                            <span className={language==="English" ?"insights-lang-eng-d insight-lang-bg-d": "insights-lang-eng"} onClick={()=>handleLangChange('English')}>Eng</span>
                            <span className={language==="Hindi" ?"insights-lang-hindi-d insight-lang-bg-d":"insights-lang-hindi"} onClick={()=>handleLangChange('Hindi')}>हिंदी</span>
                        </div>
    </div>
    <div className="insight-container-d">     
        {/* <ValidationModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            message={validationMessage}
        /> */}
        <ChatBot activeMatch={activeMatch} isOpen={isChatOpen} toggleChat={handleChatBotzClick} />
        {isModalOpen && (
            <ErrorModal
                title='Player Selection'
                message={validationMessage}
                close={() => setIsModalOpen(false)}
            />
        )}
        
      {insightIsLoading && loadingOverlay}        
        <div className="chart-box">
              <div className="insight-chart-container-d">  
                <p className="chartTitle">Match Scores Chart</p>           
                <ResponsiveContainer width="90%" height={300}>
                  {/* <BarChart data={chartData} margin={{ top: 10, right: 10, left: 0, bottom: 5 }}> */}
                  <BarChart data={venueChartData} 
                        margin={{ top: 10, right: 10, left: 0, bottom: 0 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <YAxis />
                    <Tooltip content={<CustomTooltip />} />
                    {/* <Legend/> */}
                    <Bar dataKey="First_Batting_Score" fill="#00876C" />
                    <Bar dataKey="Second_Batting_Score" fill="#7EAD9D" />
                  </BarChart>
                </ResponsiveContainer>                
              </div>
              
              <div className="insight-chart-container-d">  
                <p className="chartTitle">Powerplay Performance</p>
                
                <ResponsiveContainer width="90%" height={300}>
                  {/* <BarChart data={matchInsights.phase_wise_data} margin={{ top: 10, right: 0, left: 0, bottom: 5 }}> */}
                  <BarChart 
                      data={sortedPhaseWiseData} 
                      margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip content={<CustomTooltipPowerplay />} />
                    {/* <Legend /> */}
                    <Bar dataKey="powerplay_runs_scored_first" fill="#00876C" name="First Innings Runs" />
                    <Bar dataKey="powerplay_runs_scored_second" fill="#7EAD9D" name="Second Innings Runs" />
                  </BarChart>
                </ResponsiveContainer>
              </div>

              <div className="insight-chart-container-d">  
                <p className="chartTitle">Death Over Performance</p>
                
                <ResponsiveContainer width="90%" height={300}>
                  {/* <BarChart data={matchInsights.phase_wise_data} margin={{ top: 10, right: 0, left: 0, bottom: 5 }}> */}
                  <BarChart data={sortedPhaseWiseData} margin={{ top: 10, right: 0, left: 0, bottom: 0 }}>
                  
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip content={<CustomTooltipDeathOver />} />
                    {/* <Legend /> */}
                    <Bar dataKey="death_runs_scored_first" fill="#00876C" name="First Innings Runs" />
                    <Bar dataKey="death_runs_scored_second" fill="#7EAD9D" name="Second Innings Runs" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
        </div>   

                     
          <div className="insightWrapper">
          <div className={textClass}>
            <div className = "insightText" dangerouslySetInnerHTML={{ __html: htmlContent }} />
            </div>
          </div>
       
          
        <div className="build-team-button-container">
          <button className="buildTeamButton" onClick={handleChatBotzClick}>
            Chat with Botz
            <img src="/images/common/spark_icon_white.svg" alt="Spark Icon" style={{ marginRight: '8px' }} />
          </button> 
          <button className="buildTeamButton" onClick={handleBuildTeamClick}>                          
           Build Fantasy Team
          </button> 
          
        </div>
        
          <FeedbackButton />
          

      </div>
    </> 
  );
}

export default MatchInsightPanel;
