import React from 'react'
import MatchSelectedM from '../../../components/Home/Mobile/MatchSelectedM'
import ApproachOneTeamsM from '../../../components/Home/Mobile/ApproachOneTeamsM'
import FeedbackButton from '../../../components/Home/Common/FeebackButton';

const TeamRecommendPage = () => {
  return (
    <div>
      <MatchSelectedM />
      <ApproachOneTeamsM />
      {/* <FeedbackButton/> */}
    </div>
  )
}

export default TeamRecommendPage