import React, { useRef, useState, useEffect } from 'react';
import './registerpagecomp.css';
import Lottie from "lottie-react";
import ballSpinner from '../../lotties/ball-loading.json';
import { useNavigate } from 'react-router-dom';
import ErrorModal from '../Home/Common/ErrorModal';
import SuccessModal from '../Home/Common/SuccessModal';

const RegisterPageComp = ({ viewPort, onRegistrationSuccess  }) => {
  const firstNameRef = useRef(null);
  const mobileRef = useRef(null);
  const emailRef = useRef(null);
  const navigate = useNavigate();
  const [errorModal, setErrorModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [modalHeader, setModalHeader] = useState('Registration Issue'); // Default header for errors
  const [registerLoading, setRegisterLoading] = useState(false);

  useEffect(()=>{
    firstNameRef.current.focus()
  },[])

  const loadingOverlay = registerLoading ? (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000
    }}>
      <Lottie animationData={ballSpinner} loop={true} style={{ width: '50px', height: '50px' }} />
    </div>
) : null;

  const handleRegisterClick = async () => {
    // Validate email format
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(emailRef.current.value)) {
      setErrorMessage('Invalid email format');
      setModalHeader('Registration Issue'); // Set appropriate modal header for errors
      setErrorModal(true);
      return;
    }

    // Validate mobile number
    if (mobileRef.current.value.length !== 10 || isNaN(mobileRef.current.value)) {
      setErrorMessage('Mobile number must be a 10-digit number');
      setModalHeader('Registration Issue'); // Set appropriate modal header for errors
      setErrorModal(true);
      return;
    }

    try {
      setRegisterLoading(true)
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/register-user/`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: firstNameRef.current.value,
          email: emailRef.current.value,
          phone_number: `+91${mobileRef.current.value}`,
        }),
      });

      if (response.ok) {
        setRegisterLoading(false)
        console.log('Registration successful!');
        setErrorMessage('Registration successful. You can now proceed to login.');
        setModalHeader('Success'); // Update the header for successful registration
        setSuccessModal(true);
        setTimeout(() => {
          onRegistrationSuccess(); // This will switch to the login tab after showing the modal
          setSuccessModal(false); // Close the modal automatically
        }, 3000); // wait for 3 seconds to show the message before switching
      } else if (response.status === 400) {
        setRegisterLoading(false)
        const data = await response.json();
        if (data.detail === 'User already exists. Please login to continue.') {
          setErrorMessage(data.detail);          
        } else {
          const detailedErrorMessage = data.detail.match(/: (.+)$/)[1]; // Extracting the actual error message after the colon
          setErrorMessage(detailedErrorMessage);
        }
        
        setModalHeader('Registration Issue'); // Keep the default header for errors
        setErrorModal(true);
      } else {
        console.error('Registration failed.');
      }
    } catch (error) {
      console.error('An error occurred during registration:', error);
    }
  };

  const closeModal = () => {
    setErrorModal(false);
    setSuccessModal(false);
  };

  return (
    <div className='sigunup-container'>
      {loadingOverlay}
       <p className="register-toptext">Create an Account</p>
       <p className="register-bottomtext">Start your 1 week free trial</p>
       <form className="register-form">
         <div className="register-inp-blocks">
           <label htmlFor='fname' className="register-label-text">First Name<span className="reg-asterisk">*</span></label>
           <input className="register-inp" ref={firstNameRef} type="text" id="fname" aria-label="First Name"/>
         </div>
         <div className="register-inp-blocks">
           <label htmlFor='mobile' className="register-label-text">Mobile Number<span className="reg-asterisk">*</span></label>
           <div className="login-input-group">
             <span className="input-group-text">+91</span>
             <input className="register-inp-mob" ref={mobileRef} type="text" id="mobile" aria-label="Mobile Number"/>
           </div>
         </div>
         <div className="register-inp-blocks">
           <label htmlFor='email' className="register-label-text">Email <span className="reg-asterisk">*</span></label>
           <input className="register-inp" ref={emailRef} type="email" id="email" aria-label="Email" required/>
         </div>
         
         <button type="button" className="register-btn" onClick={handleRegisterClick}>Register</button>
       </form>
       {viewPort === "mobile" && (
         <div className="register-login">
           <p>Have an account? <span className="register-login-text" onClick={()=>navigate('/login')}> Login </span></p>
         </div>
       )}
       <div className="register-terms-text">
         <span>By clicking Register, you agree to Cricbotz's <a href="/terms-and-conditions" className="register-links">Terms & Conditions</a> and <a href="/privacy-policy" className="register-links">Privacy Policy.</a></span>
       </div>
       
       {errorModal && ( <ErrorModal title={modalHeader} message={errorMessage} close={closeModal}/>)}
       {successModal && ( <SuccessModal title={modalHeader} message={errorMessage} close={closeModal}/>)}
    </div>
  );
}

export default RegisterPageComp;