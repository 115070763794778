import React, { useState } from 'react';
import './termsandconditions.css';

function TermsAndConditions() {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };
    return (
        
        
        <div className="root2">
            <header>
                <a href="https://cricbotz.com">
                    <img className="logo_static" src="images/logo-name.svg" alt="Cricbotz Logo" style={{ height: '50px' }} />
                </a>
                <div className={`nav-links-2 ${menuOpen ? 'open' : ''}`}>
                    <a href="/how_it_works">How It works</a>
                    <a href="/#a-pricing">Pricing</a>
                    <a href="https://blogs.cricbotz.com">Blog</a>
                    <a href="/login" className="login-btn-2">Login</a>
                </div>
                <div className="hamburger" onClick={toggleMenu}>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div>
            </header>
            
            <div className="content">
                <h1>TERMS AND CONDITIONS</h1>
                <p>
                Last updated June, 2024
                </p>
                <div className="section">
                    <h2>AGREEMENT TO OUR LEGAL TERMS:</h2>
                    <p>We are <strong>Cricbotz</strong>, a product of Crunchsight Technologies, a company registered in India at B-503, Ahad Opus, Sarjapura Road, Bangalore - 560035.  You can contact us by email at support@cricbotz.com.</p>
                    <p>These Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf of an entity and Cricbotz, concerning your access to and use of the Services. You agree that by accessing the Services, You have read, understood, and agreed to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY. </p>
                    <p>Supplemental terms and conditions or documents that may be posted on the Services from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Legal Terms at any time and for any reason. We will notify you about changes by updating the ‘Last updated’ date of these Legal Term, and you waive any right to receive specific note of each such change. </p>
                    <p><strong>The services are intended for users who are at least 18 years of old. Persons under the age of 18 are not permitted to use or register for the services.</strong></p>
                </div>

                <div className="section">
                    <h2>OUR SERVICES:</h2>
                    <p>At Cricbotz, we provide a cutting-edge platform designed to help cricket enthusiasts, fantasy players select the best possible playing eleven for upcoming cricket matches. Our service offers a comprehensive suite of features to enhance your team selection process like Data-Driven Player analysis, AI driven engine to pick best players, customisable parameters to simulate user driven scenarios. 
                        <strong>In current version we would be only providing features for T20 matches for Mens and T20 Leagues.</strong></p>
                    <p>The information provided when using the Services is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country.
                        <strong>We do not engage in any gaming activities or fantasy sports. Our platform is entirely dedicated to offering data-driven analytics and suggestions, providing valuable insights to our users.</strong>
                    </p>
                </div>

                <div className="section">
                    <h2>OUR INTELLECTUAL PROPERTY:</h2>
                    <p>We are the owner of all intellectual property rights in our Services, including all source code, database, functionality, website design and logos in the Services (collectively, the ‘Content’ and the ‘Marks’). 
                        <strong>Services by any means other than through the interface that is provided by Cricbotz for use in accessing the Services.</strong>
                    </p>
                </div>


                <div className="section">
                    <h2>USE OF OUR SERVICES:</h2>
                    <p>Subject to your compliance with these Legal Terms, we grant you a non-exclusive, non-transferable, revokable license to :
                        •	Access the services
                        •	Download or print a copy of any portion of the content to which you have properly gained access .
                        Solely for your personal, non-commercial use.
                        Except as set out in this section or elsewhere in our Legal Terms, no part of the Services and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.
                        </p>
                    <p>Any breach of these intellectual property rights will constitute a material breach of our Legal Terms and your right to use our Services will terminate immediately.</p>
                </div>

                <div className="section">
                    <h2>USE OF OUR SERVICES:</h2>
                    <p>By using the Services, you represent and warrant that: (1) all registration information you submit will be true, accurate, current, and complete. (2) you have the legal capacity and you agree to comply with these Legal Terms. (3) you are not a minor in jurisdiction in which you reside. (4) you will not access the Services through automated or non-human means, whether through a bot, script or otherwise. (5) you will not use the Services for any illegal or unauthorized purpose. (6) your use of the Services will not violate any applicable law or regulation.</p>
                </div>

                <div className="section">
                    <h2>PURCHASES AND PAYMENTS:</h2>
                    <p>We accept the following forms of payments:
                        •	CREDIT/DEBIT CARD
                        •	UPI
                        You agree to provide current, complete, and accurate purchase and account information for all purchases made via the Services. 
                        <strong>We may change prices at any time. All payments shall be in INR. We reserve the right to refuse any order placed through the Services.</strong>
                    </p>
                </div>

                <div className="section">
                    <h2>SUBCRIPTIONS:</h2>
                    <p>Subscription is not auto renewed. Once plan is exhausted, user will be prompted to renew the plan from platform.</p>
                    <p>We offer a 7-day free trial to new users who register with the Services. The account will not be charged and the subscription will be suspended until upgraded to a paid version at the end of the trial.</p>
                    <p><strong>All purchases are non-refundable. You can cancel your subscription at any time by contacting us using the contact information provided. Your cancellation will take effect at the end of the current paid term. If you have any questions or are unsatisfied with our services, please email us at supprt@cricbotz.com.</strong></p>
                    <p>We may time to time, make changes to subscription fee and will communicate any price changes to you in accordance with applicable law.</p>
                </div>

                <div className="section">
                    <h2>PROHIBITED ACTIVITES:</h2>
                    <p>You may not access or use the Services for any purpose other than that for which we make the Services available. Ther Services may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.</p>
                    <p>As a user of the Service, you agree not to:</p>
                    <p>•	Systematically retrieve data or other content from the Services to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.</p>
                    <p>•	Use the Services in a manner inconsistent with any applicable laws or regulations.</p>
                    <p>•	Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.</p>
                    <p>•	Attempt to bypass any measure of the Services designed to prevent or restrict access to the Services, or any portion of the Services.</p>
                    <p>•	Email, or transmit any content that is illegal, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, libelous, invasive of privacy, hateful, or objectionable based on race, ethnicity, or other factors.</p>
                </div>

                <div className="section">
                    <h2>CONTRIBUTION LICENSE:</h2>
                    <p>You agree that we may access, store, process, and use any information and personal data that you provide and your choices (including settings). By submitting suggestions or other feedback regarding the Services, you agree that we can use and share such feedback for any purpose without compensation to you.</p>
                    <p>We do not assert any ownership over your Contributions. You retain full ownership of all of your Contributions and any intellectual property rights or other proprietary rights associated with your Contributions. We are not liable for any statements or representations in your Contributions provided by you in any area on the Services. You are solely responsible for your Contributions to the Services and you expressly agree to exonerate us from any and all responsibility and to refrain from any legal action against us regarding your Contributions.</p>
                </div>

                <div className="section">
                    <h2>SERVICES MANAGEMENT:</h2>
                    <p>We reserve the right, but not the obligation, to: (1) monitor the Services for violations of these Legal Terms; (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Legal Terms, including without limitation, reporting such user to law enforcement authorities; (3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or any portion thereof; (4) in our sole discretion and without limitation, notice, or liability, to remove from the Services or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems; and (5) otherwise manage the Services in a manner designed to protect our rights and property and to facilitate the proper functioning of the Services.</p>
                </div>

                <div className="section">
                    <h2>PRIVACY POLICY:</h2>
                    <p>We care about data privacy and security. By using the Services, you agree to be bound by our Privacy Policy posted on the Services, which is incorporated into these Legal Terms. Please be advised the Services are hosted in India. If you access the Services from any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in India, then through your continued use of the Services, you are transferring your data to India, and you expressly consent to have your data transferred to and processed in India.</p>
                </div>

                <div className="section">
                    <h2>TERM AND TERMINATION</h2>
                    <p>These Legal Terms shall remain in full force and effect while you use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.</p>
                    <p>If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.</p>
                </div>

                <div className="section">
                    <h2>MODIFICATIONS AND INTERRUPTIONS</h2>
                    <p>We reserve the right to change, modify, or remove the contents of the Services at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Services. We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Services.</p>
                    <p><strong>We cannot guarantee the Services will be available at all times.</strong> We may experience hardware, software, or other problems or need to perform maintenance related to the Services, resulting in interruptions, delays, or errors. We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Services at any time or for any reason without notice to you. <strong>You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the Services during any downtime or discontinuance of the Services.</strong> Nothing in these Legal Terms will be construed to obligate us to maintain and support the Services or to supply any corrections, updates, or releases in connection therewith.</p>
                    <p>If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.</p>
                </div>

                <div className="section">
                    <h2>CORRECTIONS</h2>
                    <p>There may be information on the Services that contains typographical errors, inaccuracies, or omissions, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Services at any time, without prior notice.</p>
                </div>

                <div className="section">
                    <h2>DISCLAIMER</h2>
                    <p>THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY UNAUTHORISED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGEMENT AND EXERCISE CAUTION WHERE APPROPRIATE.</p>
                </div>

                <div className="section">
                    <h2>LIMITATIONS OF LIABILITY</h2>
                    <p>IN NO EVENT WILL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US DURING THE SIX (6) MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING. </p>
                </div>

                <div className="section">
                    <h2>USER DATA</h2>
                    <p>We will maintain certain data that you transmit to the Services for the purpose of managing the performance of the Services, as well as data relating to your use of the Services. Although we perform regular routine backups of data, you are solely responsible for all data that you transmit or that relates to any activity you have undertaken using the Services. You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby waive any right of action against us arising from any such loss or corruption of such data.</p>
                </div>

                <div className="section">
                    <h2>CONTACT US</h2>
                    <p>In order to resolve a complaint regarding the Services or to receive further information regarding use of the Services, please contact us at:</p>
                    <p>CRICBOTZ</p>
                    <p>support@cricbotz.com</p>
                </div>

            </div>
            <div className="land-footer-container">
                <div className="land-logo-footer">
                    <img src="/images/common/logo-footer.svg" alt="" className="land-logo-name-footer"/>
                </div>
                <div className="land-footer-links">
                    <a className="land-link-list" href="#">support@cricbotz.com</a>
                    <a className="land-link-list" href="/terms-and-conditions">Terms & Conditions</a>
                    <a className="land-link-list" href="/privacy-policy">Privacy Policy</a>
                    <a className="land-link-list" href="/billing-subscription">Billing And Subscription</a>
                </div>
            </div>
        </div>
    
    );
}

export default TermsAndConditions;