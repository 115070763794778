/* global dataLayer */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './context/authContext';
import { ViewportProvider } from './context/viewportContext';
import App from './App';
// import ReactGA from 'react-ga4';

// const gaId = process.env.REACT_APP_GA_ID;
// ReactGA.initialize(gaId};

// GoogleAnalytics.initialize(gaId);

// // Set the document title
// document.title = "Cricbotz";

// // Function to load Google Analytics
// const loadGoogleAnalytics = () => {
//   const gaId = process.env.REACT_APP_GA_ID; // Retrieve GA ID from environment
//   if (gaId) {
//     const script = document.createElement('script');
//     script.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;
//     script.async = true;
//     document.head.appendChild(script);

//     script.onload = () => {
//       window.dataLayer = window.dataLayer || [];
//       function gtag() { dataLayer.push(arguments); }
//       gtag('js', new Date());
//       gtag('config', gaId);
//       console.log("Google Analytics loaded and initialized with ID:", gaId);
//     };
//   } else {
//     console.error("Google Analytics ID is not set in the environment variables.");
//   }
// };


// Call the function to load Google Analytics
// loadGoogleAnalytics();

// Create a root container
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <ViewportProvider>
      <BrowserRouter>
        <AuthProvider>
          <App />
        </AuthProvider>
      </BrowserRouter>
    </ViewportProvider>
  // </React.StrictMode>
);
