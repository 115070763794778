import React, { useState } from 'react';
import './billingsubscription.css';


function BillingSubscription() {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };
    return (
        
        
        <div className="root2">
            <header>
                <a href="https://cricbotz.com">
                    <img className="logo_static" src="images/logo-name.svg" alt="Cricbotz Logo" style={{ height: '50px' }} />
                </a>
                <div className={`nav-links-2 ${menuOpen ? 'open' : ''}`}>
                    <a href="/how_it_works">How It works</a>
                    <a href="/#a-pricing">Pricing</a>
                    <a href="https://blogs.cricbotz.com">Blog</a>
                    <a href="/login" className="login-btn-2">Login</a>
                </div>
                <div className="hamburger" onClick={toggleMenu}>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div>
            </header>
            <div className="content">
                <h1>Welcome to Cricbotz.com</h1>
                <p>
                    Explore comprehensive cricket analysis with a focus on venue insights, player performance, and predictive team recommendations. Our platform is tailored for enthusiasts and professionals alike, aiming to enhance their understanding and strategy in the game of cricket.
                </p>
                <div class="section">
                    <h2>Subscription Plans</h2>
                    <p><strong>Monthly and Annual Plans:</strong> Choose from flexible subscription options to suit your needs. Monthly plans offer short-term access while annual plans provide a cost-effective solution for year-round coverage. Payments are processed at the start of the subscription period.</p>
                    <p><strong>Trial Period:</strong> Experience our services with a 1-week free trial, available exclusively to new customers. This trial includes full access to all our analytical tools and resources.</p>
                    <p><strong>Cancellation:</strong> You may cancel your subscription at any time. Access to the services continues until the end of the current billing cycle, with no refunds for unused portions of the period.</p>
                </div>

                <div class="section">
                    <h2>Refund Policy</h2>
                    <p>All payments made to Cricbotz are final and non-refundable. In exceptional cases, refunds may be issued at our discretion, typically in cases of service disruptions or failures to deliver promised content.</p>
                </div>

                <div class="section">
                    <h2>Reliance on Information Posted</h2>
                    <p>Information available through our services is intended for general informational purposes only. While we strive for accuracy, we cannot guarantee the completeness or currentness of the information provided. Reliance on any information from this site is solely at your own risk.</p>
                </div>

                <div class="section">
                    <h2>Disclaimer of Warranties</h2>
                    <p>Our services are provided on an "as is" and "as available" basis, without any warranties of any kind, either express or implied. We disclaim all warranties, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>
                </div>

                <div class="section">
                    <h2>Limitation on Liability</h2>
                    <p>Cricbotz and its affiliates will not be liable for any direct, indirect, incidental, special, consequential, or punitive damages resulting from the use of, or inability to use, our services, even if advised of the possibility of such damages.</p>
                </div>

                <div class="section">
                    <h2>Indemnification</h2>
                    <p>By using our services, you agree to defend, indemnify, and hold harmless Cricbotz, its affiliates, and their respective officers, directors, employees, and agents from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your use of the services or your violation of these terms.</p>
                </div>

                <div class="section">
                    <h2>Severability</h2>
                    <p>If any provision of these terms is held to be invalid, illegal, or unenforceable for any reason, such provision shall be modified to reflect the parties' intention or eliminated to the minimum extent such that the remaining provisions of the terms will continue in full force and effect.</p>
                </div>

                <div class="section">
                    <h2>Entire Agreement</h2>
                    <p>This document and any policies or operating rules posted by us on this site or in respect to the service constitute the entire agreement and understanding between you and Cricbotz. It supersedes all prior or contemporaneous communications and proposals, whether electronic, oral, or written, between the user and us.</p>
                </div>

                <div class="section">
                    <h2>Contact Information</h2>
                    <p>For any questions, concerns, or issues, please feel free to contact us at <a href="mailto:support@cricbotz.com">service@cricbotz.com</a>. We are committed to providing excellent customer support and typically respond within 24 hours.</p>
                </div>
            </div>
            <div className="land-footer-container">
                <div className="land-logo-footer">
                    <img src="/images/common/logo-footer.svg" alt="" className="land-logo-name-footer"/>
                </div>
                <div className="land-footer-links">
                    <a className="land-link-list" href="#">support@cricbotz.com</a>
                    <a className="land-link-list" href="/terms-and-conditions">Terms & Conditions</a>
                    <a className="land-link-list" href="/privacy-policy">Privacy Policy</a>
                    <a className="land-link-list" href="/billing-subscription">Billing And Subscription</a>
                </div>
                </div>
        </div>
    );
}

export default BillingSubscription;
