import React from 'react'
import {useState} from 'react'
import './matchselectedM.css'
import { parseISO, addMinutes, format } from 'date-fns';
import { useNavigate } from "react-router-dom";
import HowToUse from '../Common/HowToUse';
import ChatBot from '../Common/ChatBot';

const MatchSelectedM = () => {
    const match = JSON.parse(localStorage.getItem('matchSelected'))
    const teamOne = match.match_name.split('||')[1].trim().split(' vs ')[0].trim();
    const teamTwo = match.match_name.split('||')[1].trim().split(' vs ')[1].trim();
    const [showInfoModal, setShowInfoModal] = useState(false)
    const [showChatModal, setShowChatModal] = useState(false)
  
  
    const handleInfoButton = () => {
        setShowInfoModal(true)
    }

    const handleInfoButtonClose = () => {
        setShowInfoModal(false)
    }

    const handleChatButton = () => {
        setShowChatModal(true)
    }

    const handleChatButtonClose = () => {
        setShowChatModal(false)
    }

    const toggleChat = () => {
        setShowChatModal(!showChatModal);
    };

    
    const navigate = useNavigate()
     // Convert UTC time to IST by adding 330 minutes (5 hours and 30 minutes)
     const dateTimeInIST = addMinutes(parseISO(match.dt), 330);
     // Format the date and time to be more human-readable, including AM/PM notation
     const formattedDateTime = format(dateTimeInIST, "dd MMM yyyy, h:mm a") + ' IST'; // e.g., 12 Apr 2024, 7:30 PM IST
   
  return (
    <div className="match-selected-container">
        <div className="match-selected-left">
            <img src="/images/mobile/arrow-left.svg" alt="" className="match-selected-arrow"
                 onClick={() => navigate(-1)}   
            />
            <div>
                <div>
                    <span className="team-info-highlight">{teamOne}</span>
                    <span className="vs-highlight">vs</span>
                    <span className="team-info-highlight">{teamTwo}</span>
                </div>
                <div>
                    <span className="match-time-highlight">{formattedDateTime}</span> 
                    <span className="match-time-highlight hyphen">-</span>
                    <span className="match-time-highlight">{match.venue}</span>
                </div>
            </div>
        </div>
        
        <div className="match-selected-right">
            <img src="/images/common/spark_icon_green.svg" alt=""  className="chat-question" onClick={handleChatButton}/>
            {/* <img src="/images/mobile/note.svg" alt=""  className="match-selected-note"/> */}
            <img src="/images/mobile/questioncircle.png" alt="" className="match-selected-question" onClick={handleInfoButton}/>
        </div>
        {
            showInfoModal &&
            <div className="upcom-info-modal-container"> 
                <HowToUse isOpen={showInfoModal} onClose={handleInfoButtonClose}/>
            </div>  
          }
        {showChatModal && <ChatBot activeMatch={match} isOpen={showChatModal} toggleChat={toggleChat} />}
    </div>
  )
}

export default MatchSelectedM