import React from 'react';
import './teamsavedmodal.css';

function TeamSavedModal({ isOpen, saveOnClose, text = "The teams are saved under \"My Teams\"" }) {
    if (!isOpen) return null;

    return (
        <div className="teamsavedmodal_modal">
            <div className="teamsavedmodal_modal-content">
                <img src="/images/close_icon_2.svg" alt="Close" className="teamsavedmodal_modal-close" onClick={saveOnClose} />
                <div className="teamsavedmodal_modal-body">
                    <img src="/images/team_saved.svg" alt="Team Saved" />
                    <div className="teamsavedmodal_modal-title">Team Saved</div>
                    <div className="teamsavedmodal_modal-text">{text}</div>
                </div>
            </div>
        </div>
    );
}

export default TeamSavedModal;