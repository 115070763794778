import React, { useState, useEffect } from 'react';
import './upcommatch.css';
import axios from 'axios';
import UpcomMatchDetail from './UpcomMatchDetail';
import MatchHighlight from './MatchHighlight';
import InfoPanel from './InfoPanel';
import RightPanel from './RightPanel';
import { useNavigate } from 'react-router-dom'; 
import MatchInsightWrapper from './MatchInsightWrapper';
import { useTheme } from '@mui/material/styles';
import ApproachInput from './ApproachInput'; 
import Approach1Teams from './Approach1Teams';
import MyTeams from './MyTeams';
import ReactGA from 'react-ga4';
import useAnalyticsEvent from '../../components/Home/Common/UseAnalyticsEvent';
import SubscriptionModal from '../../components/Home/Common/SubscriptionModal';
import NoUpcomingMatches from '../Home/Common/NoUpcomingMatches';
import Lottie from "lottie-react";
import ballSpinner from '../../lotties/ball-loading.json'

const UpcomMatch = ({ activePlan, buildTeams, setMyTeams, setBuildTeams }) => {
  const theme = useTheme();
  const [matches, setMatches] = useState([]);
  const [matchId, setMatchId] = useState(null);
  const [matchSquad, setMatchSquad] = useState(null);
  const [matchInsights, setMatchInsights] = useState(null);
  const [showApproachInput, setShowApproachInput] = useState(false);
  const [showApproach1Teams, setShowApproach1Teams] = useState(false);
  const [approach1TeamsData, setApproach1TeamsData] = useState(null);
  const [insightIsLoading, setInsightIsLoading] = useState(true);
  const [squadIsLoading, setSquadIsLoading] = useState(true);
  const [teamSelections, setTeamSelections] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isAPICalled, setIsAPICalled] = useState(null);
  
  const trackAnalyticsEvent = useAnalyticsEvent();
  const navigate = useNavigate();
  const handleSelectionUpdate = (selections) => {
    setTeamSelections(selections);
    console.log("Updated team selections in parent:", selections);
};
  const toggleApproachInput = () => {
    console.log('Toggling Approach Input');
    setShowApproachInput(prev => !prev);
  };

  const toggleApproach1Teams = () => {
    console.log('Toggling to Approach 1 teams display...')
    setShowApproach1Teams(prev => !prev);
  }


  useEffect(() => {
    if (activePlan === "no_active_plan") {
      setIsLoading(false);
      return;
    }
    

    axios.get(`${process.env.REACT_APP_API_BASE_URL}/fetch-matches-with-details/`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('idToken')}`
      }
    })
    .then(response => {
      setMatches(response.data.matches);
      if (response.data.matches.length > 0) {
        setMatchId(response.data.matches[0].match_id);
      }
      trackAnalyticsEvent("Match Details", "Viewed List of Matches", 1);
      setIsAPICalled(true);
    })
    .catch(error => {
      console.error('Error fetching matches:', error);
      if (error.response && error.response.status === 401) {
        navigate('/login');
        setIsAPICalled(true);
      }
    })
    .finally(() => {
      setIsLoading(false);
      setIsAPICalled(true);
    });
  }, [navigate, activePlan]);

  

  useEffect(() => {
    if (matchId !== null) {
      loadMatchDet(matchId);
      loadMatchInsights(matchId);
    }
  }, [matchId]);

  const loadMatchInsights = (id) => {
    setInsightIsLoading(true);
    const token = localStorage.getItem('idToken');
    if (!token) {
        console.error('No token found, redirecting to login.');
        navigate('/login');
        return;
    }

    console.log("Loading match insights for ID:", id);
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/match-summary-insights/`, { match_id: id }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response => {
      console.log("Match insights fetched:", response.data);
      setMatchInsights(response.data);
      setInsightIsLoading(false);
      // const uid = localStorage.getItem('UID');  
      // const sessionID = localStorage.getItem('sessionID');  
      trackAnalyticsEvent("Match Details", "Viewed Match Insights",1);
    })
    .catch(error => {
      setInsightIsLoading(false);
      console.error('Error fetching match insights:', error);
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
    });
};

const loadMatchDet = (id) => {
    setSquadIsLoading(true);
    const token = localStorage.getItem('idToken');
    if (!token) {
        console.error('No token found, redirecting to login.');
        navigate('/login');
        return;
    }

    console.log("Loading match details for ID:", id);
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/match-squad/`, { match_id: id }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response => {
      console.log("Match squad fetched:", response.data);
      if (typeof response.data === 'string') {
          try {
              const parsedData = JSON.parse(response.data);
              console.log("Parsed match squad:", parsedData);
              setMatchSquad(parsedData);
              // const uid = localStorage.getItem('UID');  // Assuming UID is stored in localStorage
              // const sessionID = localStorage.getItem('sessionID');  // Assuming sessionID is stored in localStorage
              trackAnalyticsEvent("Match Details", "Viewed Match Squad",1);
          } catch (error) {
              console.error('Error parsing match squad:', error);
          }
      } else {
          setMatchSquad(response.data);
      }
      setSquadIsLoading(false);
    })
    .catch(error => {
      setSquadIsLoading(false);
      console.error('Error fetching match squad:', error);
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
    });
};

  const handleClick = (id) => {
    setMatchId(id);  // Update the matchId when a match is clicked
    setShowApproachInput(false)
    setShowApproach1Teams(false)
    setMyTeams(false)
    setBuildTeams(true)
  };

  const activeMatch = matches.find(match => match.match_id === matchId);

  const renderContent = () => {
    if (isLoading) {
      return (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000
        }}>
          <Lottie animationData={ballSpinner} loop={true} style={{ width: '50px', height: '50px' }} />
        </div>
      );
    }

    if (matches.length === 0 && isAPICalled) {
      return <NoUpcomingMatches />;
    }

    return (
      <div className="upcom-container-d">
        <div className="upcom-wrapper-d">
            <div className="upcom-left-container-d">
              <div className="upcom-header-container-d">
                <p className="upcom-header-d">Upcoming Matches</p>
              </div>
              <div className="upcom-match-container-d">
                {matches.map(match => (
                  <UpcomMatchDetail 
                    match={match} 
                    key={match.match_id} 
                    loadMatch={handleClick}
                    activeId={matchId} 
                  />
                ))}
              </div>
            </div>
            <div className="upcom-center-container">
              <div className="highlight-container">
                {activeMatch && <MatchHighlight match={activeMatch} />}
              </div>
              {buildTeams ? (
                <div className="report-container">
                  {activePlan === "no_active_plan" ? (
                    <SubscriptionModal />
                  ) : showApproach1Teams ? (
                    <Approach1Teams matchDetails={activeMatch} onBackClick={toggleApproach1Teams} teamDetails_response={approach1TeamsData} />
                  ) : showApproachInput ? (
                    <ApproachInput activeMatch={activeMatch} onBackClick={toggleApproachInput} matchSquad={matchSquad} toggleApproach1Teams={toggleApproach1Teams} setApproach1TeamsData={setApproach1TeamsData} teamSelections={teamSelections} />
                  ) : (
                    <MatchInsightWrapper
                      onSelectionUpdate={handleSelectionUpdate}
                      teamSelections={teamSelections}
                      matchInsights={matchInsights}
                      activeMatch={activeMatch}
                      matchSquad={matchSquad}
                      toggleApproachInput={toggleApproachInput}
                      toggleApproach1Teams={toggleApproach1Teams}
                      insightIsLoading={insightIsLoading}
                      squadIsLoading={squadIsLoading}
                    />
                  )}
                </div> 
              ) : (
                <div className='myTeamContainer'>
                  <MyTeams activeMatch={activeMatch} />
                </div>
              )}
            </div>
          </div>
      </div>
    );
  };

  return renderContent();
};

export default UpcomMatch;
