import {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import MatchSelectedM from './MatchSelectedM'
import { useLocation } from 'react-router-dom'
import Lottie from "lottie-react";
import ballSpinner from '../../../lotties/ball-loading.json'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import axios from 'axios'
import './myteamsplayersM.css'


function formatPlayerType(type) {
    return type.replace(/_/g, ' ')
        .replace(/(first|second) /, '')
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

const MyTeamsPlayersM = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const filepath = location.state[0]
  const fileTitle = location.state[1]
  const firstTeam = location.state[2]
  const secondTeam = location.state[3]

  const [teamStyles, setTeamStyles] = useState({});
  const [selectedTeamDetails, setSelectedTeamDetails] = useState(null);
  const [selectedTeamIndex, setSelectedTeamIndex] = useState(null); // Track the index of the selected team
  const [selectedTeamTitle, setSelectedTeamTitle] = useState(''); // Track the title of the selected team
  const [insights, setInsights] = useState('');
  const [chartData, setChartData] = useState([]); 
  const [isLoading, setIsLoading] = useState(true);
  
  const match = JSON.parse(localStorage.getItem('teamSavedSelected'))

  const loadingOverlay =  (
    <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: ' rgba(128,128,128,0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000
      }}>
      <Lottie animationData={ballSpinner} loop={true} style={{ width: '50px', height: '50px' }} />
    </div>) 
    

  useEffect(()=>{
    const token = localStorage.getItem('idToken');
    if (!token) {
        console.error('No token found, redirecting to login.');
        navigate('/login');
        return;
    }
    setIsLoading(true);
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/fetch-single-team/`, { match_id: match.match_id, filepath }, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
    .then(response => {
        console.log("Single Team data:", response.data);
        setSelectedTeamDetails(response.data.file_data.team_details);
        setChartData(response.data.file_data.chartData);
        console.log(response.data.file_data.chartData)
        setInsights(response.data.file_data.insights)
        setSelectedTeamIndex(location.state[4]); // Set the selected index
        setSelectedTeamTitle(fileTitle); // Set the title of the selected team
        setIsLoading(false);
    })
    .catch(error => {
        console.error('Error fetching single team:', error);
        if (error.response && error.response.status === 401) {
            navigate('/login');
        }
        setIsLoading(false);
    })
  },[match.match_id])


  useEffect(() => {
    if (!selectedTeamDetails) return; // Make sure the data is there before attempting to use it

    const uniqueTeams = new Set(selectedTeamDetails.map(player => player.team_name));
    const teamArray = Array.from(uniqueTeams);
    setTeamStyles({
        [teamArray[0]]: 'teamStyleBlack',
        [teamArray[1]]: 'teamStyleWhite',
    });
  }, [selectedTeamDetails]);

  return (
    <div className="myteams-players-container">
        <MatchSelectedM  teamSaved={true} />
        {
          isLoading?   loadingOverlay:
            (
            <div className="myteams-player-insight-wrapper">
                <div className="myteams-wrapper">
                    <div className="teams-selected-names">
                        <p className="show-teams-top-text">{fileTitle}</p> 
                    </div>
                    <div className="myteams-players-list-section">
                        {selectedTeamDetails.map((player, index) => (
                            <div key={index} className="myteams-playerlist-wrapper">
                                <div className='MyTeamsplayerDetailContainer-m'>
                                    <span className='myteams-players-serial'>{index + 1}</span> 
                                </div>  
                                <div className="myteams-players-details">
                                    <div className="eachPlayerList-m">
                                        <span className='playerName-m'>{player.player_name}</span>
                                        <span className={`teamName-m ${teamStyles[player.team_name]}`}>{player.team_name}</span>
                                        <img src={player.type.includes('bat') ? "/images/baticon.svg" : "/images/bowler.svg"} alt={player.type.includes('bat') ? "Bat Icon" : "Bowler Icon"}  
                                                className="team-recom-player-icon"/>
                                        {['C', 'VC'].includes(player.captain_flag) && (
                                        <span className="role-circle">{player.captain_flag}</span>
                                    )}
                                    </div>
                                    <div>
                                        <span className='playerType-m'>{formatPlayerType(player.type)}</span>
                                    </div>
                                </div>   
                    
                            </div>
                        ))}
                    </div>
                </div>

                <div className='myteams-wrapper'>
                    <span className="MyTeam-name">Team Scenario</span>
                    <div className="myteams-insight-wrapper">
                    {
                        chartData.length !== 0 ?
                    <>
                    <p>{insights}</p>
                    <div className="myteams-chartContainer">
                    <ResponsiveContainer width="100%" height={200}>
                        <BarChart data={chartData} margin={{ top: 5, right: 30, left: 5, bottom: 5 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            {/* <Legend /> */}
                            <Bar dataKey="First_Innings_Runs" fill="#00876c" name="First Innings Runs" />
                            <Bar dataKey="Second_Innings_Runs" fill="#7ead9d" name="Second Innings Runs" />
                        </BarChart>
                    </ResponsiveContainer>
                    {/* </div>
                    <div className="MyTeamsChartContainer"> */}
                    <ResponsiveContainer width="100%" height={200}>
                        <BarChart data={chartData} margin={{ top: 5, right: 35, left: 5, bottom: 5 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            {/* <Legend /> */}
                            <Bar dataKey="First_Innings_Wickets" fill="#00876c" name="First Innings Wickets" />
                            <Bar dataKey="Second_Innings_Wickets" fill="#7ead9d" name="Second Innings Wickets" />
                        </BarChart>
                    </ResponsiveContainer>
                    </div>
                    </>
                    :
                    <span className="myteams-no-teams-text">No Data to show</span> 
                    }
                    </div>
                </div>        

            </div>
            )
        }
        {/*
        <div className="myteams-players-btn-wrapper">
            <button>Download</button>
        </div> */}
    </div>
  )
}

export default MyTeamsPlayersM