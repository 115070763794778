import { useState, useEffect } from 'react'
import GuessCheatSheetM from './GuessCheatSheetM'
import './approachcompM.css'
import { useNavigate } from 'react-router-dom'
import Lottie from "lottie-react";
import ballSpinner from '../../../lotties/ball-loading.json';

const ApproachCompM = () => {
  const [option, setOption] = useState("option1")
  const match = JSON.parse(localStorage.getItem('matchSelected'))
  // const teamNames = match.match_name.split('|')[1].trim().split(' vs ').map(name => name.trim());
  const firstTeam = match.team1_name
  const secondTeam= match.team2_name
  const [activeTeam, setActiveTeam] = useState(firstTeam)
  const [nextBtnActv, setNextBtnActv ] = useState(true)
  const [language, setLanguage] = useState("English")
  const [errMsg, setErrMsg] = useState("")
  const [showTooltip, setShowTooltip] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false)

  const loadingOverlay = fetchLoading ? (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000
    }}>
      <Lottie animationData={ballSpinner} loop={true} style={{ width: '50px', height: '50px' }} />
    </div>
) : null;

 
  const [batTeam, setBatTeam] = useState({
    firstTeam:firstTeam,
    firstTeamExpScore:"",
    firstTeamExpWktFallen:"0",
    firstTeamRunsPP:'0',
    firstTeamWicketPP:'0',
    firstTeamRunsMO:'0',
    firstTeamWicketMO:'0',
    firstTeamRunsDO:'0',
    firstTeamWicketDO:'0',
    secondTeam:secondTeam,
    secondTeamExpScore:"",
    secondTeamExpWktFallen:"0",
    secondTeamRunsPP:'0',
    secondTeamWicketPP:'0',
    secondTeamRunsMO:'0',
    secondTeamWicketMO:'0',
    secondTeamRunsDO:'0',
    secondTeamWicketDO:'0',
  })

  const navigate = useNavigate()
  // const BASE_URL = "https://asak4lmpca.execute-api.us-east-2.amazonaws.com/dev"
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;


  const descriptions = {
    matchScore: {
        English: "Users can choose teams based on the score they predict for the match. This approach allows you to input expected scores for the first and second innings, including wickets likely to fall. To further refine your predictions, you can provide detailed scores for the powerplay and death overs. Based on these inputs, our AI analyzes player roles and performance to recommend the most suitable players for your fantasy team.",
        Hindi: "उपयोगकर्ता मैच के अनुमानित स्कोर के आधार पर टीमें चुन सकते हैं। इस दृष्टिकोण में आप पहली और दूसरी पारी के स्कोर और गिरने वाले विकेट की संख्या भर सकते हैं। आपकी भविष्यवाणियों को और सटीक बनाने के लिए, आप पावरप्ले और डेथ ओवर्स के लिए विस्तृत स्कोर प्रदान कर सकते हैं। इन इनपुटों के आधार पर हमारी AI प्लेयर की भूमिकाओं और प्रदर्शन का विश्लेषण करती है और आपकी फैंटेसी टीम के लिए सबसे उपयुक्त खिलाड़ियों की सिफारिश करती है।"
    },
    playerPosition: {
        English: "Users can form teams based on a player's playing position. The system recommends compatible players and advises against less optimal choices based on the current team configuration and player positions. This method helps maximize team synergy and overall performance.",
        Hindi: "उपयोगकर्ता खिलाड़ी की प्लेइंग पोजीशन के आधार पर टीमें बना सकते हैं। सिस्टम संगत खिलाड़ियों की सिफारिश करता है और मौजूदा टीम कॉन्फ़िगरेशन और खिलाड़ियों की पोजीशन के आधार पर कम उपयुक्त विकल्पों के खिलाफ सलाह देता है। यह विधि टीम सिनर्जी और समग्र प्रदर्शन को अधिकतम करने में मदद करती है।"
    }
  };
  const key = 'approachInputData';

  useEffect(()=>{
    if(batTeam.firstTeam && batTeam.firstTeamExpScore &&
        batTeam.secondTeam && batTeam.secondTeamExpScore){
        setNextBtnActv(false)
        console.log(nextBtnActv)
      }else{
        setNextBtnActv(true)
        console.log(nextBtnActv)
      }
  },[batTeam.firstTeam, batTeam.firstTeamExpScore, batTeam.firstTeamExpWktFallen,
    batTeam.secondTeam, batTeam.secondTeamExpScore, batTeam.secondTeamExpWktFallen 
  ])
    
  const handleInputChanges = (e) =>{
    console.log(e.target.value, e.target.name)
    setBatTeam(prevData => {
      return{...prevData, [e.target.name]: e.target.value}
    })
   
  }

  const handleLangChange = (lang) =>{
    console.log("clicked on language change..........")
    setLanguage(lang)
  }

  const handleOptionChange = (opt) =>{
    setOption(opt)
  }

  const handleTeamChange = (activeTeam) =>{
    setActiveTeam(activeTeam)
    setBatTeam(prevData => {
      return{...prevData, firstTeam: activeTeam, secondTeam: activeTeam===firstTeam? secondTeam: firstTeam}
    })
    console.log(activeTeam)
  }

  const handleSubmit = (e) =>{
    e.preventDefault()
    console.log("Bat team:",batTeam)

    const advancedInputsFirstInning = [
      batTeam.firstTeamRunsPP, batTeam.firstTeamWicketPP,
      batTeam.firstTeamRunsMO, batTeam.firstTeamWicketMO,
      batTeam.firstTeamRunsDO, batTeam.firstTeamWicketDO
    ]
    const advancedInputsSecondInning = [
      batTeam.secondTeamRunsPP, batTeam.secondTeamWicketPP,
      batTeam.secondTeamRunsMO, batTeam.secondTeamWicketMO,
      batTeam.secondTeamRunsDO, batTeam.secondTeamWicketDO
    ]

    const allFirstInningInputsFilled = advancedInputsFirstInning.filter(item => item !== '0')
    const allSecondInningInputsFilled = advancedInputsSecondInning.filter(item => item !== '0')
    
   console.log("allFirstInningInputsFilled", allFirstInningInputsFilled.length)
    if((allFirstInningInputsFilled.length > 0 && allFirstInningInputsFilled.length <6)
        || (allSecondInningInputsFilled.length > 0 && allSecondInningInputsFilled.length <6)) 
        {
          console.log("I have Error")
          setErrMsg("For Advanced Input: Please either fill all values or leave all empty. Partial input is not accepted.")  
          return
        }else{
          setErrMsg("")
    }

    // Sum calculations for runs and wickets
    const sumFirstInningWickets = parseInt(batTeam.firstTeamWicketPP) + parseInt(batTeam.firstTeamWicketMO) + parseInt(batTeam.firstTeamWicketDO);
    const sumSecondInningWickets = parseInt(batTeam.secondTeamWicketPP) + parseInt(batTeam.secondTeamWicketMO) + parseInt(batTeam.secondTeamWicketDO);
    const sumFirstInningRuns = parseInt(batTeam.firstTeamRunsPP) + parseInt(batTeam.firstTeamRunsMO) + parseInt(batTeam.firstTeamRunsDO);
    const sumSecondInningRuns = parseInt(batTeam.secondTeamRunsPP) + parseInt(batTeam.secondTeamRunsMO) + parseInt(batTeam.secondTeamRunsDO);

    // Validate wickets and runs
    if (sumFirstInningWickets > 10 || sumSecondInningWickets > 10) {
      setErrMsg('Total wickets in any inning cannot exceed 10.');
      return;
    }
    if (sumFirstInningRuns > 450 || sumSecondInningRuns > 450) {
      setErrMsg('Total runs in any inning cannot exceed 450.');
      return;
    }

    const teamSelected = JSON.parse(localStorage.getItem('playerList')) 
    const teamsData = Object.entries(teamSelected).map(([key, playerIds]) => {
      const [teamId, teamName] = key.split(' - ');
      const playerIdArr = playerIds.map(player => player.player_details.player_id)
      return { team_id: teamId, team_name: teamName, player_ids: playerIdArr };
    })
    console.log("TeamsData",teamsData)

    const team1_id = batTeam.firstTeam === match.team1_name ? match.team1_id : match.team2_id;
    const team2_id = batTeam.secondTeam === match.team1_name ? match.team1_id : match.team2_id;
    const team1_name = batTeam.firstTeam === match.team1_name ? match.team1_name : match.team2_name;
    const team2_name = batTeam.secondTeam === match.team1_name ? match.team1_name : match.team2_name;

    const phaseScore = {
      first_batting_score: (parseInt(batTeam.firstTeamRunsPP, 10) || 0) +
                            (parseInt(batTeam.firstTeamRunsMO, 10) || 0) +
                            (parseInt(batTeam.firstTeamRunsDO, 10) || 0),
      first_batting_wicket_lost: (parseInt(batTeam.firstTeamWicketPP, 10) || 0) +
                                 (parseInt(batTeam.firstTeamWicketMO, 10) || 0) +
                                 (parseInt(batTeam.firstTeamWicketDO, 10) || 0),
      second_batting_score: (parseInt(batTeam.secondTeamRunsPP, 10) || 0) +
                            (parseInt(batTeam.secondTeamRunsMO, 10) || 0) +
                            (parseInt(batTeam.secondTeamRunsDO, 10) || 0),
      second_batting_wicket_lost: (parseInt(batTeam.secondTeamWicketPP, 10) || 0) +
                                  (parseInt(batTeam.secondTeamWicketMO, 10) || 0) +
                                  (parseInt(batTeam.secondTeamWicketDO, 10) || 0),
      runs_scored_powerplay_first_inning: parseInt(batTeam.firstTeamRunsPP, 10) || 0,
      runs_scored_middle_first_inning: parseInt(batTeam.firstTeamRunsMO, 10) || 0,
      runs_scored_death_first_inning: parseInt(batTeam.firstTeamRunsDO, 10) || 0,
      wickets_taken_powerplay_first_inning: parseInt(batTeam.firstTeamWicketPP, 10) || 0,
      wickets_taken_middle_first_inning: parseInt(batTeam.firstTeamWicketMO, 10) || 0,
      wickets_taken_death_first_inning: parseInt(batTeam.firstTeamWicketDO, 10) || 0,
      runs_scored_powerplay_second_inning: parseInt(batTeam.secondTeamRunsPP, 10) || 0,
      runs_scored_middle_second_inning: parseInt(batTeam.secondTeamRunsMO, 10) || 0,
      runs_scored_death_second_inning: parseInt(batTeam.secondTeamRunsDO, 10) || 0,
      wickets_taken_powerplay_second_inning: parseInt(batTeam.secondTeamWicketPP, 10) || 0,
      wickets_taken_middle_second_inning: parseInt(batTeam.secondTeamWicketMO, 10) || 0,
      wickets_taken_death_second_inning: parseInt(batTeam.secondTeamWicketDO, 10) || 0
    }

    // Check if any values are null
    const hasNullValues = Object.values(phaseScore).some(value => value === null);

    const postData = {
      match_id: match.match_id,
      first_inning_cluster: `${batTeam.firstTeamExpScore}__${batTeam.firstTeamExpWktFallen}`,
      second_inning_cluster: `${batTeam.secondTeamExpScore}__${batTeam.secondTeamExpWktFallen}`,
      team1_name: team1_name,
      team2_name: team2_name,
      team1_id: team1_id,
      team2_id: team2_id,
      teams: teamsData,
      phase_score: hasNullValues ? {} : phaseScore
    }
    setFetchLoading(true)

    fetch(`${BASE_URL}/get_team_from_score/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('idToken')}`
      },
      body: JSON.stringify(postData)
    })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data.team_details);
        localStorage.removeItem('teamRecommend');
        localStorage.setItem('teamRecommend', JSON.stringify(data.team_details));
        navigate('/team-recommend');
        setFetchLoading(false); // Set loading to false on successful completion
      })
      .catch(error => {
        console.error('Error:', error);
        setFetchLoading(false); // Set loading to false on failure
      });
      
  }

  return (
    <form className="approach-container" onSubmit={handleSubmit}>
      {loadingOverlay}

        <div className="approach-options">
            <div className="approach-choose">
                <input id="appr-one" type="radio" name="type" 
                        value="option1" checked={option==="option1"}
                        onChange={()=>handleOptionChange("option1")} />
                <label htmlFor='appr-one'>Based on Match Score</label>
            </div>
            <div className="approach-info-right" >
                <img src="/images/mobile/info-grey.svg" alt="" 
                      className="approach-info-icon"
                      onClick={(e) => { e.stopPropagation(); setShowTooltip(true); }}
                />

                {showTooltip && (
                  <div className="approach-tooltip">
                    <div className="tooltip-header">
                      <div className="insights-lang-block">
                        <span className={language === 'English' ? 'insights-lang-eng insight-lang-bg' : 'insights-lang-eng'} onClick={() => handleLangChange('English')}>
                          Eng
                        </span>
                        <span className={language === 'Hindi' ? 'insights-lang-hindi insight-lang-bg' : 'insights-lang-hindi'} onClick={() => handleLangChange('Hindi')}>
                          हिंदी
                        </span>
                      </div>
                      <img src="/images/close_icon_2.svg" alt="Close" className="explain-close-icon" onClick={(e) => setShowTooltip(false)} />
                    </div>
                    <span>{descriptions.matchScore[language]}</span>
                  </div>
                )}


             </div>
        </div>

        {
          (option === "option1") ?
            <div className="appr-one-best-guess-wrapper">
                <p className="select-players-toptext">Best Guess</p>
                <p className="appr-sub-text">Use the cheatsheet to guess the score</p>
                <GuessCheatSheetM 
                                 teamOne={firstTeam}
                                 teamTwo={secondTeam} 
                                 activeTeam={activeTeam}
                                 handleTeamChange={handleTeamChange}
                                 batTeam={batTeam}
                                 handleInputChanges={handleInputChanges}
                />
            </div>
            : null
        }
        <div>
          {errMsg &&
          <div className="select-player-error">
              <img src="/images/mobile/alert-icon.svg" alt="" />
              <span>{errMsg}</span>
          </div>
          }
          <button className={nextBtnActv? "approach-next-btn":"approach-next-btn approach-next-btn-actv"}
              disable={nextBtnActv} type="submit">
              Next
          </button>
        </div>
    </form>
  )
}

export default ApproachCompM