
import './playercardpage.css'
// import PlayCardDetails from '../../../components/Home/Mobile/PlayCardDetails'
import PlayCardDetails from './PlayCardDetails'
// import { useLocation } from 'react-router-dom'
// import { useNavigate } from 'react-router-dom'

const PlayerCardPage = ({player, handleDetail}) => {
  // const location = useLocation()
  // const player = location.state
  // const navigate = useNavigate()
  console.log(player)

  return (
    <div className="player-card-container">
        {/*Division for top part of screen */}
        <div className="player-card-top-section">
            <span>Player Info</span>
            <img src="/images/mobile/close-icon.svg" alt="" 
                  onClick={() => handleDetail('close')}
            />
        </div>

        {/*Division for player icon and name */}
        <div className="player-card-name-section">
            <img src="/images/common/player-icon.svg" alt="" />
            <span>{player.player_details.player_name}</span>
        </div>
        <div className='player-performance-section'>
        {
          player.performances.map((performance) =>(
              <PlayCardDetails performance={performance} 
                                key={performance.match_start_date}
              />
          ))
        }
        </div>  
    </div>
  )
}

export default PlayerCardPage