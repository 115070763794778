import React from 'react'
import './landingfooter.css'

const LandingFooter = () => {
  return (
    <div className="land-footer-container">
      <div className="land-logo-footer">
          <img src="/images/common/logo-footer.svg" alt="" className="land-logo-name-footer"/>
      </div>
      <div className="land-footer-links">
          <a className="land-link-list" href="#">support@cricbotz.com</a>
          <a className="land-link-list" href="/terms-and-conditions">Terms & Conditions</a>
          <a className="land-link-list" href="/privacy-policy">Privacy Policy</a>
          <a className="land-link-list" href="/billing-subscription">Billing And Subscription</a>
      </div>
    </div>
  )
}

export default LandingFooter