import {useState} from 'react'
import './playcarddetails.css'

const PlayCardDetails = ({performance}) => {
    // console.log(performance)
    const [caretUp, setCaretUp] = useState(true) 
    
    const handleCaret = () =>{
      setCaretUp(prevState => !prevState)
    }  

    const monthNames = [
        "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
    ];
    const matchDate = new Date(performance.match_start_date)
    const dayOfMonth = matchDate.getDate()
    const month = monthNames[matchDate.getMonth()]
    const year = matchDate.getFullYear()

  return (
    <div className="player-card-detail-section">
        {/*Start- Division for player played match details-top */}
        <div className="player-card-detail-match" onClick={handleCaret}>
            <div className="player-card-detail-match-left">
                <p className="player-card-team">{performance.team1_name}</p>
                <span className="player-card-vs">vs</span>
                <span className="player-card-team">{performance.team2_name}</span>
                <span className="player-card-dot">·</span>
                <span>{`${dayOfMonth} ${month} ${year}`}</span>
            </div>
            <div className="player-card-detail-match-right">
                <img src={caretUp ? "/images/common/chevron-up.svg" : "/images/common/chevron-down.svg"} 
                        alt={caretUp ? "Collapse" : "Expand"} />
            </div>
        </div>
        {/*End- Division for player played match details-top */}

        {/*Start- Division for player played match details-middle */}
        <div className="player-card-player-detail">
            <div className="player-card-player-parm">
                <p>Batting Position</p>
                <span>{performance.batting_position}</span>
            </div>
            <div className="player-card-player-parm">
                <p>Players Rank</p>
                
                <span style={{
                        padding: '3px 10px',
                        borderRadius: '10px',
                        backgroundColor: parseInt(performance.rank, 10) <= 11 ? '#8cbcac' : 'transparent',
                        display: 'inline-block',
                        width:'30px',
                        alignItems:'center',
                        justifyContent:'center'
                      }}>{performance.rank}</span>
            </div>
            <div className="player-card-player-parm">
                <p>Fantasy Pts</p>
                <span>{performance.total_fantasy_points}</span>
            </div>
        </div>
        {/*End- Division for player played match details-middle */}
        {
        !caretUp &&
        <>
            <div className="player-card-bat-text">
                <p>Batting and Bowling</p>
            </div>
            
            {/*Start- Division for player played match details-middle */}
            <div className="player-card-over-details">
                <div className="player-card-over-items">
                    <p className="pc-text">Run Scored / Ball Faced / SR</p>
                    <span style={{
                          padding: '3px 10px',
                          borderRadius: '10px',
                          backgroundColor: parseInt(performance.runs_scored, 10) >= 50 ? '#8cbcac' : 'transparent',
                          display: 'inline-block'
                        }}><p>{performance.runs_scored} / {performance.balls_faced} / {parseInt(performance.batting_strike_rate, 10)}</p></span>
                    
                </div>
                <div className="player-card-over-items">
                    <p className="pc-text">Overs Bowled / Wickets Taken / Runs Conceded</p>
                    
                    <span style={{
                            padding: '3px 10px',
                            borderRadius: '10px',
                            backgroundColor: parseInt(performance.wickets_taken, 10) >= 2 ? '#8cbcac' : 'transparent',
                            display: 'inline-block'
                          }}> 
                          {parseInt(performance.overs_bowled, 10)} / {performance.wickets_taken}/ {performance.runs_conceded}</span>
                </div>
                <div className="player-card-over-items">
                    <p className="pc-text">Power-Play Overs Bowled / Wickets Taken / Runs Conceded</p>
                    <span style={{
                            padding: '3px 10px',
                            borderRadius: '10px',
                            backgroundColor: parseInt(performance.powerplay_wickets_taken, 10) >= 2 ? '#8cbcac' : 'transparent',
                            display: 'inline-block'
                          }}> {parseInt(performance.powerplay_overs, 10)} / {performance.powerplay_wickets_taken} / {performance.powerplay_runs_conceded}</span>
                </div>
                <div className="player-card-over-items">
                    <p className="pc-text">Death Overs Bowled / Wickets Taken / Runs Conceded</p>
                    <span style={{
                            padding: '3px 10px',
                            borderRadius: '10px',
                            backgroundColor: parseInt(performance.death_wickets_taken, 10) >= 2 ? '#8cbcac' : 'transparent',
                            display: 'inline-block'
                          }}>{parseInt(performance.death_overs, 10)} / {performance.death_wickets_taken} / {performance.death_runs_conceded}</span>
                </div>
            </div>
            {/*End- Division for player played match details-middle */}
        </>}    
    </div>
  )
}

export default PlayCardDetails