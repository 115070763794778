import React, { useState, useEffect } from 'react';
import './approachinput.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; 
import FeedbackButton from '../../components/Home/Common/FeebackButton';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import GuessCheatSheetM from '../Home/Mobile/GuessCheatSheetM';
import { useNavigate } from 'react-router-dom';
import useAnalyticsEvent from '../../components/Home/Common/UseAnalyticsEvent';
import Lottie from "lottie-react";
import ballSpinner from '../../lotties/ball-loading.json';

const ApproachInput = ({ onBackClick, activeMatch, matchSquad, toggleApproach1Teams, setApproach1TeamsData, teamSelections }) => {
    
    const [selectedOption, setSelectedOption] = useState('matchScore');
    const [language, setLanguage] = useState('English');
    const [errMsg, setErrMsg] = useState("")
    const [fetchLoading, setFetchLoading] = useState(false)
    const navigate = useNavigate()
    const trackAnalyticsEvent = useAnalyticsEvent();
    const localStorageKey = 'approachInputData';

    // State initialization from localStorage or defaults
    const initialData = () => {
        const savedData = localStorage.getItem(localStorageKey);
        if (savedData) {
            const parsedData = JSON.parse(savedData);
            if (parsedData && parsedData.activeMatchId === activeMatch?.match_id) {
                return parsedData; // Return saved data if it exists and matches the current match
            }
        }
        // Default state if no data is saved or it's a different match
        return {
            firstTeam: activeMatch.team1_name,
            secondTeam: activeMatch.team2_name,
            firstTeamExpScore: "",
            firstTeamExpWktFallen: "0",
            firstTeamRunsPP: '0',
            firstTeamWicketPP: '0',
            firstTeamRunsMO: '0',
            firstTeamWicketMO: '0',
            firstTeamRunsDO: '0',
            firstTeamWicketDO: '0',
            secondTeamExpScore: "",
            secondTeamExpWktFallen: "0",
            secondTeamRunsPP: '0',
            secondTeamWicketPP: '0',
            secondTeamRunsMO: '0',
            secondTeamWicketMO: '0',
            secondTeamRunsDO: '0',
            secondTeamWicketDO: '0',
        };
    };

    const [batTeam, setBatTeam] = useState(initialData);

    useEffect(() => {
        const dataToSave = {
            ...batTeam,
            activeMatchId: activeMatch?.match_id,
        };
        localStorage.setItem(localStorageKey, JSON.stringify(dataToSave));
    }, [batTeam, activeMatch]);

    useEffect(() => {
        const restoreData = initialData(); // Call initialData to attempt loading from localStorage
        setBatTeam(restoreData); // Set state with either loaded data or defaults
    }, [activeMatch.match_id]);

    const loadingOverlay = fetchLoading ? (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000
        }}>
          <Lottie animationData={ballSpinner} loop={true} style={{ width: '50px', height: '50px' }} />
        </div>
    ) : null;

    const firstTeam = activeMatch.team1_name
    const secondTeam= activeMatch.team2_name
    const [activeTeam, setActiveTeam] = useState(firstTeam)
    // console.log("firstTeam ==> ",firstTeam)
    // const [batTeam, setBatTeam] = useState({
    //     firstTeam:firstTeam,
    //     firstTeamExpScore:"",
    //     firstTeamExpWktFallen:"0",
    //     firstTeamRunsPP:'0',
    //     firstTeamWicketPP:'0',
    //     firstTeamRunsMO:'0',
    //     firstTeamWicketMO:'0',
    //     firstTeamRunsDO:'0',
    //     firstTeamWicketDO:'0',
    //     secondTeam:secondTeam,
    //     secondTeamExpScore:"",
    //     secondTeamExpWktFallen:"0",
    //     secondTeamRunsPP:'0',
    //     secondTeamWicketPP:'0',
    //     secondTeamRunsMO:'0',
    //     secondTeamWicketMO:'0',
    //     secondTeamRunsDO:'0',
    //     secondTeamWicketDO:'0',
    //   })

      const handleInputChanges = (e) =>{
        // console.log(e.target.value, e.target.name)
        setBatTeam(prevData => {
          return{...prevData, [e.target.name]: e.target.value}
        })
       
      }
      const handleTeamChange = (activeTeam) =>{
        setActiveTeam(activeTeam)
        setBatTeam(prevData => {
          return{...prevData, firstTeam: activeTeam, secondTeam: activeTeam===firstTeam? secondTeam: firstTeam}
        })
        // console.log(activeTeam)
      }
    
    

    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const descriptions = {
        matchScore: {
            English: "You can choose teams based on the score you predict for the match. This approach allows you to input expected scores for the first and second innings, including wickets likely to fall. To further refine your predictions, you can provide detailed scores for the powerplay and death overs. Based on these inputs, our AI analyzes player roles and performance to recommend the most suitable players for your fantasy team.",
            Hindi: "आप मैच के अनुमानित स्कोर के आधार पर टीमें चुन सकते हैं। इस दृष्टिकोण में आप पहली और दूसरी पारी के स्कोर और गिरने वाले विकेट की संख्या भर सकते हैं। आपकी भविष्यवाणियों को और सटीक बनाने के लिए, आप पावरप्ले और डेथ ओवर्स के लिए विस्तृत स्कोर प्रदान कर सकते हैं। इन इनपुटों के आधार पर हमारी AI प्लेयर की भूमिकाओं और प्रदर्शन का विश्लेषण करती है और आपकी फैंटेसी टीम के लिए सबसे उपयुक्त खिलाड़ियों की सिफारिश करती है।"
        },
        playerPosition: {
            English: "You can form teams based on a player's playing position. The system recommends compatible players and advises against less optimal choices based on your current team configuration and player positions. This method helps maximize team synergy and overall performance.",
            Hindi: "आप खिलाड़ी की प्लेइंग पोजीशन के आधार पर टीमें बना सकते हैं। सिस्टम संगत खिलाड़ियों की सिफारिश करता है और आपके मौजूदा टीम कॉन्फ़िगरेशन और खिलाड़ियों की पोजीशन के आधार पर कम उपयुक्त विकल्पों के खिलाफ सलाह देता है। यह विधि टीम सिनर्जी और समग्र प्रदर्शन को अधिकतम करने में मदद करती है।"
        }
    };

    const toggleLanguage = (event, newLanguage) => {
        if (newLanguage !== null) {
            setLanguage(newLanguage);
        }
    };

    const fetchTeams = (e) =>{
        e.preventDefault()
        // console.log("Bat team:",batTeam)
    
        const advancedInputsFirstInning = [
          batTeam.firstTeamRunsPP, batTeam.firstTeamWicketPP,
          batTeam.firstTeamRunsMO, batTeam.firstTeamWicketMO,
          batTeam.firstTeamRunsDO, batTeam.firstTeamWicketDO
        ]
        const advancedInputsSecondInning = [
          batTeam.secondTeamRunsPP, batTeam.secondTeamWicketPP,
          batTeam.secondTeamRunsMO, batTeam.secondTeamWicketMO,
          batTeam.secondTeamRunsDO, batTeam.secondTeamWicketDO
        ]
    
        const allFirstInningInputsFilled = advancedInputsFirstInning.filter(item => item !== '0')
        const allSecondInningInputsFilled = advancedInputsSecondInning.filter(item => item !== '0')
        
       console.log("allFirstInningInputsFilled", allFirstInningInputsFilled.length)
        if((allFirstInningInputsFilled.length > 0 && allFirstInningInputsFilled.length <6)
            || (allSecondInningInputsFilled.length > 0 && allSecondInningInputsFilled.length <6)) 
            {
              console.log("I have Error")
              console.log("allFirstInningInputsFilled.length ==> ",allFirstInningInputsFilled.length)
              console.log("allSecondInningInputsFilled.length ==> ",allSecondInningInputsFilled.length)
              setErrMsg("For Advanced Input: Please either fill all values or leave all empty. Partial input is not accepted.")  
              return
            }else{
              setErrMsg("")
        }
    
        // Sum calculations for runs and wickets
        const sumFirstInningWickets = parseInt(batTeam.firstTeamWicketPP) + parseInt(batTeam.firstTeamWicketMO) + parseInt(batTeam.firstTeamWicketDO);
        const sumSecondInningWickets = parseInt(batTeam.secondTeamWicketPP) + parseInt(batTeam.secondTeamWicketMO) + parseInt(batTeam.secondTeamWicketDO);
        const sumFirstInningRuns = parseInt(batTeam.firstTeamRunsPP) + parseInt(batTeam.firstTeamRunsMO) + parseInt(batTeam.firstTeamRunsDO);
        const sumSecondInningRuns = parseInt(batTeam.secondTeamRunsPP) + parseInt(batTeam.secondTeamRunsMO) + parseInt(batTeam.secondTeamRunsDO);
    
        // Validate wickets and runs
        if (sumFirstInningWickets > 10 || sumSecondInningWickets > 10) {
          setErrMsg('Total wickets in any inning cannot exceed 10.');
          return;
        }
        if (sumFirstInningRuns > 450 || sumSecondInningRuns > 450) {
          setErrMsg('Total runs in any inning cannot exceed 450.');
          return;
        }
    
        // const teamSelected = JSON.parse(localStorage.getItem('playerList')) 
        // const teamsData = Object.entries(teamSelected).map(([key, playerIds]) => {
        //   const [teamId, teamName] = key.split(' - ');
        //   const playerIdArr = playerIds.map(player => player.player_details.player_id)
        //   return { team_id: teamId, team_name: teamName, player_ids: playerIdArr };
        // })
        const teamsData = Object.entries(teamSelections).map(([key, playerIds]) => {
          const [teamId, teamName] = key.split(' - ');
          return { team_id: teamId, team_name: teamName, player_ids: playerIds };
        });
        console.log("TeamsData",teamsData)


    
        const team1_id = batTeam.firstTeam === activeMatch.team1_name ? activeMatch.team1_id : activeMatch.team2_id;
        const team2_id = batTeam.secondTeam === activeMatch.team1_name ? activeMatch.team1_id : activeMatch.team2_id;
        const team1_name = batTeam.firstTeam === activeMatch.team1_name ? activeMatch.team1_name : activeMatch.team2_name;
        const team2_name = batTeam.secondTeam === activeMatch.team1_name ? activeMatch.team1_name : activeMatch.team2_name;
    
        const phaseScore = {
          first_batting_score: (parseInt(batTeam.firstTeamRunsPP, 10) || 0) +
                                (parseInt(batTeam.firstTeamRunsMO, 10) || 0) +
                                (parseInt(batTeam.firstTeamRunsDO, 10) || 0),
          first_batting_wicket_lost: (parseInt(batTeam.firstTeamWicketPP, 10) || 0) +
                                     (parseInt(batTeam.firstTeamWicketMO, 10) || 0) +
                                     (parseInt(batTeam.firstTeamWicketDO, 10) || 0),
          second_batting_score: (parseInt(batTeam.secondTeamRunsPP, 10) || 0) +
                                (parseInt(batTeam.secondTeamRunsMO, 10) || 0) +
                                (parseInt(batTeam.secondTeamRunsDO, 10) || 0),
          second_batting_wicket_lost: (parseInt(batTeam.secondTeamWicketPP, 10) || 0) +
                                      (parseInt(batTeam.secondTeamWicketMO, 10) || 0) +
                                      (parseInt(batTeam.secondTeamWicketDO, 10) || 0),
          runs_scored_powerplay_first_inning: parseInt(batTeam.firstTeamRunsPP, 10) || 0,
          runs_scored_middle_first_inning: parseInt(batTeam.firstTeamRunsMO, 10) || 0,
          runs_scored_death_first_inning: parseInt(batTeam.firstTeamRunsDO, 10) || 0,
          wickets_taken_powerplay_first_inning: parseInt(batTeam.firstTeamWicketPP, 10) || 0,
          wickets_taken_middle_first_inning: parseInt(batTeam.firstTeamWicketMO, 10) || 0,
          wickets_taken_death_first_inning: parseInt(batTeam.firstTeamWicketDO, 10) || 0,
          runs_scored_powerplay_second_inning: parseInt(batTeam.secondTeamRunsPP, 10) || 0,
          runs_scored_middle_second_inning: parseInt(batTeam.secondTeamRunsMO, 10) || 0,
          runs_scored_death_second_inning: parseInt(batTeam.secondTeamRunsDO, 10) || 0,
          wickets_taken_powerplay_second_inning: parseInt(batTeam.secondTeamWicketPP, 10) || 0,
          wickets_taken_middle_second_inning: parseInt(batTeam.secondTeamWicketMO, 10) || 0,
          wickets_taken_death_second_inning: parseInt(batTeam.secondTeamWicketDO, 10) || 0
        }
    
        // Check if any values are null
        const hasNullValues = Object.values(phaseScore).some(value => value === null);
    
        const postData = {
          match_id: activeMatch.match_id,
          first_inning_cluster: `${batTeam.firstTeamExpScore}__${batTeam.firstTeamExpWktFallen}`,
          second_inning_cluster: `${batTeam.secondTeamExpScore}__${batTeam.secondTeamExpWktFallen}`,
          team1_name: team1_name,
          team2_name: team2_name,
          team1_id: team1_id,
          team2_id: team2_id,
          teams: teamsData,
          phase_score: hasNullValues ? {} : phaseScore
        }
        setFetchLoading(true)
        
        fetch(`${process.env.REACT_APP_API_BASE_URL}/get_team_from_score/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('idToken')}`
        },
          body: JSON.stringify(postData)
        })
          .then(response => response.json())
          .then(data => {
            console.log('Success:', data.team_details);
            setFetchLoading(false); // Set loading to false on successful completion
            setApproach1TeamsData(data.team_details);
            toggleApproach1Teams(true); // Toggle the display of Approach1Teams on successful API call
            trackAnalyticsEvent("Team Build", "Fetched Teams",1);
            
          })
          .catch(error => {
            console.error('Error:', error);
            setFetchLoading(false); // Set loading to false on failure
          });
          
      }

      const handleLangChange = (lang) =>{
        setLanguage(lang)
      }
      

    return (
        <div className="approachInput">
            
            {loadingOverlay}
            <div className="insights-top-d">
                        <span className="insights-text-d">Choose Approach</span>
                        <div className="insights-lang-block-d">
                            <span className={language==="English" ?"insights-lang-eng-d insight-lang-bg-d": "insights-lang-eng"} onClick={()=>handleLangChange('English')}>Eng</span>
                            <span className={language==="Hindi" ?"insights-lang-hindi-d insight-lang-bg-d":"insights-lang-hindi"} onClick={()=>handleLangChange('Hindi')}>हिंदी</span>
                        </div>
            </div>
            <form className="approach-form">
                <div className="top-content">
                    <label className="card">
                        <input
                            type="radio"
                            name="approach"
                            value="matchScore"
                            checked={selectedOption === 'matchScore'}
                            onChange={handleRadioChange}
                        />
                        {language === "English" ? "Based on Match Score" : "मैच स्कोर के आधार पर"}
                    </label>
                    <p>{descriptions.matchScore[language]}</p>
                    </div>
                      <div className="top-content">
                    <label className="card" style={{ opacity: 0.5 }}> {/* Opacity for additional greying out */}
                      
                    <input
                        type="radio"
                        name="approach"
                        value="playerPosition"
                        checked={selectedOption === 'playerPosition'}
                        onChange={handleRadioChange}
                        disabled={true} // This disables the radio button
                    />
                    {language === "English" ? "Based on Player Position" : "खिलाड़ी की स्थिति के आधार पर"}
                </label>
                <p> Soon launching!</p>
                {/* <p>{descriptions.playerPosition ? descriptions.playerPosition[language] : "Unavailable"}</p> */}
            </div>
            </form>

            {selectedOption === 'matchScore' && (
                <div className="appr-one-best-guess-wrapper">
                    <p className="select-players-toptext">Best Guess</p>
                    <p className="appr-sub-text">Use the cheatsheet to guess the score</p>
                    <p>
                    {errMsg &&
                        <div className="select-player-error">
                            <img src="/images/mobile/alert-icon.svg" alt="" />
                            <span>{errMsg}</span>
                        </div>
                        }
                    </p>
                   
                    <GuessCheatSheetM 
                                    teamOne={firstTeam}
                                    teamTwo={secondTeam} 
                                    activeTeam={activeTeam}
                                    handleTeamChange={handleTeamChange}
                                    batTeam={batTeam}
                                    handleInputChanges={handleInputChanges}
                    />
                     
                </div>
            )}
            
    
            <div style={{ position: 'fixed', bottom: '10px', left: '50%' }}>
                
                    <div className="approach-input-button-container">
                        <button className="back-to-insights-button" onClick={onBackClick}>                        
                            <ArrowBackIcon style={{ marginRight: '8px' }} /> Back
                        </button>
                        <button className="fetch-teams-button" onClick={fetchTeams}>
                        {/* <button className="fetch-teams-button" > */}
                        
                            Suggest Teams
                        </button>
                    </div>
                
            </div>
            <FeedbackButton/>
        </div>
    );
}
export default ApproachInput;