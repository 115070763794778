import RegisterPageComp from '../../components/Register/RegisterPageComp'
import './registerpagemobile.css'
import { useNavigate } from 'react-router-dom'

const RegisterPageMobile = () => {
  const navigate = useNavigate()
  const handleRegistrationSuccessM = () => {
    navigate('/login');
  };
  

  return (
    <div className="register-container">
        <div className="register-header">
          <div>
          <img src="/images/mobile/arrow-left.svg" alt=""
              className="match-selected-arrow"
              onClick={() => navigate(-1)} 
          />
          </div>
          <div>
          <img src="/images/logoname1.svg" alt="" 
              className="reg-logo-name-header"/>
          </div>
        </div>
        <RegisterPageComp viewPort="mobile" onRegistrationSuccess={handleRegistrationSuccessM}/>
    </div>
  )
}

export default RegisterPageMobile