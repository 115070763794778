import React, { createContext, useContext,  useState } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [authState, setAuthState] = useState({
        isAuthenticated: localStorage.getItem('isAuthenticated') === true,
        user: localStorage.getItem('user') || null,
        accessToken: localStorage.getItem('accessToken') || null,
        refreshToken: localStorage.getItem('refreshToken') || null,
        idToken: localStorage.getItem('idToken') || null
    });

    const setAuthInfo = ({ user, accessToken, refreshToken, idToken }) => {
        localStorage.setItem('isAuthenticated', true);
        localStorage.setItem('user', user);
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('idToken', idToken);
        setAuthState({
            isAuthenticated: true,
            user,
            accessToken,
            refreshToken,
            idToken
        });
    };

    const logout = () => {
        localStorage.clear();
        setAuthState({
            isAuthenticated: false,
            user: null,
            accessToken: null,
            refreshToken: null,
            idToken: null
        });
    };

    return (
        <AuthContext.Provider value={{ authState, setAuthInfo, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);