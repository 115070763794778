import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; 
import './approach1teams.css';
import { useNavigate } from 'react-router-dom'; 
import TeamSavedModal from '../Home/Common/TeamSavedModal';
import Lottie from "lottie-react";
import ballSpinner from '../../lotties/ball-loading.json'
import ErrorModal from '../Home/Common/ErrorModal';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import GenerateCombinationsModal from '../Home/Common/GenerateCombinationsModal';
import useAnalyticsEvent from '../../components/Home/Common/UseAnalyticsEvent';
import FeedbackButton from '../../components/Home/Common/FeebackButton';



const tabPanelStyle = {
    overflowY: 'hidden', 
    '&::-webkit-scrollbar': {
        width: '3px',
        cursor: 'pointer',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#025A4E',
        borderRadius: '2px',
    },
};



function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            style={tabPanelStyle}
            {...other}
        >
            {value === index && (
                <div>
                    {children}
                </div>
            )}
        </div>
    );
}

function formatPlayerType(type) {
    const typeMap = {
        first_opening_batsman: "Opening Batsman",
        second_opening_batsman: "Opening Batsman",
        first_middle_bowler: "Middle Over Bowler",
        second_middle_bowler: "Middle Over Bowler",
        first_middle_batsman: "Middle Order Batsman",
        second_middle_batsman: "Middle Order Batsman",
        first_low_batsman: "Lower Middle Order Batsman",
        second_low_batsman: "Lower Middle Order Batsman",
        first_powerplay_bowler: "Powerplay Bowler",
        second_powerplay_bowler: "Powerplay Bowler",
        first_death_bowler: "Death Over Bowler",
        second_death_bowler: "Death Over Bowler"
    };

    return typeMap[type] || type.replace(/_/g, ' ')
        .replace(/(first|second) /, '')
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

function Approach1Teams({ onBackClick, teamDetails_response }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    
    const [value, setValue] = useState(0);
    const [selectedRole, setSelectedRole] = useState({});
    const [teamStyles, setTeamStyles] = useState({});
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const input_request = teamDetails_response.input_request
    const teamDetails = teamDetails_response.team_details
    // const [chartData, setChartData] = useState([]);
    const insightDetails = teamDetails_response.insight_data;
    const [showGenCombModal, setShowGenCombModal] = useState(false);
    const trackAnalyticsEvent = useAnalyticsEvent();

    const {
        match_id,
        first_batting_team_name: first_batting_team,
        second_batting_team_name: second_batting_team,
        first_batting_team_id,
        second_batting_team_id
    } = teamDetails_response.input_request;
    const teamDetailsGen = teamDetails_response.team_details[value];


    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("An unexpected error occurred");

    const navigate = useNavigate();

    const handleGenerateTeamsOpenModal = () => {
        setShowGenCombModal(true);
    };

    const handleCloseModal = () => {
        setShowGenCombModal(false);
    };

    const handleRoleSelect = (playerId, role) => {
        console.log(`Attempting to set role '${role}' for player ID '${playerId}' in team index ${value}`);
    
        setSelectedRole(prevRoles => {
            return prevRoles.map((teamRoles, index) => {
                if (index === value) { // Only update roles for the currently selected team
                    console.log(`Updating team index ${index} (active team)`);
                    const newTeamRoles = { ...teamRoles };
                    console.log(`Current roles before update:`, newTeamRoles);
    
                    if (newTeamRoles[playerId] === role) {
                        console.log(`Role '${role}' is already set for player ID '${playerId}', toggling off.`);
                        delete newTeamRoles[playerId]; // Toggle the role off if it's already set
                    } else {
                        // Check if any player already has this role and remove it
                        Object.keys(newTeamRoles).forEach(key => {
                            if (newTeamRoles[key] === role) {
                                console.log(`Removing role '${role}' from player ID '${key}' to reassign`);
                                delete newTeamRoles[key]; // Remove the role from other players
                            }
                        });
                        newTeamRoles[playerId] = role; // Set the role for the selected player
                        console.log(`Role '${role}' assigned to player ID '${playerId}'`);
                    }
                    
                    console.log(`New roles after update:`, newTeamRoles);
                    return newTeamRoles;
                }
                // Log that this team's roles are not being updated
                console.log(`Skipping update for team index ${index}, not the active team`);
                return teamRoles;
            });
        });
    };
    
    
    
    
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        const uniqueTeams = new Set(teamDetails.flat().map(type => type.player_details.map(player => player.team_name)).flat());
        const teamArray = Array.from(uniqueTeams);
        setTeamStyles({
            [teamArray[0]]: 'teamStyleBlack',
            [teamArray[1]]: 'teamStyleWhite',
        });
    }, [teamDetails]);

    // useEffect(() => {
    //     const data = insightDetails.map((item, index) => ({
    //         name: `Scenario ${index + 1}`,
    //         firstInningsRunsPowerplay: item.runs_scored_powerplay_first_inning,
    //         secondInningsRunsPowerplay: item.runs_scored_powerplay_second_inning,
    //         firstInningsRunsMiddle: item.runs_scored_middle_first_inning,
    //         secondInningsRunsMiddle: item.runs_scored_middle_second_inning,
    //         firstInningsRunsDeath: item.runs_scored_death_first_inning,
    //         secondInningsRunsDeath: item.runs_scored_death_second_inning,
    //         firstInningsWicketsPowerplay: item.wickets_taken_powerplay_first_inning,
    //         secondInningsWicketsPowerplay: item.wickets_taken_powerplay_second_inning,
    //         firstInningsWicketsMiddle: item.wickets_taken_middle_first_inning,
    //         secondInningsWicketsMiddle: item.wickets_taken_middle_second_inning,
    //         firstInningsWicketsDeath: item.wickets_taken_death_first_inning,
    //         secondInningsWicketsDeath: item.wickets_taken_death_second_inning,
    //     }));
    //     setChartData(data);
    // }, [insightDetails]);

    const handlePlayerSelect = (playerId) => {
        setSelectedPlayer(prev => ({
            ...prev,
            [value]: playerId  // Ensuring a new object is created
        }));
    };    
    

    const loadingOverlay = isLoading ? (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000
        }}>
          <Lottie animationData={ballSpinner} loop={true} style={{ width: '50px', height: '50px' }} />
        </div>
    ) : null;
    
    
    const handleSaveTeam = async () => {
        if (!teamDetails || !teamDetails[value]) {
            console.error("No team data available for the current tab.");
            return;
        }
    
        const currentTeamDetails = teamDetails[value].map(playerType => {
            console.log('inside handle save team.....')
            if (playerType.player_details.length === 2) {
                // Filter to include only the selected player when there are exactly two players
                const selected = playerType.player_details.find(player => player.player_id === selectedPlayer?.[value]);
                if (selected) {
                    const captainFlag = selectedRole[value][selected.player_id];
                    
                    return [{
                        player_id: selected.player_id,
                        player_name: selected.player_name,
                        team_name: selected.team_name,
                        type: selected.type,
                        captain_flag: captainFlag || null  // Ensure it uses the scoped `selected`
                    }];
                }
                return []; // Return an empty array if no player is selected
            } else {
                // Return all player details if there are not exactly two players
                return playerType.player_details.map(player => {
                    const captainFlag = selectedRole[value][player.player_id];
                    
                    return {
                        player_id: player.player_id,
                        player_name: player.player_name,
                        team_name: player.team_name,
                        type: player.type,
                        captain_flag: captainFlag || null  // Now correctly scoped inside the map
                    };
                });
            }
        }).flat();

            // Validation for Captain and Vice Captain
        const currentTeamRoles = selectedRole[value];
        const hasCaptain = Object.values(currentTeamRoles).includes('C');
        const hasViceCaptain = Object.values(currentTeamRoles).includes('VC');
    
        if (!hasCaptain || !hasViceCaptain) {
            setErrorMessage("Select a Captain and a Vice Captain.");
            setErrorModalOpen(true);
            return; // Stop execution if validation fails
        }
        console.log(currentTeamDetails)
        const teamInput = {
            match_id: input_request.match_id,
            approach_number: 1, 
            first_batting_team_id: input_request.first_batting_team_id,
            second_batting_team_id: input_request.second_batting_team_id,
            first_batting_team_name: input_request.first_batting_team_name,
            second_batting_team_name: input_request.second_batting_team_name,
            team_details: currentTeamDetails,
            insights: insights,
            chartData: chartData
        };
        const token = localStorage.getItem('idToken');
        if (!token) {
            console.error('No token found, redirecting to login.');
            navigate('/login');
            return;
        }
        setIsLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/save-single-team/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(teamInput)
            });
    
            const result = await response.json();
            if (response.ok) {
                setIsLoading(false);
                setIsModalOpen(true);
                console.log("Team saved successfully:", result);
                trackAnalyticsEvent("Team Build", "Team Saved",1);
            } else if (response.status === 409) {
                console.log("409 Conflict - attempting to open error modal");
                setErrorMessage("Team configuration already exists."); // Set error message for the modal
                setIsLoading(false);
                setErrorModalOpen(true); // Optionally, open modal to show the error
            } else {
                throw new Error(result.detail || "An error occurred while saving the team");
            }
        } catch (error) {
            console.error("API call failed:", error);
            setErrorMessage("Unexpected issue! We couldn't save your team. Please try again. If the problem persists, attempt again later."); 
            setIsLoading(false);
            setErrorModalOpen(true); // Show error in modal
            
        }
    

        console.log(currentTeamDetails);
        console.log("Selected Player State:", selectedPlayer);

    };
   
    

    useEffect(() => {
        if (teamDetails && teamDetails.length > 0) {
            if (teamDetails && teamDetails.length > 0) {
                // Initialize with an object for each team containing empty roles
                setSelectedRole(new Array(teamDetails.length).fill({}));
                const initialSelectedPlayers = new Array(teamDetails.length).fill(null);
                setSelectedPlayer(initialSelectedPlayers);
            }
            
            // const initialSelectedPlayers = {};
            // teamDetails.forEach((_, idx) => {
            //     initialSelectedPlayers[idx] = null;  // Initializes each tab index to null
            // });
            // setSelectedPlayer(initialSelectedPlayers);

            // const initialRoles = {};
            // teamDetails.forEach((_, idx) => {
            //     initialRoles[idx] = {};  // Initialize an empty object for each team
            // });
            // setSelectedRole(initialRoles);
        }
        
    }, [teamDetails]); 

    const getInsights = (index) => {
        const item = insightDetails[index];
        return item.insights
    }

    const getChartData = (index) => {
        const item = insightDetails[index];
        return [
            {
                name: "Powerplay",
                "First Innings Runs": item.runs_scored_powerplay_first_inning,
                "Second Innings Runs": item.runs_scored_powerplay_second_inning,
                "First Innings Wickets": item.wickets_taken_powerplay_first_inning,
                "Second Innings Wickets": item.wickets_taken_powerplay_second_inning
            },
            {
                name: "Middle Overs",
                "First Innings Runs": item.runs_scored_middle_first_inning,
                "Second Innings Runs": item.runs_scored_middle_second_inning,
                "First Innings Wickets": item.wickets_taken_middle_first_inning,
                "Second Innings Wickets": item.wickets_taken_middle_second_inning
            },
            {
                name: "Death Overs",
                "First Innings Runs": item.runs_scored_death_first_inning,
                "Second Innings Runs": item.runs_scored_death_second_inning,
                "First Innings Wickets": item.wickets_taken_death_first_inning,
                "Second Innings Wickets": item.wickets_taken_death_second_inning
            }
        ];
    };

    // Use useEffect to update chart data when the selected tab changes
    useEffect(() => {
        if (insightDetails.length > 0 && value < insightDetails.length) {
            // Now the chart will update whenever the selected tab changes
            setChartData(getChartData(value));
            setInsights(getInsights(value));
        }
    }, [value, insightDetails]);

    const [chartData, setChartData] = useState(getChartData(value));
    const [insights, setInsights] = useState(getInsights(value));


    return (
        <div className="approach-one-container-d">
            {loadingOverlay}
            <div className='approachHeadingContainer'>
                <div className='approachTeamHeading'>Team Recommendation</div>
                <div className='approachTeamSubHeading'>Choose and save your teams</div>
            </div>
            <Tabs
                value={value}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
                aria-label="team tabs"
                sx={{ fontFamily: 'Inter', borderBottom: '3px solid', borderColor: 'divider', '.MuiTabs-indicator': { backgroundColor: '#00876c', height: 4 }}}
            >
                {teamDetails.map((_, index) => (
                    <Tab label={`Team ${index + 1}`} key={index} sx={{ fontFamily: 'Inter', backgroundColor: '#ede7de', fontWeight: 600, fontSize: '12px', color: '#7a7a7a', '&.Mui-selected': { fontWeight: 'bold', color: 'black' } }} />
                ))}
            </Tabs>
          
            {teamDetails.map((team, index) => (
                value === index && (
                <div key={index}  className="approach-team-show-row">
                {/* <TabPanel value={value} index={index} key={index}> */}
                    <div className='tabPanelContainer'>
                    {team.map((playerType, ptIndex) => (
                        <React.Fragment key={ptIndex}>
                            <div className="playerSerialAndCard">
                                <span className='playerSerialNumber'>{ptIndex + 1}</span> 
                                <div className="player-card">
                                    {playerType.player_details.length === 2 ? (
                                        <>
                                        <div className="cardContent">
                                            {playerType.player_details.map((player, playerIndex) => (
                                                <React.Fragment key={player.player_id}>
                                                    
                                                        <div className='playerDetailContainer'>
                                                            <Radio
                                                                checked={selectedPlayer?.[value] === player.player_id}
                                                                onChange={() => handlePlayerSelect(player.player_id)}
                                                                value={player.player_id}
                                                                name={`playerRadio-${value}`}
                                                                size="small"
                                                                style={{ padding: '0 5px 0 0' }}
                                                            />
                                                            <div className="eachPlayerList-wrapper">
                                                                <div className="eachPlayerList">
                                                                    <span className='approach-playerName'>{player.player_name}</span>
                                                                    <span className={`teamName ${teamStyles[player.team_name]}`}>{player.team_name}</span>
                                                                    <img src={player.type.includes('bat') ? "/images/baticon.svg" : "/images/bowler.svg"} alt={player.type.includes('bat') ? "Bat Icon" : "Bowler Icon"} style={{ marginRight: '8px' }} />
                                                                </div>
                                                                <div>
                                                                    <span className='playerType'>{formatPlayerType(player.type)}</span>
                                                                </div>
                                                            </div>    
                                                        </div>
                                                        {playerIndex < playerType.player_details.length - 1 && (
                                                            <div className="player-separator"></div>
                                                        )}
                                                    
                                                </React.Fragment>
                                            ))}
                                            </div>
                                            {playerType.captain_flag && (
                                                <div className="captainContainer">
                                                    {['C', 'VC'].map((role) => {
                                                        // Ensure the player ID from the selectedPlayer array for the current team is used
                                                        const currentPlayerId = selectedPlayer?.[value];
                                                        const isSelected = selectedRole?.[value]?.[currentPlayerId] === role;
                                                
                                                        return (
                                                            <div key={role}
                                                                className={`role-d-circle ${isSelected ? 'selected' : ''}`}
                                                                onClick={() => handleRoleSelect(currentPlayerId, role)}>
                                                                {role}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            
                                            )}
                                        </>
                                    ) : (
                                        playerType.player_details.map((player, playerIndex) => (
                                            <React.Fragment key={player.player_id}>
                                                <div className="cardContent">
                                                    <div className='MyTeamsplayerDetailContainer'>
                                                        <div className="eachPlayerList-wrapper">
                                                            <div className="eachPlayerList">
                                                                <span className='approach-playerName'>{player.player_name}</span>
                                                                <span className={`teamName ${teamStyles[player.team_name]}`}>{player.team_name}</span>
                                                                <img src={player.type.includes('bat') ? "/images/baticon.svg" : "/images/bowler.svg"} alt={player.type.includes('bat') ? "Bat Icon" : "Bowler Icon"} style={{ marginRight: '8px' }} />
                                                            </div>
                                                            <div>
                                                                <span className='playerType'>{formatPlayerType(player.type)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {playerIndex < playerType.player_details.length - 1 && (
                                                        <div className="player-separator"></div>
                                                    )}
                                                </div>
                                                {playerType.captain_flag && (
                                                    <div className="captainContainer">
                                                        {['C', 'VC'].map((role) => (
                                                            <div key={role}
                                                                className={`role-d-circle ${selectedRole[value] && selectedRole[value][player.player_id] === role ? 'selected' : ''}`}
                                                                onClick={() => handleRoleSelect(player.player_id, role)}>
                                                                {role}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        ))
                                    )}
                                </div>

                            </div>
                        </React.Fragment>
                    ))}

                    </div>
                {/* </TabPanel> */}
                    <div className='ApproachInsightAndFirstBattingContainer'>
                        <div className = 'ApproachInsightAndChartContainer'>
                            <div className='ApproachInsightContainer' >  
                                <div className='ApproachInsightHeading'>  Team Scenario </div>                    
                                <p>{insightDetails[index]['insights']}</p>
                                
                            </div>
                            <div className='ApproachChartContainer'>
                                {/* Runs Scored Chart */}
                                
                                <ResponsiveContainer width="100%" height={200} >
                                    <BarChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        {/* <Legend /> */}
                                        <Bar dataKey="First Innings Runs" fill="#00876c" name="Runs 1st Innings" />
                                        <Bar dataKey="Second Innings Runs" fill="#7ead9d" name="Runs 2nd Innings" />
                                    </BarChart>
                                </ResponsiveContainer>
                                

                                
                                <ResponsiveContainer width="100%" height={200}>
                                    <BarChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        {/* <Legend /> */}
                                        <Bar dataKey="First Innings Wickets" fill="#00876c" name="Wickets 1st Innings" />
                                        <Bar dataKey="Second Innings Wickets" fill="#7ead9d" name="Wickets 2nd Innings" />
                                    </BarChart>
                                </ResponsiveContainer>
                                
                            </div>

                        </div>
                        <div className='firstBattingSecondBattingContainer'>
                            <div className="team-recom-team-names-d">
                                <span>First Batting - <span className="teamNametxt-d teamStyleBlack">{input_request.first_batting_team_name}</span></span>
                                <span>Second Batting - <span className="teamNametxt-d teamStyleWhite">{input_request.second_batting_team_name}</span></span>
                            </div>
                            <p>You can always change the batting order by going back to last page. If the provided scenarios do not meet your needs, you can create a custom scenario by using the "Advanced Input" section on the previous screen.</p>

                        </div>
                    </div>
                    
                </div>
                )
            ))}
           
            <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)' }}>
                <div className="approach-input-button-container">
                    <button className="back-to-insights-button" onClick={onBackClick}>
                        <ArrowBackIcon style={{ marginRight: '8px' }} /> Back
                    </button>
                    <button className="blackButton-d" onClick={handleGenerateTeamsOpenModal}>
                        Generate Team Combinations
                    </button>
                                      
                    <button className="fetch-teams-button" onClick={handleSaveTeam}>
                        Save Team
                    </button>
                </div>
            </div>
            <TeamSavedModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />

            {errorModalOpen && (
                <ErrorModal 
                    title = 'Error Saving Team'
                    close={() => setErrorModalOpen(false)} 
                    message={errorMessage}
                />
            )}
            <GenerateCombinationsModal 
                isOpen={showGenCombModal} 
                onClose={handleCloseModal} 
                numberOfTeams={7}  // Adjust as necessary
                teamDetails={teamDetails_response}
                matchId={match_id}
                firstBattingTeam={first_batting_team}
                secondBattingTeam={second_batting_team}
                firstBattingTeamId={first_batting_team_id}
                secondBattingTeamId={second_batting_team_id}
                currentTeamDetails={teamDetailsGen}
                chartData={chartData}
                insights = {insights}
            />  
            <FeedbackButton/>
        </div>
    );

}

export default Approach1Teams;