import React, { useState, useEffect } from 'react';
import { IconButton } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import './rightpanel.css';
// import PlayerModal from './PlayerModal';
import PlayerCardPage from '../../components/Home/Common/PlayerCardPage'
import { Box } from '@mui/material';
// import RiseLoader from 'react-spinners/RiseLoader';
import ballSpinner from '../../lotties/ball-loading.json'
import Lottie from "lottie-react";

const getIcon = (role) => {
    switch (role) {
        case "top-order batter": return "/images/baticon.svg";
        case "middle-order batter": return "/images/baticon.svg";
        case "opening batter": return "/images/baticon.svg";
        case "batter": return "/images/baticon.svg";
        case "Allrounder - Pace":
        case "Allrounder - Spin": return "/images/all-rounder-icon.svg";
        case "allrounder": return "/images/all-rounder-icon.svg";
        case "bowling allrounder": return "/images/all-rounder-icon.svg";
        case "batting allrounder": return "/images/all-rounder-icon.svg";
        case "Bowler - Pace":
        case "Bowler - Spin": return "/images/bowler.svg";
        case "bowler": return "/images/bowler.svg";
        case "wicketkeeper": return "/images/wicketkeeper.svg";
        case "wicketkeeper batter": return "/images/wicketkeeper.svg";
        default: return "/images/playericon.svg";
    }
};



const RightPanel = ({ onSelectionUpdate, matchId, matchSquad = null, squadIsLoading, team1_full_name, team2_full_name,team1_name, team2_name, team1_id, team2_id, }) => {
    const [selectedTeamPlayers, setSelectedTeamPlayers] = useState({});
    const [teamOneActive, setTeamOneActive] = useState(true);
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [selectedPlayers, setSelectedPlayers] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const teamOne = `${team1_id} - ${team1_name}`;
    const teamTwo = `${team2_id} - ${team2_name}`;
    const [isClosing, setIsClosing] = useState(false);

    
    console.log("Initial Props:", { team1_full_name, team2_full_name });


    const loadingOverlay = (
        <div style={{
          position: 'fixed', // Overlay covers the entire screen
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
          display: 'flex',
          justifyContent: 'right',
          alignItems: 'center',
          zIndex: 1000, // High z-index to cover everything else
          paddingRight: '200px'
        }}>
          
          {/* <Lottie animationData={ballSpinner} loop={true} style={{ width: '50px', height: '50px' }} /> */}
        </div>
      );

      useEffect(() => {
        let currentMatchSquad = typeof matchSquad === 'string' ? JSON.parse(matchSquad) : matchSquad;
        if (!currentMatchSquad || !currentMatchSquad.teams) {
            console.error('matchSquad is not valid:', currentMatchSquad);
            setIsLoading(true);
            return;
        }

        const extractPlayers = (teamData) => {
            // Check if teamData is null or undefined, and return an empty array if it is
            if (!teamData) {
                console.log('No team data provided');
                return [];
            }
        
            // Proceed with extracting player IDs where players played the last game
            return Object.entries(teamData)
                .filter(([_, playerDetails]) => playerDetails.player_details && playerDetails.player_details.played_last_game === "true")
                .map(([playerId]) => playerId);
        };
        

        const teamDataOne = currentMatchSquad.teams[teamOne];
        const teamDataTwo = currentMatchSquad.teams[teamTwo];
        setSelectedPlayers({
            [teamOne]: extractPlayers(teamDataOne),
            [teamTwo]: extractPlayers(teamDataTwo)
        });

        setIsLoading(false);
    }, [matchSquad, teamOne, teamTwo]);

    useEffect(() => {
        if (onSelectionUpdate) {
            onSelectionUpdate(selectedPlayers);
        }
    }, [selectedPlayers]);

    //   useEffect(() => {
    //     if (!matchSquad || typeof matchSquad !== 'object' || !matchSquad.teams) {
    //         console.error('Invalid matchSquad data');
    //         setIsLoading(true);
    //         return;
    //     }
    
    //     const initializeSelections = (teamKey, teamData) => {
    //         return Object.entries(teamData)
    //             .filter(([_, playerDetails]) => playerDetails.player_details && playerDetails.player_details.played_last_game === "true")
    //             .map(([playerId, _]) => playerId);
    //     };
    
    //     const teamDataOne = matchSquad.teams[teamOne];
    //     const teamDataTwo = matchSquad.teams[teamTwo];
    //     const initialPlayersTeamOne = initializeSelections(teamOne, teamDataOne);
    //     const initialPlayersTeamTwo = initializeSelections(teamTwo, teamDataTwo);
    
    //     setSelectedPlayers({
    //         [teamOne]: initialPlayersTeamOne,
    //         [teamTwo]: initialPlayersTeamTwo
    //     });
    
    //     setIsLoading(false);
    // }, [matchSquad]); // This useEffect should only run once when matchSquad is set
    

      useEffect(() => {
        console.log("UseEffect running with:", { teamOne, teamTwo, matchSquad });
    
        if (typeof matchSquad === 'string') {
            try {
                matchSquad = JSON.parse(matchSquad);
            } catch (error) {
                console.error('Error parsing matchSquad:', error);
                setIsLoading(true);
                return;
            }
        }
    
        if (!matchSquad || !matchSquad.teams) {
            console.error('matchSquad or matchSquad.teams is undefined');
            setIsLoading(true);
            return;
        }
    
        // Function to extract player IDs who played the last game
        const extractLastGamePlayers = (teamData) => {
            return Object.entries(teamData)
                .filter(([_, playerDetails]) => playerDetails.player_details && playerDetails.player_details.played_last_game === "true")
                .map(([playerId, _]) => playerId);
        };
    
        // Update player counts and selected players for both teams
        const updateTeamData = (teamKey) => {
            const teamData = matchSquad.teams[teamKey] || {};
            const lastGamePlayers = extractLastGamePlayers(teamData);
    
            setSelectedPlayers(prevState => ({
                ...prevState,
                [teamKey]: lastGamePlayers
            }));
            if (teamKey === (teamOneActive ? teamOne : teamTwo)) {
                setSelectedTeamPlayers(teamData);
            }
            return lastGamePlayers.length;
        };
    
        // Update for both teams
        const countSelectedPlayersTeamOne = updateTeamData(teamOne);
        const countSelectedPlayersTeamTwo = updateTeamData(teamTwo);
    
        setIsLoading(false);
    // }, [teamOne, teamTwo, matchId, matchSquad, teamOneActive]);
        }, [matchId, matchSquad]);

    // useEffect(() => {
    //     const initializeTeamData = () => {
    //         if (!matchSquad || typeof matchSquad !== 'object' || !matchSquad.teams) {
    //             console.error('Invalid matchSquad data');
    //             setIsLoading(true);
    //             return;
    //         }
    
    //         const extractInitialPlayers = (teamData) => {
    //             return Object.entries(teamData)
    //                 .filter(([_, details]) => details.player_details && details.player_details.played_last_game === "true")
    //                 .map(([playerId]) => playerId);
    //         };
    
    //         setSelectedPlayers({
    //             [teamOne]: extractInitialPlayers(matchSquad.teams[teamOne] || {}),
    //             [teamTwo]: extractInitialPlayers(matchSquad.teams[teamTwo] || {})
    //         });
    
    //         setIsLoading(false);
    //     };
    
    //     initializeTeamData();
    // }, [matchSquad, teamOne, teamTwo]);
    
    
    
    // Set the Team Selection
    useEffect(() => {
        console.log('Selected players:', selectedPlayers);
        if (onSelectionUpdate) {
            onSelectionUpdate(selectedPlayers);
        }
    }, [selectedPlayers, onSelectionUpdate]);

    const handleTeamSwitch = (team) => {
        console.log('Type of matchSquad:', typeof matchSquad);
        let parsedMatchSquad;
    
        if (typeof matchSquad === 'string') {
            try {
                parsedMatchSquad = JSON.parse(matchSquad);
            } catch (error) {
                console.error('Failed to parse matchSquad:', error);
                return;  // Exit the function if parsing fails
            }
        } else {
            parsedMatchSquad = matchSquad;
        }
    
        if (!parsedMatchSquad || !parsedMatchSquad.teams) {
            console.error('Parsed matchSquad is not an object or has no teams property');
            return;  // Further safety check
        }
    
        setTeamOneActive(team === teamOne);
        const teamData = parsedMatchSquad.teams[team];
        if (teamData) {
            setSelectedTeamPlayers(teamData);
        } else {
            console.log('No data found for team:', team);
        }
    };  

    const showPlayerCard = (playerDetails) => {
        setSelectedPlayer(playerDetails);
    };

    const closePlayerCard = () => {
        setIsClosing(true);
        setTimeout(() => {
            setSelectedPlayer(null);
            setIsClosing(false);
        }, 500); // Match the duration of the slide-down animation
    };

    const togglePlayerSelection = (playerId) => {
        const activeTeamKey = teamOneActive ? teamOne : teamTwo;
        setSelectedPlayers(prevState => ({
            ...prevState,
            [activeTeamKey]: prevState[activeTeamKey].includes(playerId)
                ? prevState[activeTeamKey].filter(id => id !== playerId)
                : [...prevState[activeTeamKey], playerId]
        }));
    };

    const isSelected = (playerId) => {
        const teamKey = teamOneActive ? teamOne : teamTwo;
        return selectedPlayers[teamKey] && selectedPlayers[teamKey].includes(playerId);
    };


    if (squadIsLoading && !isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
            {/* <RiseLoader color="#013220" size={15} /> */}
            </div>
        ); 
        }

    const countSelectedPlayersTeamOne = (selectedPlayers[teamOne] || []).length;
    const countSelectedPlayersTeamTwo = (selectedPlayers[teamTwo] || []).length;

    const formatTeamName = (name) => {
        if (!name) return '';
        if (name.length > 12) {
            return `${name.substring(0, 12)}..`;
        }
        return name;
    };

    const playersWhoPlayedLastGame = Object.entries(selectedTeamPlayers).filter(([_, playerDetails]) => playerDetails.player_details.played_last_game === "true");
    const playersWhoDidNotPlayLastGame = Object.entries(selectedTeamPlayers).filter(([_, playerDetails]) => playerDetails.player_details.played_last_game !== "true");

    return (
        <div className="right-panel">
            {squadIsLoading && loadingOverlay}    
            <h4 className="right-panel-top-text">Choose the playing 11 from both teams</h4>
            <div className="players-text-block-d">
                <img src="/images/mobile/info.svg" alt="" />
                <span>Pre-selection are based on previous match played. Click on the player to view the player card. </span>
            </div>
            <div className="load-players-container">
                <div className="load-team-name">
                    <span 
                        onClick={() => handleTeamSwitch(teamOne)} 
                        className={teamOneActive ? "player-teamname bg-team-white-d" : "player-teamname"}
                        title={team1_full_name}>
                        {formatTeamName(team1_full_name)} <span className="player-count">{countSelectedPlayersTeamOne}</span>
                    </span>
                    <span 
                        onClick={() => handleTeamSwitch(teamTwo)} 
                        className={!teamOneActive ? "player-teamname bg-team-white-d" : "player-teamname"}
                        title={team2_full_name}>
                        {formatTeamName(team2_full_name)} <span className="player-count">{countSelectedPlayersTeamTwo}</span>
                    </span>
                </div>
    
                <div className="player-box">
                    <h5>PLAYED PREVIOUS MATCH</h5>
                    <div className="load-player-list played-last-match-container">
                        {playersWhoPlayedLastGame.map(([playerId, playerDetails]) => (
                            <div key={playerId} className={`load-player ${playerDetails.player_details.avg_fantasy_points > 40 ? 'highlight-player' : ''}`}>
                                <div className="load-player-content" onClick={() => showPlayerCard(playerDetails)}>
                                    <div className="load-player-left">
                                        <img src="/images/playericon.svg" alt="Player icon" className="player-pic" />
                                        <span className="right-player-name">{playerDetails.player_details.player_name}</span>
                                        <img src={getIcon(playerDetails.player_details.player_role)} alt={`${playerDetails.player_details.player_role} icon`} className={`player-type-icon ${playerDetails.player_details.player_role === "Wicketkeeper" ? "wicketkeeper-icon-large" : ""}`} />
                                    </div>
                                </div>
                                <button onClick={(event) => {
                                    event.stopPropagation();
                                    togglePlayerSelection(playerId);
                                }} className="player-selection-icon">
                                    <img src={isSelected(playerId) ? "/images/selected.svg" : "/images/add.svg"} alt={isSelected(playerId) ? "Deselect" : "Select"} />
                                </button>
                            </div>
                        ))}
                    </div>


    
                    <h5>REST OF THE SQUAD</h5>
                    <div className="load-player-list rest-of-squad-container">
                        {playersWhoDidNotPlayLastGame.map(([playerId, playerDetails]) => (
                            <div key={playerId} className={`load-player ${playerDetails.player_details.avg_fantasy_points > 40 ? 'highlight-player' : ''}`}>
                            <div className="load-player-content" onClick={() => showPlayerCard(playerDetails)}>
                                <div className="load-player-left">
                                    <img src="/images/playericon.svg" alt="Player icon" className="player-pic" />
                                    <span className="right-player-name">{playerDetails.player_details.player_name}</span>
                                    <img src={getIcon(playerDetails.player_details.player_role)} alt={`${playerDetails.player_details.player_role} icon`} className={`player-type-icon ${playerDetails.player_details.player_role === "Wicketkeeper" ? "wicketkeeper-icon-large" : ""}`} />
                                </div>
                            </div>
                            <button onClick={(event) => {
                                event.stopPropagation();
                                togglePlayerSelection(playerId);
                            }} className="player-selection-icon">
                                <img src={isSelected(playerId) ? "/images/selected.svg" : "/images/add.svg"} alt={isSelected(playerId) ? "Deselect" : "Select"} />
                            </button>
                        </div>
                        ))}
                    </div>
                </div>
            </div>      
            {/* {selectedPlayer && <PlayerModal player={selectedPlayer} onClose={closePlayerCard} />} */}
            {selectedPlayer &&
                <div className='overlay'>
                    {/* <div className='select-player-modal-container'> */}
                    <div className={`select-player-modal-container ${isClosing ? 'slide-right' : 'slide-left'}`}>
                        <PlayerCardPage player={selectedPlayer} handleDetail={closePlayerCard} />
                    </div>
                </div>
            }
        </div>
    );
    
};

export default RightPanel;