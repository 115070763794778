import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './matchinsightpanelM.css';

function MatchInsightPanelM({ matchInsights, language }) {
  console.log("Received matchInsights:", matchInsights);
  
  const chartData = matchInsights.venue_data.map(venue => ({
    name: `${venue.team1_name} vs ${venue.team2_name}`,
    First_Batting_Score: Number(venue.first_batting_score),
    First_Batting_Wickets: venue.first_batting_wicket_lost,
    Second_Batting_Score: Number(venue.second_batting_score),
    Second_Batting_Wickets: venue.second_batting_wicket_lost,
  }));

  const htmlContent = matchInsights.ai_insights_data[language] || 'No insights available.';
  
  /*
  let phaseWiseData = [];
  phaseWiseData.sort((a, b) => a.match.localeCompare(b.match));
  matchInsights.phase_wise_data.forEach(data => {
    const matchInningKey = `${data.team1_name} vs ${data.team2_name} - ${data.batting_innings} Inning`;
    phaseWiseData.push({
      match: matchInningKey,
      powerplayRuns: data.powerplay_runs_scored,
      deathRuns: data.death_runs_scored,
      // Directly include these in the data for tooltip usage
      powerplayWickets: data.powerplay_wickets_taken,
      deathWickets: data.death_wickets_taken,
    });
  });
  console.log(phaseWiseData)
  */

  function CustomTooltip({ active, payload }) {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${payload[0].payload.name}`}</p>  
          <p className="intro">{`First Innings Score: ${payload[0].payload.First_Batting_Score} / ${payload[0].payload.First_Batting_Wickets}` }</p>            
          <p className="intro">{`Second Innings Score: ${payload[1].payload.Second_Batting_Score} / ${parseInt(payload[1].payload.Second_Batting_Wickets, 10)}`}</p>
        </div>
      );
    }
    return null;
  }

  function CustomTooltipPowerplay({ active, payload }) {
    if (active && payload && payload.length) {
      // Directly accessing the original data object for the hovered bar
      const data = payload[0].payload;
      return (
        <div className="custom-tooltip">
          <p className="label">{data.team1_name} vs {data.team2_name}</p>
          <p className="intro">{`First Inning Powerplay Score: ${data.powerplay_runs_scored_first} / ${data.powerplay_wickets_taken_first}`}</p>
          <p className="desc">{`Second Inning Powerplay Score: ${data.powerplay_runs_scored_second} / ${data.powerplay_wickets_taken_second}`}</p>
        </div>
      );
    }
    return null;
  }

  function CustomTooltipDeathOver({ active, payload }) {
    if (active && payload && payload.length) {
      // Directly accessing the original data object for the hovered bar
      const data = payload[0].payload;
      return (
        <div className="custom-tooltip">
          <p className="label">{data.team1_name} vs {data.team2_name}</p>
          <p className="intro">{`First Inning Death Score: ${data.death_runs_scored_first} / ${data.death_wickets_taken_first}`}</p>
          <p className="desc">{`Second Inning Death Score: ${data.death_runs_scored_second} / ${data.death_wickets_taken_second}`}</p>
        </div>
      );
    }
    return null;
  }

  return (
    <div className="insight-container">
        <div className="insight-chart-container">
            <p>Match Scores Chart</p>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={chartData} margin={{ top: 20, right: 50, left: 10, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                {/* <Legend /> */}
                <Bar dataKey="First_Batting_Score" fill="#00876C" />
                <Bar dataKey="Second_Batting_Score" fill="#7EAD9D" />
              </BarChart>
            </ResponsiveContainer>
        </div>
        <div className="insight-chart-container">
            <p>Powerplay Performance</p>
            <ResponsiveContainer width="100%" height={300}>
                <BarChart
                          data={matchInsights.phase_wise_data}
                          margin={{ top: 20, right: 50, left: 10, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip content={<CustomTooltipPowerplay />} />
                {/* <Legend /> */}
                <Bar dataKey="powerplay_runs_scored_first" fill="#00876C" name="First Innings Runs"  />
                <Bar dataKey="powerplay_runs_scored_second" fill="#7EAD9D" name="Second Innings Runs" />
              </BarChart>
            </ResponsiveContainer>       
      </div>
      <div className="insight-chart-container">
            <p>Death Over Performance</p>
            <ResponsiveContainer width="100%" height={300}>
                <BarChart
                          data={matchInsights.phase_wise_data}
                          margin={{ top: 20, right: 50, left: 10, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip content={<CustomTooltipDeathOver/>} />
                {/* <Legend /> */}
                <Bar dataKey="death_runs_scored_first" fill="#00876C"  name="First Innings Runs"  />
                <Bar dataKey="death_runs_scored_second" fill="#7EAD9D" name="Second Innings Runs" />
              </BarChart>
            </ResponsiveContainer>       
      </div>
      <div className="insight-text-wrapper">
          <div className="insight-content" dangerouslySetInnerHTML={{ __html: htmlContent}}></div>
      </div>

    </div>
  );
}

export default MatchInsightPanelM;