import React from 'react'
import MatchSelectedM from '../../../components/Home/Mobile/MatchSelectedM'
import MatchInsightsM from '../../../components/Home/Mobile/MatchInsightsM'
import './matchcheatsheetpage.css'
import { useNavigate } from "react-router-dom"
import FeedbackButton from '../../../components/Home/Common/FeebackButton';

const MatchCheatSheetPage = () => {
  const navigate = useNavigate()
  
  return (
    <div className="cheat-sheet-container">
      <MatchSelectedM />
      <MatchInsightsM />      
      <button className="build-team-btn"
           onClick={() => navigate('/select-players')}>
        Build fantasy team 
      </button>
      <FeedbackButton/>

    </div>
  )
}

export default MatchCheatSheetPage