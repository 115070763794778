import React from 'react';
import PropTypes from 'prop-types';
import { WarningAmber } from '@mui/icons-material'; // Import MUI icon
import './errormodal.css';

const ErrorModal = ({ title, message, close }) => {
  return (
    <div className="error-modal">
      <div className="error-modal-content">
        <div className="modal-header">
            
          <button className="modal-close-icon" onClick={close}>
            <img src="/images/close_icon_2.svg" alt="Close" />            
          </button>
          <h4>{title}</h4>
        </div>
        <WarningAmber style={{ fontSize: 40, color: 'red' }} /> {/* MUI Icon with styling */}
        <p>{message}</p>
        {/* <button className="modal-close-button" onClick={close}>Close</button> */}
      </div>
    </div>
  );
};

ErrorModal.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
};

export default ErrorModal;
