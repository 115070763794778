import React, { useState } from 'react';
import './feedbackbutton.css'; // Assuming you will create a CSS file for styling

const FeedbackButton = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [feedback, setFeedback] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isThankYouModalOpen, setIsThankYouModalOpen] = useState(false);

    const handleToggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const handleFeedbackChange = (event) => {
        setFeedback(event.target.value);
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const idToken = localStorage.getItem("idToken");

        if (!idToken) {
            alert("User is not authenticated");
            setIsSubmitting(false);
            return;
        }

        try {
            const response = await fetch(`${baseUrl}/submit-feedback`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
                body: JSON.stringify({ feedback })
            });

            if (response.ok) {
                console.log("Feedback submitted successfully.");
                setIsOpen(false);
                setFeedback(""); // Clear feedback after submission
                setIsThankYouModalOpen(true);
            } else {
                console.error("Failed to submit feedback.");
                alert("Failed to submit feedback.");
            }
        } catch (error) {
            console.error("Error submitting feedback:", error);
            alert("An error occurred while submitting feedback.");
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleThankYouClose = () => {
        setIsThankYouModalOpen(false);
    };

    return (
        <div className="feedback-container">            
            <button onClick={handleToggleOpen} className="feedback-toggle">
                <img src='/images/common/message_icon.svg' alt="Feedback" />Feedback
            </button>
            {isOpen && (
                <div className="feedback-modal-overlay">
                    <div className="feedback-form">
                        <div className="feedback-header">
                                <p>Feedback</p>
                                <img
                                    src="/images/common/grey_close_icon.svg"
                                    alt="Close"
                                    className="feedback-close-icon"
                                    onClick={handleToggleOpen}
                                />
                            </div>
                        <textarea
                            value={feedback}
                            onChange={handleFeedbackChange}
                            placeholder="Share your feedback or suggest stats, analyses, or features to add."
                        />
                        <button onClick={handleSubmit} disabled={isSubmitting}>
                            {isSubmitting ? "Sending..." : "Send"}
                        </button>
                    </div>
                </div>
            )}
            {isThankYouModalOpen && (
                <div className="thank-you-modal-overlay">
                    <div className="thank-you-modal">
                        <img
                            src="/images/common/grey_close_icon.svg"
                            alt="Close"
                            className="thank-you-close-icon"
                            onClick={handleThankYouClose}
                        />
                        <p>Thank you for your feedback! We will work on it.</p>
                        <button onClick={handleThankYouClose} className="thank-you-ok-button">
                            OK
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FeedbackButton;
