import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { marked } from 'marked';
import './chatbot.css';
import Lottie from "lottie-react";
// import ballSpinner from '../../lotties/ball-loading.json'
import ballSpinner from '../../../lotties/ball-loading.json'

const ChatBot = ({ activeMatch, isOpen, toggleChat }) => {
    const [message, setMessage] = useState('');
    const [conversations, setConversations] = useState([]);
    const [showHelp, setShowHelp] = useState(false);
    const [isChatLoading, setIsChatLoading] = useState(false);
    const navigate = useNavigate();

    const fetchConversations = async () => {
        const idToken = localStorage.getItem('idToken');
        console.log("activeMatch from ChatBot ==> ", activeMatch);
        if (!idToken) {
            console.error('No token found, redirecting to login.');
            navigate('/login');
            return;
        }
    
        try {
            setIsChatLoading(true);
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/fetch-conversation`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
                body: JSON.stringify({ match_id: activeMatch.match_id })  // Correct JSON key
            });
    
            if (response.status === 200) {
                const data = await response.json(); // Parse JSON data
                if (data.error) {
                    // Handle error case where no conversations are found
                    console.warn("API Response Error:", data.error);
                    setConversations([]); // Set conversations to an empty array
                    // alert(data.error); // Optionally display an alert or UI message
                } else {
                    // Process the conversation data
                    const updatedConversations = data.map(conv => ({
                        ...conv,
                        content: marked(conv.content) // Convert Markdown to HTML
                    }));
                    setConversations(updatedConversations);
                }
            } else {
                console.error("Failed to fetch conversations.", response.status);
            }
        } catch (error) {
            console.error("Error fetching conversations:", error);
        } finally {
            setIsChatLoading(false); // Stop loading spinner
        }
    };
    

    useEffect(() => {
        fetchConversations(); // Call the function on component mount
    }, [activeMatch]); 

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const sendMessage = async () => {
        if (message.trim() === '') return;
    
        const idToken = localStorage.getItem('idToken');
        const userDetails = JSON.parse(localStorage.getItem('userDetails'));
        const apiKey = userDetails ? userDetails.api_key : null;
    
        if (!idToken || !apiKey) {
            console.error('No token or API key found, redirecting to login.');
            navigate('/login');
            return;
        }
    
        setIsChatLoading(true);  // Start loading
    
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_LLM_URL}/chatQuestion`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,
                    'x-api-key': apiKey
                },
                body: JSON.stringify({
                    match_id: activeMatch.match_id,
                    question: message
                })
            });
    
            if (response.status === 200) {
                const filename = await response.text();
    
                // Check if filename is valid
                if (!filename) {
                    throw new Error("Invalid filename received from API");
                }
    
                console.log("filename ==> ", filename);
                let attempts = 0;
                const maxAttempts = 40;
    
                const interval = setInterval(async () => {
                    try {
                        const fileResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/fetch-file/${filename}`);
                        
                        if (fileResponse.status === 200) {
                            clearInterval(interval);
                            const data = fileResponse.data;
                            const updatedConversations = data.map(conv => ({
                                ...conv,
                                content: marked(conv.content)
                            }));
                            setConversations(updatedConversations);
                            setIsChatLoading(false);  // Stop loading after all async operations
                        }
                    } catch (error) {
                        console.error("Error fetching file:", error);
                        attempts++;
                        if (attempts >= maxAttempts) {
                            clearInterval(interval);
                            setIsChatLoading(false);
                            alert('Sorry, we are unable to process the request right now. It may be a case that it is taking too much time to fetch the data. Please try with more specific questions.');
                        }
                    }
                }, 2000);
            } else {
                console.error('Failed to fetch the filename.', response.status);
                setIsChatLoading(false);  // Stop loading here if fetch fails
            }
        } catch (error) {
            console.error('Error fetching the filename:', error.message);
            setIsChatLoading(false);  // Stop loading here on catch
        }
    
        setMessage('');  // Clear message field
    };
    
    
    


    const toggleHelp = () => {
        setShowHelp(!showHelp);
    };

    return (
        <div className={`chat-overlay ${isOpen ? 'open' : ''}`}>
            <div className="chat-header">
                    <div className="title-and-question">
                        <h2>Chat with Botz</h2>
                        <img src="/images/mobile/questioncircle.png" alt="Help" onClick={toggleHelp} />
                    </div>
                    {showHelp && (
                        <div className="help-popup">
                            <p>Botz can assist with player statistics, venue analysis, series data, head-to-head comparisons, recent performances, and fantasy game rules.</p>
                        </div>
                    )}
                    <img src="/images/mobile/close-icon.svg" alt="Close" onClick={toggleChat} />
                </div> 
            <div className="chat-window">
                
                <div className="chat-content">
                    {conversations.map((conv, index) => (
                        <div key={index} className={`message ${conv.type}`}>
                            <img src={conv.type === 'user' ? '/images/common/user_icon.svg' : '/images/logo.svg'} alt="" className="message-icon" />
                            <div className="message-box" dangerouslySetInnerHTML={{ __html: conv.content }} />
                        </div>
                    ))}
                </div>
                {isChatLoading && (
                    <div className="loading-overlay">
                        <Lottie animationData={ballSpinner} loop={true} style={{ width: '50px', height: '50px' }} />
                    </div>
                )}
                <div className="chat-input">
                    <textarea
                        className="chat-input-textarea"
                        value={message}
                        onChange={handleMessageChange}
                        placeholder="Type your message..."
                    ></textarea>
                    <img src="/images/common/send_icon.svg" alt="Send" className="send-icon" onClick={sendMessage} />
                </div>
            </div>
        </div>
    );
    
};

export default ChatBot;
