import React, { useState, useEffect, useRef } from 'react';
import './leftpanel.css';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import SubscriptionCancellation from '../Home/Common/SubscriptionCancellation';


const UserModal = ({ userDetails, activePlan, onClose }) => {
  const [showCancellationModal, setShowCancellationModal] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    localStorage.clear(); // Clear all local storage
    navigate('/login');   // Navigate to the login page
  };

  const navigateTo = (path) => {
    navigate(path);
  };

  const openCancellationModal = () => {
    setShowCancellationModal(true);
  };

  const closeCancellationModal = () => {
    setShowCancellationModal(false);
  };

  const proceedWithCancellation = () => {
    setShowCancellationModal(false);
    // close();
    if (location.pathname === '/home') {
      window.location.reload();
    } else {
      navigate('/home');
    }
  };

  const planMapping = {
    'trial-INR-Weekly': '1 WEEK TRIAL',
    '1-Month-INR-Monthly': 'Pro Monthly',
    '1-Year-INR-Yearly': 'Pro Yearly'
  };

  return (
    <div className="user-modal">
      <p className="key">Email</p>
      <p className="value">{userDetails.email}</p>
      <p className="key">Mobile</p>
      <p className="value">{userDetails.phone_number}</p>
      <p className="key">Active Plan</p>
      <p className="value">
        {planMapping[activePlan] ? <span className="trial-plan">{planMapping[activePlan]}</span> : 'No active plan'}
      </p>
      <hr className="separator" />
      <p className="link" onClick={openCancellationModal}>Cancel Subscription</p>
      
      <p className="link" onClick={() => navigateTo('/terms-and-conditions')}> Terms & Conditions </p>
      <p className="link" onClick={() => navigateTo('/privacy-policy')}> Privacy Policy </p>
      <hr className="separator" />
      <p className="logout-link" onClick={handleLogout}>Logout</p>

      {showCancellationModal && (
        <SubscriptionCancellation 
          close={closeCancellationModal} 
          proceedWithCancellation={proceedWithCancellation} 
        />
      )}
    </div>
  );
};

const LeftPanel = ({ userDetails, activePlan, setBuildTeams, setMyTeams, buildTeams, myTeams }) => {
  const [showModal, setShowModal] = useState(false);
  const modalRef = useRef(null);

  const handleMyTeamsClick = () => {
    setMyTeams(true);
    if (buildTeams) {
      setBuildTeams(!buildTeams);
    }
  };

  const handleBuildTeamsClick = () => {
    if (myTeams) {
      setMyTeams(!myTeams);
    }
    setBuildTeams(true);
  };

  const handleMoreClick = () => {
    setShowModal(!showModal);
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowModal(false);
    }
  };

  useEffect(() => {
    if (showModal) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showModal]);

  return (
    <div className="left-panel">
      <div className="left-panel-wrapper">
        <div className="logo-container">
          <img src="/images/logo.svg" alt="" className="cric-logo" />
        </div>
        <div className="NavigationIconContainer">
          {buildTeams ? (
            <img src="/images/buildTeamsGreen_icon.svg" alt="" className="icons" onClick={handleBuildTeamsClick} />
          ) : (
            <img src="/images/buildTeamsGrey_icon.svg" alt="" className="icons" onClick={handleBuildTeamsClick} />
          )}
          {myTeams ? (
            <img src="/images/myTeamsGreen_icon.svg" alt="" className="icons" onClick={handleMyTeamsClick} />
          ) : (
            <img src="/images/myTeamsGrey_icon.svg" alt="" className="icons" onClick={handleMyTeamsClick} />
          )}
        </div>
      </div>

      <div className="usericon-container">
        <img src="/images/moreicon.svg" alt="" className="icons" onClick={handleMoreClick} style={{ width: '28px', height: '28px' }}  />
        {showModal && (
          <div ref={modalRef}>
            <UserModal userDetails={userDetails} activePlan={activePlan} onClose={() => setShowModal(false)} />
          </div>
        )}
      </div>
    </div>
  );
};

export default LeftPanel;
