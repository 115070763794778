import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './loginpage.css';
import { useAuth } from '../../context/authContext';
import {ThreeDots} from 'react-loader-spinner';
import { jwtDecode } from 'jwt-decode';
import ErroModal from '../../components/Home/Common/ErrorModal'
import { useRef } from 'react';


const LoginPage = ({ viewPort, onSignUpClick  }) => {
  const { setAuthInfo } = useAuth();  
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isOtpScreen, setIsOtpScreen] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [sessionToken, setSessionToken] = useState("");
  const [requestId, setRequestId] = useState("");
  const [otp, setOtp] = useState(Array(4).fill(""));
  const [error, setError] = useState("");
  const [timer, setTimer] = useState(90); // Timer for OTP resend
  const [canResend, setCanResend] = useState(false);
  const InpRef = useRef()
  // useEffect(() => {
  //   let interval;
  //   if (timer > 0 && !canResend) {
  //     interval = setInterval(() => {
  //       setTimer(timer - 1);
  //     }, 1000);
  //   } else if (timer === 0) {
  //     clearInterval(interval);
  //     setCanResend(true);
  //   }
  //   return () => clearInterval(interval);
  // }, [timer, canResend]);

  useEffect(()=>{
    InpRef.current.focus()
  },[])

  useEffect(() => {
    checkTokenValidity();
    let interval;
    if (timer > 0 && !canResend) {
      interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(interval);
      setCanResend(true);
    }
    return () => clearInterval(interval);
  }, [timer, canResend]);

  const handleMobileChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Allows only numbers
    if (value.length <= 10) { // Ensure not more than 10 digits
      setMobileNumber(value);
    }
  };

  const handleSignUpClick = () => {
    navigate('/register');
  };

  
  const checkTokenValidity = () => {
    const token = localStorage.getItem('idToken');
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp > currentTime) {
        navigate('/home');
      }
    }
  };


  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    // Automatically move focus to next input
    if (value.length === 1 && index < 3) {
      document.getElementById(`otp-${index + 1}`).focus();
    }
  };

  const initiateAuth = async () => {
    if (mobileNumber.length !== 10) {
      setError("Please enter a valid 10-digit mobile number");
      return;
    }
    setIsLoading(true); // Set loading to true when the request starts
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/initiate-auth/`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          phone_number: `+91${mobileNumber}`
        })
      });
      const data = await response.json();
      console.log(data);
      if (response.ok) {
        setIsOtpScreen(true);
        setOtp(Array(4).fill("")); // Clear the OTP fields
        setSessionToken(data.response.Session);
        setRequestId(data.response.ChallengeParameters.requestId);
        setError("");
        setTimer(180);
        setCanResend(false);
      } else {
        if (data.detail === 'User not found') {
          setError(data.detail);
          // onSignUpClick();
        } else {
          setError(data.detail || "Failed to send OTP");
        }
      }
    } catch (error) {
      console.error("Network error", error);
      setError("Network error");
    }
    setIsLoading(false); // Set loading to false when the request completes
  };
  
const getSessionID = () => {
  // Generate a new sessionID every time
  const sessionID = 'sess-' + Math.random().toString(36).substr(2, 9);
  // Store the new sessionID in localStorage, replacing any existing one
  localStorage.setItem('sessionID', sessionID);
  // Return the new sessionID
  return sessionID;
};



const verifyOtp = async () => {
  setIsLoading(true); // Start loading
  try {
      const fullOtp = otp.join('');
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/respond-to-auth-challenge/?phone_number=%2B91${mobileNumber}&session=${encodeURIComponent(sessionToken)}&code=${fullOtp}`, {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
          },
      });
      const data = await response.json();
      if (response.ok && data.AuthenticationResult) {
          const { AccessToken, RefreshToken, IdToken } = data.AuthenticationResult;
          
          // Set session ID in local storage upon successful authentication
          const sessionID = getSessionID(); // Generate or retrieve existing session ID

          setAuthInfo({
              user: data.userName,
              accessToken: AccessToken,
              refreshToken: RefreshToken,
              idToken: IdToken,
              sessionID: sessionID  // Optionally store session ID in auth info if needed elsewhere
          });
          navigate('/home');
      } else {
          setIsOtpScreen(false);
          setOtp(Array(4).fill("")); // Reset the OTP input fields
          alert('Invalid OTP. Please try again.');
      }
  } catch (error) {
      console.error('Network error:', error);
      alert('Network error. Please try again later.');
  }
  setIsLoading(false); // End loading
};


const closeModal = () => {
    setError("");
  };


  return (
    <>
    {error && ( <ErroModal title='Issue with Login' message={error} close={closeModal}/>
        // <div className="error-modal">
        //   <div className="error-modal-content">
        //     <div className="modal-header">
        //       <button className="modal-close-icon" onClick={closeModal}>
        //         <img src="/images/closeicon.svg" alt="Close" />
        //       </button>
        //       <h2>Issue with Login</h2>
        //     </div>
        //     <p>{error}</p>
        //     <button className="modal-close-button" onClick={closeModal}>Close</button>
        //   </div>
        // </div>
      )}
      {!isOtpScreen ? (
        <div className="login-container">
          {viewPort === "mobile" && (
            <div className="register-header">
              <div onClick={() => navigate(-1)}>
                <img src="/images/mobile/arrow-left.svg" alt="Back" className="match-selected-arrow" />
              </div>
              <div>
                <img src="/images/logoname1.svg" alt="Logo" className="reg-logo-name-header"/>
              </div>
            </div>
          )}
          <span className="login-toptext">Welcome !</span>
          <span className="login-bottomtext">Login to continue</span>
          <form className="login-form" onSubmit={e => e.preventDefault()}>
            <div className="login-inp-blocks">
              <label htmlFor='mobile' className="register-label-text">Mobile Number</label>
              <div className="input-group-mobile">
                <span className="input-group-text">+91</span>
                <input className="login-inp" ref={InpRef} type="text" id="mobile" value={mobileNumber} onChange={handleMobileChange} />
              </div>
            </div>
            <button type="button" className="login-btn" onClick={initiateAuth}>
                {isLoading ? <ThreeDots color="#fff" height={20} width={80} /> : (canResend ? "Resend OTP" : "Send OTP")}
            </button>
          </form>
          {
           viewPort === "mobile" &&
              <div className="register-login">
                <p>New user?<span className="register-login-text" onClick={handleSignUpClick}>Create an account</span></p>
              </div>
          }
          <div className="register-terms-text">
            <span>By Signing In, you agree to Cricbotz's <a href="/terms-and-conditions" className="register-links">Terms & Conditions</a> and <a href="/privacy-policy" className="register-links">Privacy Policy.</a></span>
          </div>
        </div>
      ) : (
        <div className="login-container">
            <span className="register-toptext">Verify your Mobile Number</span>
            <span className="register-bottomtext">We have sent an OTP to +91{mobileNumber} for the Request ID - {requestId}</span>
            <form className="otp-form">
                <div className="otp-inputs-container">
                {otp.map((value, index) => (
                    <input key={index} id={`otp-${index}`} type="text" className="otp-input" maxLength="1" value={value} onChange={e => handleOtpChange(index, e.target.value)} />
                ))}
                </div>
                <button type="button" className="verify-btn" onClick={verifyOtp}>
                    {isLoading ? <ThreeDots color="#fff" height={20} width={80} /> : "Verify"}
                </button>
            </form>
            {!canResend && (
                <div className="timer">
                Resend available in {Math.floor(timer / 60)}:{('0' + (timer % 60)).slice(-2)}
                </div>
            )}
            {canResend && (
                <>
                    {/* <div className="login-inp-blocks">
                        <label htmlFor='mobile' className="register-label-text">Mobile Number</label>
                        <div className="input-group">
                            <span className="input-group-text">+91</span>
                            <input className="login-inp" type="text" id="mobile" value={mobileNumber} onChange={handleMobileChange} />
                        </div>
                    </div> */}
                    <button type="button" onClick={initiateAuth} className="login-btn">
                        {isLoading ? <ThreeDots color="#fff" height={20} width={80} /> : "Resend OTP"}
                    </button>
                </>
            
            )}
            <div className="register-terms-text">
                <span>By Signing In, you agree to Cricbotz's <a href="#" className="register-links">Terms & Conditions</a> and <a href="#" className="register-links">Privacy Policy.</a></span>
            </div>
            </div>

      )}
      {error && <div className="error-message">{error}</div>}
    </>
  );
};

export default LoginPage;
