import React, { useState } from 'react';
import axios from 'axios';
import './subscriptionmodal.css';
import ballSpinner from '../../../lotties/ball-loading.json';
import Lottie from "lottie-react";

const SubscriptionModal = () => {
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);
  const loadingOverlay = subscriptionLoading ? (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000
    }}>
      <Lottie animationData={ballSpinner} loop={true} style={{ width: '50px', height: '50px' }} />
    </div>
) : null;
  const handleBuyPlan = async (planType) => {
    setSubscriptionLoading(true);  

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-payment-url`, { plan: planType }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('idToken')}`
        }
      });
      window.location.href = response.data.url; // Redirect to payment URL
    } catch (error) {
      console.error('Error fetching payment URL:', error);
      alert('Failed to fetch payment URL.'); // Inform the user of the failure
    } finally {
      setSubscriptionLoading(false);
      // setLottieAnimation(false); // Stop showing Lottie animation
    }
  };

  return (
    <div className="subscription-modal-container">
      {loadingOverlay}
      <div className="subscription-modal">
        <header className="subscription-modal-header">
          <img src="/images/trial_icon.svg" alt="Trial Icon" className="trial-icon" />
          <p>No Active Subscription</p>
        </header>
        <div className="subscription-modal-content">
          <p>
            You do not have any active subscription and your free 1 week trial has also expired. Please subscribe to a plan to continue using our services.
          </p>
          <div className="subscription-cards-container">
            
                <div className="subscription-card">                
                    <div className='subscription-plan-type'>PRO - Monthly</div>
                    <div className='subscription-plan-price'>
                        <span className="subscription-price-amount">₹99</span> 
                        <span className="subscription-plan-duration">/month - One time payment</span>
                    </div>
                    {/* <div style={{ height: '32px' }}></div> */}
                    {/* <button className='subsciption-button'>Buy Plan</button> */}
                    <button className='subscription-modal-btn' onClick={() => handleBuyPlan('monthly')}>Buy Plan</button>
                    
                    <hr className="subscription-divider" />
                    <div className='subscription-whats-included'>What's Included</div>
                    <div className="subtitle2">For T20 Matches</div>    
                    <div className='subscription-plan-features'>
                        <ul className="subscription-plan-features">
                            <li>Recent Venue Stats</li>
                            <li>Recent Player Stats</li>
                            <li>Customized Insights for Fantasy Cricket</li>
                            <li>Build Fantasy Teams</li>
                        </ul>
                    </div>          
                </div>

                <div className="subscription-card">                
                    <div className='subscription-plan-type'>PRO - Yearly</div>
                    <div className='subscription-plan-price'>
                        <span className="subscription-price-amount">₹899</span> 
                        <span className="subscription-plan-duration">/year - One time payment</span>
                    </div>
                    {/* <div style={{ height: '32px' }}></div> */}
                    {/* <button className='subsciption-button'>Buy Plan</button> */}
                    <button className='subscription-modal-btn' onClick={() => handleBuyPlan('yearly')}>Buy Plan</button>
                    
                    <hr className="subscription-divider" />
                    <div className='subscription-whats-included'>What's Included</div>
                    <div className="subtitle2">For T20 Matches</div>    
                    <div className='subscription-plan-features'>
                        <ul className="subscription-plan-features">
                            <li>Recent Venue Stats</li>
                            <li>Recent Player Stats</li>
                            <li>Customized Insights for Fantasy Cricket</li>
                            <li>Build Fantasy Teams</li>
                        </ul>
                    </div>          
                </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionModal;
