import React, { useState } from 'react';
import axios from 'axios';
import './generatecombinationsmodal.css';
import { useNavigate } from 'react-router-dom'; 
import Lottie from "lottie-react";
import ballSpinner from '../../../lotties/ball-loading.json';
import TeamSavedModal from './TeamSavedModal';

const GenerateCombinationsModal = ({ isOpen, onClose, numberOfTeams, matchId, firstBattingTeam, secondBattingTeam, 
    firstBattingTeamId, secondBattingTeamId, currentTeamDetails, chartData, insights }) => {
    const [selected, setSelected] = useState(null);
    const [isTeamsSaving, setIsTeamsSaving] = useState(false);
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [apiResponse, setApiResponse] = useState(null);
    const [disableSave, setDisableSave] = useState(true)
    // const [hideGen, setHideGen] = useState(false)

    const navigate = useNavigate();

    if (!isOpen) return null;

    const handleSelect = (index) => {
        setSelected(index);
        setDisableSave(false)
    };

    const handleSaveTeams = () => {
        const token = localStorage.getItem('idToken');        
        if (!token) {
            console.error('No token found, redirecting to login.');
            navigate('/login'); // Navigate to login if not authenticated
            return;
        }
        
        if (selected === null) {
            alert('Please select a number of teams.');
            return;
        }

        setIsTeamsSaving(true); // Start showing the Lottie animation

        const data = {
            match_id: matchId,
            approach_number: 1,
            first_batting_team_id: firstBattingTeamId,
            second_batting_team_id: secondBattingTeamId,
            first_batting_team_name: firstBattingTeam,
            second_batting_team_name: secondBattingTeam,
            number_of_teams: selected + 1,
            team_details: currentTeamDetails,
            chartData: chartData,
            insights: insights
        };
        
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/generate-teams`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            console.log("Teams generated and saved:", response.data);
            setIsTeamsSaving(false); // Stop showing the Lottie animation
            setIsSuccessModalOpen(true); // Show the "Team Saved" modal
            setApiResponse(response.data);
            // setHideGen(true)
        })
        .catch(error => {
            console.error('Error generating and saving teams:', error);
            setIsTeamsSaving(false); // Ensure to stop the animation on error as well
            if (error.response && error.response.status === 401) {
                navigate('/login'); // Navigate to login if token is invalid
            }
        });
        
    };
  
    const loadingOverlay = isTeamsSaving ? (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000
        }}>
          <Lottie animationData={ballSpinner} loop={true} style={{ width: '50px', height: '50px' }} />
        </div>
    ) : null;
    
    
    return (
        <div className="GenCombModal-backdrop">
            { loadingOverlay }
            <div className="GenCombModal-container">
                <img src="/images/close_icon_2.svg" alt="Close" className="GenCombModal-close" onClick={onClose} />
                <h2 className="GenCombModal-header">Generate Combination</h2>
                <p className="GenCombModal-subtext">
                    The teams will be saved under "My Teams"
                    <img src="/images/myTeamsGrey_icon.svg" alt="My Teams" style={{ height: '20px', width: '20px' }}/>
                </p>
                <div className="GenCombModal-circleContainer">
                    {[...Array(numberOfTeams).keys()].map((num) => (
                        <div key={num} className={`GenCombModal-circle ${selected === num ? 'selected' : ''}`} onClick={() => handleSelect(num)}>
                            {num + 1}
                        </div>
                    ))}
                </div>
                
                
                <button className={disableSave?"GenCombModal-disSavebtn":"GenCombModal-saveButton"} 
                        disable={disableSave} onClick={handleSaveTeams}>
                    Save Teams
                </button>
            </div>
            {/* { loadingOverlay } */}
            
            <TeamSavedModal 
                isOpen={isSuccessModalOpen}                 
                saveOnClose={() => {
                    setIsSuccessModalOpen(false)
                    onClose()
                }}
                text={apiResponse ? apiResponse.response : "The teams are saved under \"My Teams\""}
            />
        
        </div>
    );
};

export default GenerateCombinationsModal;