import React, { useState, useEffect } from 'react'
import axios from 'axios';
import './matchinsightsM.css'
import MatchInsightPanelM from './MatchInsightPanelM';
import {ThreeDots} from 'react-loader-spinner';

const MatchInsightsM = () => {
  const [loading, setLoading] = useState(true) 
  const [language, setLanguage] = useState("English")
  const [matchInsights, setMatchInsights] = useState(null);

  const match = JSON.parse(localStorage.getItem('matchSelected'))
  const BASE_URL = "https://asak4lmpca.execute-api.us-east-2.amazonaws.com/dev"

  useEffect(()=>{
    console.log("Loading match insights for ID:", match.match_id);
    axios.post(`${BASE_URL}/match-summary-insights/`, { match_id: match.match_id }, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          console.log("Match insights fetched:", response.data);
          setMatchInsights(response.data);
          setLoading(false)
        })
        .catch(error => {
          console.error('Error fetching match insights:', error);
          setLoading(false)
        });
        
  }, [])

  const handleLangChange = (lang) =>{
    setLanguage(lang)
  }

  if (loading === true){
    return (
      <div className="spinner-div">
          <ThreeDots
          height="80"    
          width="80"
          color="#025A4E"
        />
      </div>
    )
 }else{
    return(
        <div className="match-insights-container">
            <div className="insights-top">
                <span className="insights-text">CheatSheet</span>
                <div className="insights-lang-block">
                    <span className={language==="English" ?"insights-lang-eng insight-lang-bg": "insights-lang-eng"} onClick={()=>handleLangChange('English')}>Eng</span>
                    <span className={language==="Hindi" ?"insights-lang-hindi insight-lang-bg":"insights-lang-hindi"} onClick={()=>handleLangChange('Hindi')}>हिंदी</span>
                </div>
            </div>
            {/* <div className="insight-panel-container"> */}
                <MatchInsightPanelM matchInsights={matchInsights} language={language} />
            {/* </div> */}
        </div>
    )
 }
 
}

export default MatchInsightsM