

import ReactGA from 'react-ga4';

function UseAnalyticsEvent() {
  const trackEvent = (action, category, value) => {
    console.log(`Event: ${action}, Category: ${category}, Value: ${value}`);
    const uid = localStorage.getItem('UID');  // Assuming UID is stored in localStorage
    const sessionID = localStorage.getItem('sessionID');  // Assuming sessionID is stored in localStorage
    ReactGA.event({
      category: category,
      action: action,
      label: `UID: ${uid}, SessionID: ${sessionID}`,
      value: value,  // Ensure that 'value' is a number if it's being used
    });
  };

  return trackEvent;
}

export default UseAnalyticsEvent;
