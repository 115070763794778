import React, { createContext, useContext, useState, useEffect } from "react";

const viewportContext = createContext({})

export const ViewportProvider = ({children}) => {
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(()=>{
    //Set the width when window size changes
    const handleWindowResize = () =>{
        setWidth(window.innerWidth)
    }
     window.addEventListener("resize", handleWindowResize)

     // Clean up the event listener when the component unmounts
     return () =>{
        window.removeEventListener("resize", handleWindowResize)
     }
  }, [width])

  return(
    <viewportContext.Provider value={{width}}>
        {children}
    </viewportContext.Provider> 
  )
}

export const useViewport = () =>{
    const {width} = useContext(viewportContext)
    return {width}
 }