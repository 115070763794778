import React, { useState } from 'react';
import './matchhighlight.css'
import { parseISO, addMinutes, format } from 'date-fns';
import HowToUse from '../Home/Common/HowToUse';

const MatchHighlight = ({ match }) => {
  const [showHowToUse, setShowHowToUse] = useState(false);
  const handleQuestionClick = () => {
    setShowHowToUse(true);
  };

  const teamOne = match.match_name.split('||')[1].trim().split(' vs ')[0].trim();
  const teamTwo = match.match_name.split('||')[1].trim().split(' vs ')[1].trim();

   // Convert UTC time to IST by adding 330 minutes (5 hours and 30 minutes)
   const dateTimeInIST = addMinutes(parseISO(match.dt), 330);
   // Format the date and time to be more human-readable, including AM/PM notation
   const formattedDateTime = format(dateTimeInIST, "dd MMM yyyy, h:mm a") + ' IST'; // e.g., 12 Apr 2024, 7:30 PM IST
 


  return (
    <div className="match-highlight-wrapper-d">
      <div>
          <div>
              <span className="team-info-highlight-d">{teamOne}</span>
              <span className="vs-highlight-d">vs</span>
              <span className="team-info-highlight-d">{teamTwo}</span>
          </div>
          <div>
              <span className="match-time-highlight-d">{formattedDateTime}</span>
              <span className="match-time-highlight-d hyphen-d">-</span>
              <span className="match-time-highlight-d">{match.venue}</span>
          </div>
      </div>
      <div className="match-selected-right-d">
          <img src="/images/mobile/questioncircle.png" alt="How to use" className="match-selected-question-d" onClick={handleQuestionClick} />
      </div>
      {showHowToUse && <HowToUse isOpen={showHowToUse} onClose={() => setShowHowToUse(false)} />}
    </div>
  )
}

export default MatchHighlight